.primary-tab
	margin-bottom: r(30px)
	padding: 10px 0
	display: flex
	justify-content: center
	align-items: center
	.tab-link
		overflow: auto
		display: flex
		padding: 0
		list-style: none
		transition: all .3s ease-in-out
		margin: 0 -5px
		&::-webkit-scrollbar-track
			box-shadow: inset 0 0 6px rgba(255, 255, 255, 0)
			background-color: #cccccc
		&::-webkit-scrollbar
			margin-top: 10px
			width: 2px
			height: 2px
		&::-webkit-scrollbar-thumb
			border-radius: unset
			box-shadow: inset 0 0 6px rgba(255, 255, 255, 0)
			background-color: $main
		@media screen and ( max-width: 767.9px )
			justify-content: flex-start
		li
			font-size: r(18px)
			font-weight: 600
			color: $main
			letter-spacing: -.25px
			margin-right: r(30px)
			position: relative
			scroll-snap-align: center
			scroll-snap-stop: always
			margin: 5px
			height: auto
			&:last-child
				margin-right: 0
			&.active,&:hover
				font-weight: 700
				color: $white
				a
					background-color: #ea2530
			a
				text-align: center
				height: 100%
				width: r(300px)
				display: flex
				align-items: center
				justify-content: center
				padding: 5px 30px
				background-color: $white
				transition: .3s all ease-in-out

	&.white
		li
			color: $white
			&::before
				background-color: $white
			&.active
				color: $white
				&::before
					background: $white
			&:hover
				color: $white
				&::before
					width: 50px
