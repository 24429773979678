.index-2
	background: url("../img/bg2.png") no-repeat
	background-size: cover
	@media screen and ( max-width: 1023.9px )
		[class*='col-']
			margin-bottom: r(30px)
	.block-title
		margin-bottom: r(35px)
	.item
		.img
			+img-ratio(220/285)
	.wrap-content
		display: flex
		align-items: center
		position: relative
		padding: r(30px)
		padding-top: r(60px)
		margin-top: -40px
		height: 170px
		font-size: r(24px)
		line-height: normal
		background-color: $main
		clip-path: polygon(0 24%, 51% 0, 100% 24%, 100% 100%, 0% 100%)
		transition: .2s all ease-in-out
		justify-content: center
		text-align: center
		&:hover
			background-color: $red-1
			.wrap-clippath
				display: none
	.wrap-clippath
		position: absolute
		bottom: 0
		left: 0
		height: 178px
		width: 100%
		clip-path: polygon(50% 0,100% 0,100% 33%,50% 10%,0 33%,0 0)
		background: #a0adb6
