.capa-7
	background-color: $bg_color
	transition: .3s all ease-in-out
	[class*="col-"]
		margin-bottom: r(30px)
	.item
		height: 100px
		display: flex
		justify-content: center
		align-items: center
		background: $white
		img
			object-fit: contain
	.row
		transition: .3s all ease-in-out
	[class*="col-"]
		display: none
	&.active
		transition: .3s all ease-in-out
		.row
			height: 100%
			transition: .3s all ease-in-out
.btn.sub-btn
	position: relative
	span
		display: inline-block
		position: absolute
		left: 0
		pointer-events: none
		transition: .3s all ease-in-out
		img
			filter: brightness(0) invert(1)
	&:hover
		span
			left: 10px
