.box-info-contact
    display: none
.infor-inner
    .box-info-contact
        display: block
.page-recruit-detail
    [class*="col-"]
        margin-bottom: r(30px)
    .info
        em
            line-height: 1.5
            font-size: 16px
        time
            margin-left: 5px
    .wrap-table
        overflow: auto
        box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1)
    table
        width: 100%
        @media screen and ( max-width: 1023.9px )
            width: 690px
            overflow: auto
            border-collapse: collapse
            border-spacing: 0;
        em
            color: $main
            line-height: 1.5
            font-size: 16px
        em+span
            padding-left: 5px
        tr
            height: 50px
            &:nth-child(even)
                background-color: $bg_color
            td
                span
                    color: $text-2
            td:first-child
                display: flex
                height: 50px
                align-items: center
                padding-left: r(30px)
                span
                    font-weight: 700
            td:last-child
                width: 80%
                span
                    font-weight: 500
    .wrap-desc
        padding: 0 r(30px) r(30px)
        margin-top: r(30px)
        box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1)
        .desc
            padding-top: r(30px)
            .title
                font-size: 16px
                margin-bottom: r(10px)
            ul
                list-style-position: inside
                padding: 0
                li
                    &::marker
                        color: rgba($text-2,0.8)
.box-info-contact,.info-option
    background-color: #ea2530
    border-bottom: thin solid $white 
    .info-contact
        padding: r(20px)
        .block-title
            font-size: r(24px)
            font-weight: 400
            letter-spacing: normal
            position: relative
            &::before
                content: ""
                position: absolute
                width: 70px
                height: 2px
                background-color: $white
                bottom: -5px
                left: 20px
                @media screen and ( max-width: 1279.9px )
                    left: 50%
                    transform: translateX(-50%)
        .desc
            display: flex
            margin-bottom: r(20px)
            *
                color: $white
            em
                font-size: 16px
                line-height: 1.5
                margin-right: 10px
            span, a
                word-break: break-all
.info-option
    padding: r(30px) r(20px)
    .button
        margin-bottom: r(20px)
        &:last-child
            margin-bottom: 0
        .btn
            border: 1px solid $white
            width: 100%
            height: 40px
            &:hover
                background-color: $white
                color: #ea2530

.other-recruit
    margin-top: r(30px)
    .wrap-header
        background-color: $main
        padding: 10px 0
        height: 50px
        display: flex
        align-items: center
        .block-title
            padding-left: r(30px)
            font-size: r(24px)
            font-weight: 500
            letter-spacing: normal
            margin-bottom: 0
    .wrap-body
        box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1)
        padding: 0 r(30px)
        .item
            padding: r(15px) 0
            border-bottom: thin solid $gray_2
            color: $text-2
            &:last-child
                border: unset
            a
                font-weight: 700
                &:hover
                    color: #ff3333
                    transition: .3s all ease-in-out