#page-banner
	position: relative
	z-index: -1
	.img
		width: 100%
		height: 100%
		img
			width: 100%
			height: 100%
			object-fit: cover
			min-height: 380px
			@media screen and ( max-width: 1024px )
				min-height: 25vh
.news-detail-page
	#page-banner
		display: none
	.Module-225
		display: block !important
