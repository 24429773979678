.page-product-detail
	&.section
		padding-bottom: 0
	.swiper-product-detail
		.gallery-top
			margin-bottom: r(30px)
			.img
				+img-ratio(380/495)
		.block-thumb
			position: relative
			.gallery-thumbs
				cursor: pointer
				.img
					+img-ratio(90/105)
				.swiper-slide-thumb-active
					border: thin solid #ee202e
			.arrow-button
				.swiper-button-prev,
				.swiper-button-next
					background-color: white
					&:hover
						background-color: #ee202e
				.swiper-button-prev
					left: -15px
				.swiper-button-next
					right: -15px
		.block-top
			position: relative
			.swiper-slide
				.img
					cursor: zoom-in
			.zoom-img
				position: absolute
				top: 0
				left: calc( 100% + 55px )
				z-index: 99
				opacity: 0
				pointer-events: none
				visibility: hidden
				transition: .3s all ease-in-out
				box-shadow: 0px 0px 10px rgba($black ,0.5 )
				@media screen and ( max-width: 1024px)
					display: none
				&.active
					opacity: 1
					pointer-events: visible
					visibility: visible
				.img
					background-image: url(../img/san-pham/1.png)
					width: 100%
					height: 100%
					background-repeat: no-repeat
					background-size: 400%
			.zoom-cursor
				position: absolute
				z-index: 4
				pointer-events: none
				inset: 0
				transition: .3s all ease-in-out
				opacity: 0
				&.active
					opacity: 1
				.zoom-area
					left: 50%
					top: 50%
					transform: translate(-50%,-50%)
					background-color: rgba($white ,0.2 )
					box-shadow: 0px 0px 5px rgba($black ,0.2 )
					width: 40%
					height: 40%
					position: absolute

	.block-title
		font-size: r(30px)
	.wrap-table
		margin-left: r(30px)
		@media screen and ( max-width: 767.9px )
			margin-left: 0
		table
			width: 100%
			tr
				height: 50px
				border-top: thin solid $gray_2
				&:last-child
					border-bottom: thin solid $gray_2
				td
					&:first-child
						width: 20%
						font-weight: 700
		.button
			margin-top: r(40px)
		.social-icons
			margin-top: r(60px)
			@media screen and ( max-width: 767.9px )
				margin-top: r(30px)
.tab-panel
	.tab-description
		overflow-x: auto
		ul
			list-style: none
			padding: 0
			display: flex
			@media screen and ( max-width:  1024.98px)
				display: flex
				justify-content: flex-start
				align-items: center
				white-space: nowrap

			li
				padding-right: 10px
				a
					display: flex
					align-items: center
					justify-content: center
					background-color: $gray_2
					background: none
					width: 180px
					height: 45px
					color: #666666
					font-size: 18px
					letter-spacing: -0.25px
					cursor: pointer
					font-weight: 500
				&:hover
					a
						background: #283a97
						color: #ffffff
				&.active
					a
						font-weight: 400
						color: $white
						background: #283a97
			@media screen and ( max-width:576px)
				display: flex
				align-items: center
				justify-content: flex-start
				li
					text-align: center
	.content-description
		border: thin solid $gray_2
		padding: r(30px)
		.tab-wrapper
			.tab-item
				display: none
				&.active
					display: block
		.fullcontent
			overflow: hidden
            // max-height: 400px
			&.active
				max-height: max-content
				transition: .5s all ease-in-out
			p
				color: #333333
				font-size: 1rem
				padding-top: 5px
			span
				color: #333333
				font-size: 1rem
				font-weight: 800
				padding-top: 25px
			strong
				color: #333333
				font-size: 1rem
				font-weight: 800
				padding-top: 25px
.product-other
	&.section
		padding-top: 0
	.title
		font-size: r(24px)
		position: relative
		&::before
			content: ""
			position: absolute
			bottom: -15px
			background-color: #ea2530
			height: 2px
			width: 75px
			left: 0
	.swiper-product-other
		margin-top: r(50px)
		position: relative
		.swiper-slide
			.item
				.title
				   &::before
                       display: none
				.text-inner
					padding: r(30px)
					.content
						letter-spacing: 1.5px
				.img
					position: relative
					+img-ratio(315/390)
					&::before
						content: ""
						position: absolute
						width: 100%
						height: 100%
						top: 0
						left: 0
						background-color: rgba(black, .5)
						z-index: 2

#modal
	position: fixed
	left: 50%
	top: 50%
	transform: translate(-50%,-50%)
	background-color: rgba(black, .8)
	width: 100vw
	height: 100vh
	z-index: 999
	opacity: 0
	visibility: hidden
	transition: .3s all ease-in-out
	padding-bottom: 50px
	overflow: auto
	display: flex
	align-items: center
	&.active
		opacity: 1
		visibility: visible
		transition: .3s all ease-in-out
	.modal-box
		max-width: 915px
		margin: 50px auto 0
		background-color: $white
		padding: r(30px)
		position: relative
		@media screen and ( max-width: 400px )
			margin-top: 0
			padding: r(40px) r(30px )
		#modal-close
			display: flex
			align-items: center
			justify-content: center
			position: absolute
			top: 0
			right: 0
			color: $white
			background-color: #ee202e
			cursor: pointer
			width: 30px
			height: 30px
		.modal-header
			.title
				font-size: r(24px)
				text-align: center
		[id*="_pnlQuestions"]
			display: flex
			flex-wrap: wrap
			margin: 0 -5px
		.form-group
			width: 100%
			flex: 0 0 50%
			padding: 0 5px
			margin-bottom: r(30px)
			&.textarea
				flex: 0 0 100%
			span
				color: #ee202e
				font-size: r(12px)
				&::before
					font-size: r(14px)
					font-weight: 300
		.modal-body
			input[type="text"]
				border: thin solid #e5e5e5
				border-radius: 0
				height: 40px
				font-weight: 300
				width: 100%
				padding: 0 15px
			textarea
				border: thin solid #e5e5e5
				width: 100%
				padding: 10px 0 0 20px
			.frm-btnwrap
				margin-bottom: 0
				display: flex
				justify-content: flex-start
				padding: 0
				.label
					display: none
				input[type="reset"]
					display: none !important
				.frm-btn
					@extend .button
					position: relative
					&::before
						content: ''
						position: absolute
						background-image: url(../img/btn-icon2.png)
						width: 28px
						height: 7px
						z-index: 3
						pointer-events: none
						top: 50%
						left: 0
						transform: translateY(-50%)
						filter: brightness(0) invert(1)
						transition: .3s all ease-in-out
					&:hover
						&::before
							left: calc( 100% - 28px )

				input[type="submit"]
					@extend .btn,.btn.red
					width: 180px !important
					border: 0
