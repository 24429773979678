.capa-4
	&.section
		padding-bottom: r(35px)
	ul.wrap-img
		display: flex
		justify-content: center
		flex-wrap: wrap
		margin-top: r(30px)
		padding: 0
		list-style: none
		li
			position: relative
			flex: 0 0 160px
			margin: 5px
			.img
				+img-ratio(155/160)
			&::before
				content: ""
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background-color: rgba($main, .5)
				z-index: 2
				transition: .3s all ease-in-out
			&:hover
				&::before
					background-color: rgba(#ea2530, .5)
			.text-inner
				text-align: center
				align-items: center
				justify-content: center
				font-size: r(18px)
				font-weight: bold
				padding: r(20px)
