.page-product
	&.section
		padding-bottom: r(80px)
	.block-title
		margin-bottom: 0
	.primary-tab
		margin-bottom: 20px
	&.index-3
		.big-box
			display: flex
			flex-wrap: wrap
		.modulepager
			margin-top: r(30px)
	.under-option
		margin-bottom: 30px
		.wrapper
			display: flex
			align-items: center
			justify-content: space-between
			@media (max-width: 575.98px)
				flex-direction: column
				align-items: flex-start
				grid-gap: 20px
		.child-link
			display: flex
			align-items: center
			flex-wrap: wrap
			padding-right: 10px
			grid-gap: 10px
			@media (min-width: 576px)
				grid-gap: 10px r(30px)
			a
				font-size: r(18px)
				color: $main
				line-height: 1.2
				transition: .3s all ease-in-out
				position: relative
				&::before
					content: ''
					position: absolute
					left: 0
					top: 100%
					width: 0
					transition: .3s all ease-in-out
					height: 1px
					background-color: #ea2530
				&.active,&:hover
					color: #ea2530
					&::before
						width: 100%
	#autoAppendHere-SortFilter
		@media (max-width: 575.98px)
			flex: 0 0 100%
			width: 100%
	.sort-list
		position: relative
		width: 100%
		@media (min-width: 576px)
			width: 140px
		.sort-title
			font-size: r(15px)
			color: rgb(51, 51, 51)
			display: flex
			align-items: center
			justify-content: space-between
			border-bottom: thin solid #ea2530
			.text
				padding-right: 5px
				display: -webkit-box
				-webkit-line-clamp: 1
				-webkit-box-orient: vertical
				overflow: hidden
			em
				font-size: r(20px)
				color: rgb(153, 153, 153)
		ul
			display: none
			position: absolute
			top: 100%
			left: 0
			width: 100%
			list-style: none
			padding: 0
			z-index: 10
			a
				display: block
				padding: 5px 10px
				background-color: #f5f5f5
				transition: .3s all ease-in-out
				&:hover
					background-color: #e1e1e1
				&.no-subpage-error
					color: #fff
					background-color: #ea2530

.pd
	&.pd-1
		position: relative
		.img
			a
				+img-ratio(390/480)
		.pd-title
			color: rgb(255, 255, 255)
			font-weight: bold
			text-transform: uppercase
			position: absolute
			left: 0
			width: 100%
			z-index: 2
			font-size: r(18px)
			padding: 0 15px
			bottom: 10px
			@media (min-width: 1280px)
				bottom: 40px
				padding: 0 40px
				font-size: r(24px)
.product-list-new
	width: 100%
	.pls-wrapper
		grid-gap: 2px
		display: grid
		grid-template-columns: repeat(2, minmax(0, 1fr))
		@media (min-width: 768px)
			grid-template-columns: repeat(3, minmax(0, 1fr))
		@media (min-width: 1280px)
			grid-template-columns: repeat(4, minmax(0, 1fr))
	.expand-item
		display: none
	.button
		margin-top: 30px
