.capacity-1
    &.section
        padding-bottom: r(60px)
    .block-bg
        padding: r(50px) 0
        background: url("../img/nanglucsx/bg1.png") no-repeat
        background-size: cover
        position: relative
        &::before
           content: ""
           position: absolute
           width: 100%
           height: 100%
           top: 0
           left: 0
           background-color: rgba(0, 0, 0, 0.5)
        .wrap-content
            padding: 0 r(70px)
            z-index: 2
            text-align: center
            @media screen and ( max-width: 767.9px )
                padding: 0 r(30px)
            .block-title
                line-height: normal
                margin-bottom: 15px
                @media screen and ( max-width: 399.9px )
                    font-size: r(20px)
            .content
                color: $white
        .wrap-img
            display: flex
            flex-wrap: wrap
            justify-content: center
            margin-top: r(30px)
            @media screen and ( max-width: 767.9px )
                padding: 5px
            .item
                flex: 0 0 250px
                height: 130px
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                padding: 15px
                margin: 2.5px
                background-color: rgba(#c2cbd1, .75)
                transition: .3s all ease-in-out
                cursor: pointer
                @media screen and ( min-width: 1280px )
                    &:last-child
                        margin-right: 0
                @media screen and ( max-width: 767.9px )
                    flex: 0 0 48%
                @media screen and ( max-width: 399.9px )
                    flex: 0 0 100%
                &.blue
                    background-color: $main
                    img
                        filter: brightness(0) invert(1)
                    .content
                        color: $white
                &:hover
                    background-color: rgba(#293b8e, .75)
                    .content
                        color: $white
                    img
                        filter: brightness(0) invert(1)
                .content
                    margin-top: 5px
                    text-align: center
                    color: $text-2
                img
                    width: 45px
                    height: 45px
                    object-fit: contain
                    
                


