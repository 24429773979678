.recruit-list-1
	background: url("../img/tuyen-dung-ds/bg1.png")no-repeat
	background-position: center
	background-size: contain
	.block-title
		font-size: r(30px)
		font-weight: 500
		margin-bottom: 0
	.title
		color: #ff3333
		font-size: r(24px)
		margin-bottom: 0
.recruit-list-2
	background: $bg_color
	.wrap-content
		padding-left: r(20px)
		height: 100%
		display: flex
		flex-direction: column
		justify-content: center
		.title
			font-size: r(36px)
			margin-bottom: 15px
		@media screen and ( max-width: 1023.9px )
			padding-left: 0
			padding-top: r(20px)
.recruit-list-3
	@media screen and ( max-width: 1023.9px )
	   [class*="col-"]
           margin-bottom: r(30px)
	.item
		box-shadow: 0 0 15px 3px rgba(black, .1)
		height: 320px
		padding: r(30px)
		@media screen and ( max-width: 1023.9px )
			height: auto
		.img
			text-align: center
			margin-bottom: 10px
			margin-right: auto
			margin-left: auto
		.block-title
			color: #333399
			font-size: r(20px)
			margin-bottom: 10px
			letter-spacing: normal
		.content
			line-height: 1.3
.recruit-list-4
	.grid-container
		display: grid
		grid-gap: 30px
		grid-template-columns: repeat(3,1fr)
		grid-template-rows: 270px 270px
		@media screen and ( max-width: 1023.9px )
			grid-template-columns: 1fr
			grid-template-rows: 2fr
		.grid-item
			position: relative
			&:nth-child(1)
				grid-area: 1 / 1 / 2 /2
				@media screen and ( max-width: 1023.9px )
					grid-area: 3 / 1 / 4 /2
			&:nth-child(2)
				grid-area: 2 / 1 / 3 /2
				@media screen and ( max-width: 1023.9px )
					grid-area: 2 / 1 / 3 /2
			&:nth-child(3)
				grid-area: 1 / 2 / 3 /3
				.text-inner
					justify-content: center
					align-items: center
					padding: r(70px)
					@media screen and ( max-width: 767.9px )
						padding: r(20px)
					.title
						text-align: center
						font-size: r(36px)
				@media screen and ( max-width: 1023.9px )
					grid-area: 1 / 1 / 2 /2
			&:nth-child(4)
				grid-area: 1 / 3 / 2 /4
				@media screen and ( max-width: 1023.9px )
					grid-area: 4 / 1 / 5 /2
			&:nth-child(5)
				grid-area: 2 / 3 / 3 /4
				@media screen and ( max-width: 1023.9px )
					grid-area: 5 / 1 / 6 /2
		.img
			position: relative
			height: 100%
			img
				object-fit: cover
				height: 100%
				position: absolute
				@media screen and ( max-width: 1023.9px )
					position: static
					height: auto
					width: 100%
			&::before
				content: ""
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background-color: rgba(black, .5)
				z-index: 2
		.text-inner
			z-index: 3
			padding: r(20px)
			top: inherit
			bottom: 0
			justify-content: flex-end
			.title
				margin-bottom: 15px
				color: $white
				font-size: r(24px)
.recruit-list-5
	&.section
		padding-bottom: r(30px)
	.wrap-content
		display: block
		padding: r(15px) r(20px)
		height: 100px
		transition: .3s all ease-in-out
		border-bottom: thin solid #e5e5e5
		.see
			color: $text
		.title
			color: #111
			font-weight: 700
			font-size: r(18px)
			margin-bottom: 3px
		@media screen and ( max-width: 767.9px )
           height: auto
		&:hover
			box-shadow: 0 0 15px 3px rgba(black, .1)
			.see
				transition: .3s all ease-in-out
				color: #333399 !important
				text-decoration: underline
			.title
				color: #333399 !important
		.see,em
			font-size: 14px
		em
			color: $text
			padding-right: 10px
			line-height: 1.8
		.address
			display: flex
		.box
			display: flex
			justify-content: space-between
			align-items: center
	+ .pages.newspager
		.modulepager
			margin: 0 0 r(40px)
.recruit-list-6
	background: url("../img/tuyen-dung-ds/bg2.png")no-repeat center
	background-size: cover
	position: relative
	z-index: 1
	&::before
		content: ""
		position: absolute
		top: 0
		bottom: 0
		width: 100%
		height: 100%
		background-color: rgba(black,.5)
		z-index: -1
	.desc
		color: $white
	.button
		margin-top: r(30px)
		.btn
			position: relative
			span
				display: inline-block
				position: absolute
				left: 0
				pointer-events: none
				transition: .3s all ease-in-out
				img
					filter: brightness(0) invert(1)
			&:hover
				span
					left: calc( 100% - 28px )
