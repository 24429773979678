.about-2
    position: relative
    background: url("../img/gioi-thieu/bg-1.png")no-repeat
    background-size: cover
    background-position: center
    z-index: 1
    &.section
        @media screen and ( max-width: 1023.9px )
            padding-bottom: r(30px)
    &::after
        position: absolute
        content: ""
        top: 0
        left: 0
        background-color: rgba(black, .75)
        width: 100%
        height: 100%
        z-index: -1
    .title,.item *
        z-index: 2
    .title
        font-size: r(24px)
        margin-bottom: r(35px)
    .item
        position: relative
        &::before
            content: ""
            position: absolute
            width: 1px
            height: 150px
            background-color: rgba($white, 0.5)
            top: 0 
            right: -15px
            @media screen and ( max-width: 1023.9px )
                display: none
        .quatity
            color: #ff3333
            font-size: r(40px)
            font-weight: 600
            line-height: 1.3
    [class*="col-"]
        margin-bottom: r(30px)
        &:last-child
            .item
                &::before
                    display: none

    