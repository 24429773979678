footer
	.footer-top
		padding: r(55px) 0 r(70px)
		background-color: #000b44
		.footer-title
			position: relative
			&::before
				content: ""
				position: absolute
				top: 0
				width: 30px
				height: 3px
				background-color: $red-1
			.title
				padding-top: 20px
				color: $white
				font-weight: 700
				margin-bottom: r(20px)
				line-height: 1.3
				font-size: r(16px)
		[class*='col-']
			border-right: 1px solid #18214c
			// padding-right: 30px
			@media screen and ( max-width: 1023.9px)
				border-right: 0
				// padding-right: 15px
			&:last-child
				border-right: 0
			// &:not(:first-child)
			// 	padding-left: 30px
			// 	@media screen and ( max-width: 1023.9px )
			// 		padding-left: 15px
		ul
			list-style: none
			padding: 0
			li
				line-height: 1.3
				margin-bottom: r(15px)
				*
					color: #8c97a9
					font-size: 14px
					letter-spacing: 1.025px
				.title-sub
					font-weight: 600
				a
					&:hover
						text-decoration: underline
	.wrap-social
		// display: flex

		// 
		display: none
		@media screen and ( max-width: 1023.9px )
			margin-bottom: 15px
		.social-item
			display: flex
			justify-content: center
			align-items: center
			width: 40px
			height: 40px
			transition: .3s all ease-in-out
			background-color: #071353
			margin-right: 5px
			em
				color: #8c97a9
				font-size: 18px
				transition: .2s all ease-in-out
			&:hover
				em
					transform: scale(1.1)
	.footer-bottom
		background-color: #071353
		padding: 20px 0
		display: flex
		align-items: center
		@media screen and ( max-width: 409.9px )
           height: auto
           padding: r(15px) 0
		.container
			overflow: hidden
		.wrap-left
			*
				color: #647084
				text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25)
			@media screen and ( max-width: 767.9px )
				text-align: center
				margin-bottom: 10px
				span
					display: block
			.ModuleContent
				display: flex
				overflow: auto
				white-space: nowrap
				&::-webkit-scrollbar
					height: 1px
		.wrap-box
			display: flex
			@media screen and ( max-width: 1279.9px )
				display: block
			*
				text-transform: uppercase
				font-size: 12px
			.wrap-right
				display: flex
				margin-left: 5px
				overflow: auto
				&::-webkit-scrollbar
					height: 1px
				.ModuleContent
					display: flex
					overflow: auto
					white-space: nowrap
					&::-webkit-scrollbar
						height: 1px
				a, span
					color: #8c97a9
					text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25)
					text-decoration: underline
				@media screen and ( max-width: 1279.9px )
					justify-content: center
					margin-left: 0
				@media screen and ( max-width: 409.9px )
					flex-wrap: wrap
					.ModuleContent
						text-align: center
				.item
					display: inline-block
					position: relative
					margin: 0 5px
					&::before
						content: ""
						height: 8px
						width: 1px
						background-color: #555f7d
						top: 50%
						transform: translateY(-50%)
						right: -5px
						position: absolute
					&:last-child
						&::before
							display: none
					&:hover
						text-decoration: underline
					@media screen and ( max-width: 409.9px )
						&:not(:first-child)
							&::before
								display: none
