.capa-2
	background: url("../img/nanglucsx/bg2.jpg") no-repeat
	background-size: cover
	&.section
		padding: r(50px) 0
	.block-title
		margin-bottom: 0
	.desc
		padding: 0 r(100px)
		text-align: center
		color: $white
		@media screen and ( max-width: 767.9px )
			padding: 0
	.primary-tab
		margin-bottom: r(15px)
		@media screen and ( max-width: 767.9px )
			.tab-link
				justify-content: center
	.swiper-capa-2
		margin-top: r(30px)
		position: relative
		display: none
		&.active
			display: block
		.item
			.img
				background-color: $white
				a
					+img-ratio(350/390)

				img
					object-fit: contain
			.title
				display: flex
				align-items: center
				justify-content: center
				padding: r(15px)
				background-color: #dddddd
				font-size: 16px
				text-transform: none
				transition: .3s all ease-in-out
				overflow: hidden
				height: 50px
				&:hover
					background-color: #ea2530
					color: $white
