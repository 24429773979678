.capa-5
    &.section
        padding-top: 0
        padding-bottom:  r(50px)
    .img
        +img-ratio(400/600)
        @media screen and ( max-width: 1023.9px )
            width: 100%
    .big-item
        display: none
        &.active
            display: block
    .wrap-content
        display: flex
        flex-direction: column
        justify-content: center
        height: 100%
        @media screen and ( max-width: 1023.9px )
            margin-bottom: r(30px)
        .title
            color: #333399
            font-size: r(18px)
        .content
            color: $text-2