.index-4
	background-color: $bg_color
	background-size: contain
	.wrap-content
		display: flex
		flex-direction: column
		justify-content: center
		height: 100%
		padding-right: r(60px)
		@media screen and ( max-width: 767.9px )
			padding-right: 0
		.title
			font-weight: 600
			line-height: normal
		.content
			color: $text-2
		.button
			margin: r(30px) 0
			@media screen and ( max-width: 1023.9px )
				justify-content: center
	.wrap-bg
		background: url("../img/index/3.png") no-repeat
		padding: r(30px)
		display: flex
		flex-wrap: wrap
		justify-content: center
		.item
			flex: 0 0 (100%/3.3)
			margin: 5px
			background-color: rgba($white,.8)
			height: 100px
			display: flex
			align-items: center
			justify-content: center
			padding: 0 10px
			@media screen and ( max-width: 767.9px )
               flex: 0 0 45%
			img
				object-fit: contain
		&.desktop
			@media screen and ( max-width: 767px )
				display: none
		&.mobile
			@media screen and ( min-width: 768px )
				display: none
	.swiper-slide
		height: fit-content
