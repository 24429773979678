
@font-face
    font-family: 'Material Icons'
    font-style: normal
    font-weight: 400
    src: url(https://fonts.gstatic.com/s/materialicons/v53/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2')
.material-icons
    font-family: Material Icons
    font-weight: normal
    font-style: normal
    font-size: 24px
    line-height: 1
    letter-spacing: normal
    text-transform: none
    display: inline-block
    white-space: nowrap
    word-wrap: normal
    direction: ltr

@font-face
    font-family: Linearicons-Free
    src: url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.eot)
    src: url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.eot?#iefix) format("embedded-opentype"), url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.woff2) format("woff2"), url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.ttf) format("truetype"), url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.woff) format("woff"), url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.svg#Linearicons-Free) format("svg")
    font-weight: 400
    font-style: normal

.lnr
    font-family: Linearicons-Free
    speak: none
    font-style: normal
    font-weight: 400
    font-variant: normal
    text-transform: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

.fa,
.fas,
.far,
.fal,
.fad,
.fab
    -moz-osx-font-smoothing: grayscale
    -webkit-font-smoothing: antialiased
    display: inline-block
    font-style: normal
    font-variant: normal
    text-rendering: auto
    line-height: 1

@-webkit-keyframes fa-spin
  0% 
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)
  100% 
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)

@keyframes fa-spin
    0%
        -webkit-transform: rotate(0deg)
        transform: rotate(0deg)
    100%
        -webkit-transform: rotate(360deg)
        transform: rotate(360deg)

@font-face
    font-family: 'Font Awesome 5 Brands'
    font-style: normal
    font-weight: 400
    font-display: block
    src: url("../fonts/fa-brands-400.eot")
    src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg")

.fab
    font-family: 'Font Awesome 5 Brands'
    font-weight: 400

@font-face
    font-family: 'Font Awesome 5 Free'
    font-style: normal
    font-weight: 400
    font-display: block
    src: url("../fonts/fa-regular-400.eot")
    src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg")

.far
    font-family: 'Font Awesome 5 Free'
    font-weight: 400

@font-face
    font-family: 'Font Awesome 5 Free'
    font-style: normal
    font-weight: 900
    font-display: block
    src: url("../fonts/fa-solid-900.eot")
    src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg")

.fa,
.fas
    font-family: 'Font Awesome 5 Free'
    font-weight: 900
