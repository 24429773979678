#advisory-form
	display: none
	max-width: 1024px
	width: 100%
	margin: 0 auto
	.advisory-form
		padding: r(60px) 0
	.form-group
		input
			border-bottom: thin solid #888888
			border-radius: 0
	.frm-btnwrap
		input
			border-bottom: thin solid $tran
	.wrap-form
		.form-row
			display: flex
			justify-content: space-between
			@media screen and ( max-width: 767.9px )
				display: block
			.form-group
				width: 49%
				@media screen and ( max-width: 767.9px )
					width: 100%
		.form-group
			input[type="text"]
				border-radius: 0 !important
				border: 0
				border-bottom: thin solid #ccc
				height: 50px
				padding-left: r(20px)
				font-size: 14px
				color: $gray_3
				letter-spacing: 1.5px
				width: 100%
				margin-bottom: r(15px)
			&.form-row
				display: flex
				justify-content: space-between
				@media screen and ( max-width: 400px )
					display: block
				input[type="text"]
					width: 48.5%
					@media screen and ( max-width: 400px )
						width: 100%
		.frm-btnwrap
			.frm-btn
				position: relative
				width: fit-content
				input[type="reset"]
					display: none
				input[type="submit"]
					width: 150px
					height: 50px
					background-color: $red-1
					color: white
					border: unset
					cursor: pointer
					transition: .3s all ease-in-out
				&::before
					content: ""
					position: absolute
					background: url("../img/btn-icon.png")no-repeat
					background-position-y: 50%
					top: 50%
					transform: translateY(-50%)
					left: 0
					width: 100%
					height: 100%
					pointer-events: none
					transition: .3s all ease-in-out
				&:hover
					input[type="submit"]
						background-color: $main
					&::before
						background-position-x: 100%
