@import src/components/_core/mixin.sass
.checkout-title
	// display: flex
	// align-items: center
	// h3 
	.title-wrap
		background-color: #f1f1f1
		border-left: 10px solid $main
		display: flex
		align-items: center
		// height: 60px
		padding-left: 15px
		font-size: r(16px)
		font-weight: 700
		color: $main
		text-transform: uppercase
		margin-bottom: 15px
		padding: 12px 15px
		border-radius: 5px
		border-top-right-radius: 0
		border-bottom-right-radius: 0
		overflow: hidden
		width: 100%
		.fas
			margin-right: 10px
		@media screen and ( max-width: 768px )
			// height: 30px
			font-weight: 600
			border-left: 5px solid $main
				// margin-bottom: 20px
.admin-content
	.panel
		margin: 60px 0
		border-radius: 5px
		background: #fff
		.panel-heading
			h1
				margin-bottom: 30px
				font-size: 30px
				text-align: center
				text-transform: uppercase
				font-weight: 400
				color: #333333
	.form-horizontal
		border: thin solid #ccc
		border-radius: 15px
		padding: 15px
		.form-group
			label
				font-size: 16px
				font-weight: 400
				color: #333333
				margin-bottom: 5px
			input
				border: thin solid #ced4da!important
				border-radius: 5px
				width: 100%
				height: 40px
				padding: 5px 15px
			input[type="submit"]
				transition: all .2s ease-in-out
				box-shadow: none
				border: none
				border-radius: 5px
				background: $main
				min-width: 120px
				height: 40px
				color: #fff
				font-size: 16px
			span
				font-size: 12px
				color: red !important
				display: flex
				margin-top: 5px
				font-style: italic
		.RadCaptcha
			display: flex
			margin-top: 20px
			margin-bottom: 20px
			flex-wrap: wrap
			// flex-direction: column-reverse
			> *
				width: 100%
			span
				order: 3
			> div
				order: 1
				margin-bottom: 5px
				> div
					display: flex
					align-items: center
					margin-bottom: 10px
					a
						display: inline-block
						padding-left: 15px
						color: #1e87f0
						font-size: 12px
			input
				order: 2

		@media(min-width: 1200px)
			padding: 20px
.customer-info
	display: flex
	flex-wrap: wrap
	padding: 0px 15px
	.form-info,.form-same,.bill-export
		flex: 100%
		padding: 15px 0px

	.form-group
		display: flex
		align-items: center
		width: 100%
		flex-wrap: wrap
		label
			flex: 0 0 200px
			font-size: 16px
			color: black
			font-weight: 400
		input,select
			height: 40px
			// display: flex
			// align-items: center
			width: 100%
		input,select,textarea
			flex: calc( 100% - 230px )
			margin-left: 15px
			border: thin solid #cccccc
			border-radius: 5px
			padding: 0px 15px
		textarea
			min-height: 200px
			padding: 5px 15px
		span
			flex: 100%
			color: $main
			font-style: italic
			padding: 5px 0px
		@media screen and ( max-width: 768px )
			label
				flex: 0 0 125px
			textarea
				min-height: 130px
		@media screen and ( max-width: 425px )
			flex-direction: column
			align-items: flex-start
			margin-bottom: 10px
			span
				font-size: 14px
			label
				flex: 100%
			input,select,textarea
				flex: 100%
				width: 100%
				margin-left: 0px
				margin-top: 5px
				flex: none

	.form-same
		padding-top: 0px
		@media screen and ( max-width: 425px )
			display: flex
			align-content: center
			label
				margin-left: 10px
		.form-wrapper
			padding-top: 15px
			display: none
	.bill-export
		// display: none
		.form-wrapper
			display: none
			padding-top: 15px
			i
				padding: 5px 0px
				display: block
.term-service-wrap
	.qcheckbox
		padding: 10px 15px
		display: flex
		align-items: center
		#PaymentAgree
			width: 15px
			height: 15px
			border-radius: 2px
			border: thin solid #ccc
			&:checked
				border: thin solid transparent
		label
			font-size: r(14px)
			color: black
			font-weight: 400
			margin-left: 15px
.setting-control
	.btn-gap
		display: flex
		justify-content: space-between
	@media screen and ( max-width: 991px )
		// width: fit-content
		margin: 0 auto
		.btn-gap
			display: flex
			justify-content: space-between
	.btn-gap
		margin-top: 15px
		display: flex
		align-items: center
		.btn-primary
			transition: .3s all ease-in-out
			padding: 10px 15px
			display: flex
			justify-content: center
			align-items: center
			height: 40px
			width: fit-content
			background: #f1f1f1
			color: $main
			border-radius: 5px
			border-left: 10px solid $main

			@media screen and ( max-width: 768px )
				border-left: 5px solid $main

			& + .btn-primary
				margin-left: 15px
			span
				margin-left: 10px
				font-size: 14/16*1rem
				font-weight: 500
				text-transform: uppercase
				white-space: nowrap
			&.red
				em
					padding-right: 0
			&.gray
				@media screen and ( max-width: 375px)
					margin-bottom: .9rem
				em,
				span
					color: #333333
			&:hover
				background-color: #56856a
				color: #ffffff
				border-left: 10px solid #56856a
