.text-white
    color: $white
.text-center
    text-align: center
.text-left
    text-align: left
.title
    color: $text-2
    font-size: r(30px)
    font-weight: 700
    margin-bottom: 25px
    line-height: normal
    &.inner
        color: $white
        text-transform: uppercase
        text-align: left
        transition: .3s all ease-in-out
        &:hover
            color: $red-1
    &.text-upper
        text-transform: uppercase
    &.text-left
        text-align: left
.see-all
    color: #444444
    font-size: r(14px)
    font-weight: 400
    text-decoration: underline
    text-transform: uppercase
    letter-spacing: 1.4px
    &.bold
        font-weight: 700
    &.gray_3
        color: $gray_3

.block-title
    color: $text-2
    font-size: r(36px)
    font-weight: 700
    text-align: center
    letter-spacing: -0.9px
    margin-bottom: r(20px)
    &.text-upper
        text-transform: uppercase
    &.text-left
        text-align: left
time
    opacity: 0.75
    display: block
    color: $text
    font-size: r(12px)
    font-weight: 400
    letter-spacing: 1.2px
    margin-bottom: 20px
    transition: .3s all  ease-in-out
