.page-contact
	&.section
		padding-bottom: 0
	[class*="col-"]
		margin-bottom: r(30px)
	.item
		display: flex
		flex-direction: column
		border: thin solid $gray_2
		padding: 10px 15px
		height: 100%
		align-items: center
		justify-content: flex-start
		.wrapper
			.wrap-content
				margin-top: r(15px)
				.title
					font-size: r(24px)
					color: $main
					margin-bottom: r(15px)
				.content
					display: flex
					align-items: flex-start
					margin-bottom: 5px
					em
						padding-right: 10px
						line-height: 1
						color: $main
						font-size: 18px
					a + a
						margin-left: r(4px)
		&.bg-blue
			background-color: $main
			border: thin solid $tran
			justify-content: center
			.title
				color: $white
				margin-top: r(30px)
				font-weight: 500
		.title
			color: $main
			font-size: 16px
			margin-top: r(10px)
			margin-bottom: r(10px)
			text-align: center
			text-transform: uppercase
		.title-sub,
		.content
			color: $text
			font-size: 14px
			letter-spacing: -.25px
		.title-sub
			font-weight: 600
.page-contact-1
	padding: r(10px) 0 r(80px)
	.wrap-form
		.form-row
			display: flex
			justify-content: space-between
			@media screen and ( max-width: 767.9px )
				display: block
			.form-group
				width: 49%
				@media screen and ( max-width: 767.9px )
					width: 100%
		.form-group
			input[type="text"]
				border: thin solid $gray_2
				height: 40px
				border-radius: 0
				letter-spacing: 0
			textarea
				width: 100%
				color: $gray_3
				font-size: 14px
				border: thin solid $gray_2
				padding: 10px 20px
				height: 150px
				margin-bottom: 25px
		.frm-btnwrap
			.frm-btn
				input[type="submit"]
					width: 180px
				input[type="reset"]
					display: none
	.wrap-map
		margin-top: r(40px)
		iframe
			@media screen and ( max-width: 1023.9px )
				height: 350px
	.wrap-form
		.form-row
			display: flex
			justify-content: space-between
			@media screen and ( max-width: 767.9px )
				display: block
			.form-group
				width: 49%
				@media screen and ( max-width: 767.9px )
					width: 100%
		.form-group
			input[type="text"]
				border-radius: 0
				height: 50px
				padding-left: r(20px)
				font-size: 14px
				color: $gray_3
				letter-spacing: 1.5px
				width: 100%
				margin-bottom: r(30px)
			&.form-row
				display: flex
				justify-content: space-between
				@media screen and ( max-width: 400px )
					display: block
				input[type="text"]
					width: 48.5%
					@media screen and ( max-width: 400px )
						width: 100%
		.frm-btnwrap
			.frm-btn
				position: relative
				width: fit-content
				input[type="reset"]
					display: none
				input[type="submit"]
					width: 150px
					height: 50px
					background-color: $red-1
					color: white
					border: unset
					cursor: pointer
					transition: .3s all ease-in-out
				&::before
					content: ""
					position: absolute
					background: url("../img/btn-icon.png")no-repeat
					background-position-y: 50%
					top: 50%
					transform: translateY(-50%)
					left: 0
					width: 100%
					height: 100%
					pointer-events: none
					transition: .3s all ease-in-out
				&:hover
					input[type="submit"]
						background-color: $main
					&::before
						background-position-x: 100%
