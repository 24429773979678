.index-5
    background: url(../img/bg3.png) no-repeat top center
    background-size: cover
    .swiper-news
        position: relative
        @media screen and ( min-width: 1024px )
            .swiper-slide:first-child
                width: 600px!important
                height: 360px!important
            .swiper-slide
                height: 360px
            .swiper-slide:not(:first-child)
                .item
                    .img
                        +img-ratio(230/285)
                    time
                        margin: r(8px) 0
    .item
        background: #e8f3eb
        display: block
        height: 100%
        .wrap-content
            height: 117.5px
            padding: r(10px) r(30px) r(30px)
        .wrap-content
            .title
                font-size: r(18px)
                @include line(3)
            .content
                font-size: r(14px)
                color: $text-2
        &:hover
            .title
                color: $main
        @media screen and ( max-width: 1023.9px )     
            .img
                +img-ratio(230/285)
            .title
                margin-bottom: 0
        &.xl
            @media screen and ( min-width: 1024px )  
                display: flex
                .img
                    img
                        height: 360px
                        object-fit: cover
                .wrap-content
                    padding: r(30px)
                    max-width: 285px
                    display: flex
                    justify-content: center
                    flex-direction: column
                    height: 100%
            .button
                margin-top: auto
                @media screen and ( max-width: 1023.9px )
                    display: none
            .content
                @include line(4)
                @media screen and ( max-width: 1023.9px )
                    display: none 
        &.sm
            .wrap-content
                .title
                    margin-bottom: 0