.arrow-button
    &.white
        .swiper-button-prev,.swiper-button-next
            border: thin solid $white
            em
                color: $white
            &:hover
                background-color: #ea2530
        .swiper-button-prev
            left: -45px
        .swiper-button-next
            right: -45px
    em
        color: $gray_3
    .swiper-button-prev,.swiper-button-next
        transition: .3s all  ease-in-out
        top: 50%
        height: 30px !important
        width: 30px !important
        border: 2px solid $gray_3
        &::after
            display: none
        &:hover
            background-color: $main
            border: 2px solid $tran
            em
                color: white
        
    .swiper-button-prev
        left: -50px
        @media screen and ( max-width: 1440px )
            left: -60px
    .swiper-button-next
        right: -50px
        @media screen and ( max-width: 1440px )
            right: -60px
    .swiper-button-prev,.swiper-button-next
        transition: .3s all  ease-in-out
        top: 50%
        height: 35px
        width: 35px
        border: 2px solid $gray_3
    @media screen and ( max-width: 1280px )
        display: flex
        justify-content: center
        align-items: center
        padding-top: 30px
        position: relative
        .swiper-button-prev
            margin-right: 10px
        .swiper-button-prev,.swiper-button-next
            position: static
