@import src/components/_core/_mixin.sass
.account-wrapper
	display: flex
	align-items: center
	em
		color: #999999
		font-size: 1rem
		font-weight: 400
		display: block
	.wrap
		padding-left: 10px
		display: flex
		align-items: center
		ul
			padding: 0px
			li
				color: #666666
				font-size: r(14px)
				font-weight: 400
				font-style: italic
				white-space: nowrap
				list-style: none
				transition: .3s all  ease-in-out
				a
					&::after
						display: none
				&:nth-of-type(2)
					display: none
				&:hover
					color: $main
					a
						color: $main !important
		.logout
			margin-left: 5px
			color: #666666
			font-size: r(14px)
			font-weight: 400
			font-style: italic
			white-space: nowrap
			list-style: none
			transition: .3s all  ease-in-out
			&:hover
				color: $main
				a
					color: $main !important
.user-sidemenu
	padding: 60px 0px
	@media screen and ( max-width: 768px )
		padding: 30px 0px
	// .row
	// 	width: 100%
