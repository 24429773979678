.news-detail-page
	.fancybox-content
		max-width: 920px !important
		@extend .mx-auto
		padding: 0 60px !important
		border-radius: 20px 0
		background-color: $white
		overflow: hidden !important
		@media screen and ( max-width: 768px )
			padding: 0 20px !important
			width: 100% !important
	.fancybox-slide
		@media screen and ( max-width: 576px )
			padding: 44px 15px !important
.RadUpload
	@media screen and ( max-width: 576px )
		width: fit-content !important
.apply-frm
	padding: 30px 10px!important
	.title
		font-weight: 700
		font-size: 2rem
		@media screen and ( min-width: 1280px )
			font-size: 24px
	h4
		display: block
		margin-bottom: 15px
		font-weight: 700
		font-size: 16px
		span
			margin: 0 r(-3px)
	.form-group
		margin-bottom: 20px
		position: relative
		label
			color: $text-2
			font-size: r(16px)
			font-weight: 400
			transition: .5s all ease-in-out
			span
				// color: #ff0000
				color: $text-2
		input,textarea
			padding: 10px 0
			width: 100%
			border: 0
			height: 40px
			outline: 0
			background-color: $tran
			border-bottom: thin solid #e0e0e0
			// &:focus,&:valid
			// 	+ label
			// 		top: -2px
			// 		font-size: r(14px)
	.frm-btnwrap
		padding: 0 15px
		.frm-btn
			margin-left: 0
			position: relative
			@media screen and ( max-width: 576px )
				margin-top: 15px
			&.btn-seemore
				&:hover
					input
						border-color: transparent
						color: $red-1
						background-color: $tran

		input
			border-bottom: 0
			color: $white
			font-size: r(18px)
			font-weight: 700
			text-align: center
			text-transform: uppercase
			width: fit-content
	.attachfile1,.attachfile2
		padding: 0 15px
		label
			position: static
			span
				color: $text-2
		.left
			.ruFileWrap.ruStyled
				width: 160px
				height: 40px
				border-radius: 20px
				background-color: $bg_color !important
				transition: .3s all ease-in-out
				display: flex
				justify-content: center
				align-items: center
				input
					color: $text-3
					font-size: r(20px)
					font-weight: 400
					border: 0 !important
					outline: 0 !important
					background: none !important
.introduce__pages
	.fancybox-content
