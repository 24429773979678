header
	position: fixed
	z-index: 999
	top: 0
	left: 0
	width: 100%
	background-color: #fff
	transition: .3s all ease-in-out
	padding: 7px 0px
	&.header-scroll
		box-shadow: 0px 0px 20px rgba(#000 ,0.25 )
	&.disable_bsd
		box-shadow: none !important
	@media screen and ( max-width: 1280px )
		box-shadow: 0px 0px 20px rgba(#000 ,0.25 )
	ul
		padding: 0
		li
			list-style: none
			cursor: pointer
	nav
		ul
			display: flex
			align-items: center
			@media screen and ( max-width: 1280px )
				display: block
			li
				color: $main
				font-size: r(16px)
				font-weight: 700
				transition: .3s all ease-in-out
				&.active,&:hover
					color: $red-1
				& + li
					margin-left: 30px
					@media screen and ( max-width: 1280px )
						margin-left: 0px
						padding: 5px 0px

	.container
		display: flex
		justify-content: space-between
		align-items: center

		.left-side
			@media screen and ( max-width: 1280px )
				display: none

		.middle
			flex: 0 0 103px
			@media screen and ( min-width: 1024px )
				margin-left: 54px
			@media screen and ( max-width: 1280px )
				flex: 0 0 72px
			.logo
				width: 100%
				height: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover
		.right-side
			display: flex
			justify-content: center
			align-items: center
			@media screen and ( max-width: 459.9px )
				justify-content: flex-start
			.right-menu
				nav
					ul
						li
			.search-icon
				margin-left: 30px
				cursor: pointer
				@media screen and ( max-width: 459.9px )
					margin-left: 0
				.icon
					display: flex
					align-items: center
				em
					color: $gray_3
					font-size: r(18px)
					font-weight: 400
					transition: all .3s
				&:hover
					em
						color: $red-1

	.language
		margin-left: 20px
		cursor: pointer
		position: relative
		display: flex
		justify-content: center
		align-items: center
		&::before
			position: absolute
			content: ''
			width: 100%
			height: 0px
			top: 100%
		&:hover
			&::before
				height: 30px
			.list-change
				visibility: visible
				pointer-events: auto
				opacity: 1
				transform: translateY(5px)
			em
				transform: rotate(180deg)
		span
			color: #999999
			font-size: r(14px)
			font-weight: 400
		em
			color: #999999
			font-size: r(18px)
			font-weight: 400
			transition: .2s all ease-in-out
		@media screen and ( max-width: 1200px )
			span
				font-size: 1rem
		.list-change
			position: absolute
			top: 100%
			left: 0
			pointer-events: none
			visibility: hidden
			opacity: 0
			transition: .3s all ease-in-out
			transform: translateY(20px)
			&:hover
				ul
					a
						color: white
						border: thin solid $red-1
						background-color: $red-1
	.nav-mobile
		display: none
		@media screen and ( max-width: 1280px )
			display: block
			width: 50%
			height: 100%
			background-color: #fff
			position: fixed
			top: 60px
			right: 0
			z-index: 1000
			padding: 15px
			opacity: 0
			bottom: 0
			overflow-y: auto
			overflow-x: hidden
			pointer-events: none
			transform: translateX(20%)
			transition: all .5s cubic-bezier(.68,-.55,.265,1.55)
			max-width: 320px
			width: 100%
			box-shadow: 0px 0px 15px rgba(black ,0.15 )
		&.active
			opacity: 1
			pointer-events: visible
			visibility: visible
			transform: translate(0)
			.close-button
				em
					transform: translate(0)
					transition-delay: 1.3s
			.icon,ul li,.login_register li,.left-side .item
				-webkit-transition: all 0.5s
				-moz-transition: all 0.5s
				-o-transition: all 0.5s
				transition: all 0.5s
				-webkit-animation-duration: 0.5s
				animation-duration: 0.5s
				-webkit-animation-fill-mode: both
				animation-fill-mode: both
				-webkit-animation-name: moveInRight
				animation-name: moveInRight
			nav
				ul
					li
						font-size: 1.3rem
						border-bottom: thin solid #ccc
						padding: 5px 0
						&:nth-child(1)
							animation-delay: .5s
						&:nth-child(2)
							animation-delay: .6s
						&:nth-child(3)
							animation-delay: .7s
						&:nth-child(4)
							animation-delay: .8s
						&:nth-child(5)
							animation-delay: .9s
						&:nth-child(6)
							animation-delay: 1s
						&:nth-child(7)
							animation-delay: 1.1s
						&:nth-child(8)
							animation-delay: 1.2s
		.close-button
			padding: 0px
			padding-bottom: 10px
			display: flex
			align-items: center
			border-bottom: thin solid $gray_2
			cursor: pointer
			em
				font-size: r(30px)
				font-weight: 300
				color: $text
				margin-left: auto
				transform: translateX(200%)
				transition: 0.8s all cubic-bezier(.68,-.55,.265,1.55)
			@media screen and ( min-width:1280.1px )
				display: none

.hotline
	display: flex
	align-items: center
	position: relative
	margin-left: 15px
	padding-left: 15px
	@media screen and ( max-width: 459.9px )
		margin-left: 0
		padding-left: 0
		margin-top: 15px
	&::before
		position: absolute
		content: ''
		left: 0px
		top: 50%
		transform: translateY(-50%)
		width: 1px
		height: 30px
		background-color: rgba( $gray_3 , 0.25)
		@media screen and ( max-width: 459.9px )
			display: none
	.number
		margin-left: 5px
		color: #9a9999
		font-size: r(12px)
		font-weight: 400
		display: flex
		align-items: center
		a
			margin-left: 5px
			color: $red-1
			font-size: r(16px)
			font-weight: 700
