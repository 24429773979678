.global-breadcrumb,.global-breadcrumb_2
	width: 100%
	border-top: thin solid $gray_2
	border-bottom: thin solid $gray_2
	&.global-breadcrumb_2
		margin-top: 0px
		background-color: $white
		border-bottom: thin solid #e0e0e0
		ol
			li
				color: $text
				& + li
					&::before
						color: $text
	.breadcrumb
		height: 40px
		display: flex
		align-items: center
		background-color: transparent
		flex-wrap: wrap
		list-style: none
		margin-bottom: 0
		padding: 0px
		li
			position: relative
			display: flex
			align-items: center
			color: $text
			font-size: r(14px)
			font-weight: 400
			// &:first-child
			// 	width: r(16px)
			// 	&:hover
			// 		a
			// 			&::before
			// 				color: $main
				// a
				// 	font-size: 0px

				// 	&::before
				// 		font-size: r(14px)
				// 		font-weight: 400
				// 		content: "\e800"
				// 		font-family: Linearicons-Free
				// 		transition: .3s all  ease-in-out
			& + li
				margin-left: 20px
			&.active
				color: $main
			&:hover
				color: $main

		li+li 
			&::before
				position: absolute
				top: 50%
				left: -20px
				color: $text
				font-size: r(24px)
				transform: translateY(-50%)
				content: "\ea54"
				font-family: 'remixicon'
