.article-wrapper
    .article-head
        padding-bottom: 10px
        border-bottom: thin solid  #d5d5d5
        margin-bottom: r(20px)
        .block-title
            text-transform: none
            line-height: normal
            margin-bottom: 10px
            font-size: r(24px)
        .wrapper
            margin-top: r(15px)
            display: flex
            justify-content: space-between
            align-items: center
    .article-body
        *
            margin-bottom: r(30px)
        h2,
        h3,
        h4,
        h5,
        h6
            position: relative
            padding-left: 15px
            &::before
                content: ""
                position: absolute
                left: 0
                top: 0
                height: 100%
                width: 3px
                background-color: #ea2530
        img
            display: block
            width: 100%
            margin-left: auto
            margin-right: auto
.social-icons
    display: flex
    .icon
        display: flex
        align-items: center
        background: #1877f2
        height: 20px
        border-radius: 3px
        min-width: 50px
        padding: 3px 8px
        margin-right: 5px
        font-weight: bold
        font-weight: 600
        font-size: 14px
        &:last-child
            margin-right: 0
        *
            color: $white
    em
        color: #0073c3
        font-size: 18px
.page-news-detail
    .news-other
        .wrap-header
            .block-title
                font-size: r(24px)
                position: relative
                &::before
                    content: ""
                    position: absolute
                    bottom: -15px
                    height: 2px
                    width: 50px
                    background-color: $red-1
                    left: 0
        .wrap-body
            padding: 20px 0
            border-bottom: 1px solid #e0e0e0
            &:nth-child(2)
                margin-top: 15px
            .item
                display: flex
                flex-direction: row
                position: relative
                height: auto
                width: 100%
                @media screen and ( max-width: 1279.9px )
                    height: auto
                .img
                    +img-ratio(80/105)
                    padding-top: 28.19048%
                    max-width: 105px
                    width: 100%
                    @media screen and ( max-width: 1279.9px )
                        padding-top: 44.22222%
                    @media screen and ( max-width: 1023.9px )
                        +img-ratio(84/220)
                        padding-top: 24.22222%
                        max-width: 220px
                    @media screen and ( max-width: 767.9px )
                        padding-top: 27.22222%
                    @media screen and ( max-width: 479.9px )
                        padding-top: 42%
                    img
                        max-width: 135px
                        @media screen and ( max-width: 1023.9px )
                             max-width: 220px
                .box-content
                    padding: 10px 15px 15px
                    .title
                        @include line(3)
                        font-size: 14px
                        margin-bottom: 0
                        text-transform: none
                        transition: .3s all ease-in-out
                        &:hover
                            color: #333399