$main: #283a97
$red-1: #ee202e
$body: #ffffff
$gray: #606060
$black: #111111
$gray_2: #cccccc
$gray_3: #999999
$gray_4: #444444
$text: #666666
$text-1: #222222
$text-2: #333333
$text-3: #333333
$white: #ffffff
$pink: #fcb4b4
$pink1: #f4c3c5
$bg_f7: #f7f7f7
$bg_color: #f5f5f5
$tran: transparent
$border: #a0adb6
