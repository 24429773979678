body {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }

::selection {
  background-color: #ee202e;
  color: white; }

* {
  outline: none; }

.container {
  padding-right: 30px;
  padding-left: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }

@media (min-width: 280px) {
  .container {
    max-width: 500px;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 1024px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1280px) {
  .container {
    max-width: 1260px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.no-gutters > *,
.no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

[class*="col-"] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1024px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1280px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  background-color: #cccccc;
  border-radius: 20px; }

::-webkit-scrollbar {
  width: 4px;
  height: 4px; }

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  background-color: #283a97; }

html {
  font-family: 'Play', sans-serif;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: 'Play', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  background-color: #ffffff;
  color: #333333;
  margin-top: 0;
  margin-bottom: 0; }

body, html {
  font-size: 13px; }
  @media (min-width: 1024px) {
    body, html {
      font-size: 14px; } }
  @media (min-width: 1280px) {
    body, html {
      font-size: 16px; } }

* {
  box-sizing: border-box; }
  *::before, *::after {
    box-sizing: border-box; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0;
  margin-left: 0; }

blockquote {
  margin: 0; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  text-decoration: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: inherit; }

a:not([href]):not([tabindex]) {
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: none;
  outline: 5px auto -webkit-focus-ring-color; }

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

select {
  word-wrap: normal; }

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden], .d-none, .hidden {
  display: none !important; }

input:focus, button:focus, a:focus, .button-prev:focus, .button-next:focus, textarea:focus, select:focus {
  outline: none; }

img {
  max-width: 100%;
  height: auto; }

.zoom-img {
  overflow: hidden; }
  .zoom-img img {
    transform: scale(1.05);
    transition: .3s all  ease-in-out; }
  .zoom-img:hover img {
    transform: scale(1);
    ransition: .3s all  ease-in-out; }

@keyframes flash {
  50% {
    opacity: 0.5; } }

@keyframes fadding {
  0% {
    opacity: 0;
    transform: scale(0.5); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes reveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slideRight {
  0% {
    left: -50px; }
  100% {
    left: 100%; } }

@keyframes slideLeft {
  0% {
    left: 100%; }
  100% {
    left: -50px; } }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@-webkit-keyframes returnToNormal {
  from { }
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  to { }
  opacity: 1;
  -webkit-transform: none;
  transform: none; }

@keyframes returnToNormal {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes growth {
  from {
    transform: scale(0.7); }
  to {
    transform: scale(1); } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(-50px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes moving {
  0% {
    transform: translateY(5px); }
  100% {
    transform: translateY(-5px); } }

@keyframes buzz {
  50% {
    border-radius: 0% 13% 0% 0%; } }

.button, #modal .modal-box .modal-body .frm-btnwrap .frm-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase; }
  .button.start, #modal .modal-box .modal-body .frm-btnwrap .start.frm-btn {
    justify-content: flex-start;
    margin-top: 1.875rem; }
  .button .btn, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn, .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"] {
    cursor: pointer;
    width: 180px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 1.4px;
    border: 1px solid transparent;
    transition: .3s all ease-in-out;
    line-height: normal;
    position: relative; }
    .button .btn.link-seemore, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.link-seemore, .button #modal .modal-box .modal-body .frm-btnwrap input.link-seemore[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input.link-seemore[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.link-seemore[type="submit"] {
      color: #283a97;
      width: auto;
      height: auto; }
      .button .btn.link-seemore .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.link-seemore .btn-icon, .button #modal .modal-box .modal-body .frm-btnwrap input.link-seemore[type="submit"] .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .button input.link-seemore[type="submit"] .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.link-seemore[type="submit"] .btn-icon {
        left: auto;
        right: -40px;
        top: 45%;
        transform: translateY(-45%); }
      .button .btn.link-seemore:hover, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.link-seemore:hover, .button #modal .modal-box .modal-body .frm-btnwrap input.link-seemore:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input.link-seemore:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.link-seemore:hover[type="submit"] {
        background: transparent; }
        .button .btn.link-seemore:hover .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.link-seemore:hover .btn-icon, .button #modal .modal-box .modal-body .frm-btnwrap input.link-seemore:hover[type="submit"] .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .button input.link-seemore:hover[type="submit"] .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.link-seemore:hover[type="submit"] .btn-icon {
          left: auto;
          right: -50px; }
    .button .btn .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn .btn-icon, .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"] .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .button input[type="submit"] .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"] .btn-icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: .4s all ease-in-out; }
      .button .btn .btn-icon img, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn .btn-icon img, .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"] .btn-icon img, #modal .modal-box .modal-body .frm-btnwrap .button input[type="submit"] .btn-icon img, #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"] .btn-icon img {
        filter: brightness(0) invert(1); }
      .button .btn .btn-icon.no-bright img, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn .btn-icon.no-bright img, .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"] .btn-icon.no-bright img, #modal .modal-box .modal-body .frm-btnwrap .button input[type="submit"] .btn-icon.no-bright img, #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"] .btn-icon.no-bright img {
        filter: brightness(1) invert(0); }
    .button .btn.white, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.white, .button #modal .modal-box .modal-body .frm-btnwrap input.white[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input.white[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.white[type="submit"] {
      border: thin solid #ffffff; }
    .button .btn.gray, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.gray, .button #modal .modal-box .modal-body .frm-btnwrap input.gray[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input.gray[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.gray[type="submit"] {
      border: thin solid #333333;
      color: #333333; }
    .button .btn.red, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.red, .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"] {
      width: 150px;
      background-color: #ee202e; }
      .button .btn.red:hover, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.red:hover, .button #modal .modal-box .modal-body .frm-btnwrap input:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input:hover[type="submit"] {
        background-color: #ee202e; }
    .button .btn.blue, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.blue, .button #modal .modal-box .modal-body .frm-btnwrap input.blue[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input.blue[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.blue[type="submit"] {
      background-color: #283a97; }
      .button .btn.blue:hover, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.blue:hover, .button #modal .modal-box .modal-body .frm-btnwrap input.blue:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input.blue:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.blue:hover[type="submit"] {
        background-color: #ee202e; }
    .button .btn:hover, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn:hover, .button #modal .modal-box .modal-body .frm-btnwrap input:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input:hover[type="submit"] {
      background-color: #ee202e;
      border: thin solid transparent; }
      .button .btn:hover .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn:hover .btn-icon, .button #modal .modal-box .modal-body .frm-btnwrap input:hover[type="submit"] .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .button input:hover[type="submit"] .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .frm-btn input:hover[type="submit"] .btn-icon {
        line-height: 1.6;
        left: 83%;
        right: 0;
        transition: .4s all ease-in-out; }
      .button .btn:hover .blue, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn:hover .blue, .button #modal .modal-box .modal-body .frm-btnwrap input:hover[type="submit"] .blue, #modal .modal-box .modal-body .frm-btnwrap .button input:hover[type="submit"] .blue, #modal .modal-box .modal-body .frm-btnwrap .frm-btn input:hover[type="submit"] .blue {
        background-color: transparent; }
      .button .btn:hover.gray, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn:hover.gray, .button #modal .modal-box .modal-body .frm-btnwrap input:hover.gray[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input:hover.gray[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input:hover.gray[type="submit"] {
        color: white; }
        .button .btn:hover.gray .no-bright img, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn:hover.gray .no-bright img, .button #modal .modal-box .modal-body .frm-btnwrap input:hover.gray[type="submit"] .no-bright img, #modal .modal-box .modal-body .frm-btnwrap .button input:hover.gray[type="submit"] .no-bright img, #modal .modal-box .modal-body .frm-btnwrap .frm-btn input:hover.gray[type="submit"] .no-bright img {
          filter: brightness(0) invert(1); }
    .button .btn.btn-expand, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.btn-expand, .button #modal .modal-box .modal-body .frm-btnwrap input.btn-expand[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input.btn-expand[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.btn-expand[type="submit"] {
      display: flex;
      align-items: center; }
      .button .btn.btn-expand .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.btn-expand .btn-icon, .button #modal .modal-box .modal-body .frm-btnwrap input.btn-expand[type="submit"] .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .button input.btn-expand[type="submit"] .btn-icon, #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.btn-expand[type="submit"] .btn-icon {
        position: static;
        margin-left: 5px;
        transform: rotate(90deg); }
  .button.inner-white .btn img, #modal .modal-box .modal-body .frm-btnwrap .inner-white.frm-btn .btn img, .button.inner-white #modal .modal-box .modal-body .frm-btnwrap input[type="submit"] img, #modal .modal-box .modal-body .frm-btnwrap .button.inner-white input[type="submit"] img, #modal .modal-box .modal-body .frm-btnwrap .inner-white.frm-btn input[type="submit"] img {
    position: absolute;
    right: 0;
    filter: brightness(0) invert(1);
    transition: .3s all; }
  .button.inner-white .btn:hover, #modal .modal-box .modal-body .frm-btnwrap .inner-white.frm-btn .btn:hover, .button.inner-white #modal .modal-box .modal-body .frm-btnwrap input:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button.inner-white input:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .inner-white.frm-btn input:hover[type="submit"] {
    background-color: #ffffff;
    color: #000b44;
    border: 1px solid #000b44; }
    .button.inner-white .btn:hover img, #modal .modal-box .modal-body .frm-btnwrap .inner-white.frm-btn .btn:hover img, .button.inner-white #modal .modal-box .modal-body .frm-btnwrap input:hover[type="submit"] img, #modal .modal-box .modal-body .frm-btnwrap .button.inner-white input:hover[type="submit"] img, #modal .modal-box .modal-body .frm-btnwrap .inner-white.frm-btn input:hover[type="submit"] img {
      filter: brightness(1) invert(0); }

.button, #modal .modal-box .modal-body .frm-btnwrap .frm-btn {
  cursor: pointer; }
  .button *, #modal .modal-box .modal-body .frm-btnwrap .frm-btn * {
    white-space: nowrap; }
  .button .btn.btn-seemore, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.btn-seemore, .button #modal .modal-box .modal-body .frm-btnwrap input.btn-seemore[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input.btn-seemore[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.btn-seemore[type="submit"] {
    width: 200px;
    height: 50px;
    border-radius: 25px;
    background-color: #ee202e;
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s all ease-in-out;
    border: thin solid transparent; }
    .button .btn.btn-seemore:hover, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.btn-seemore:hover, .button #modal .modal-box .modal-body .frm-btnwrap input.btn-seemore:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .button input.btn-seemore:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.btn-seemore:hover[type="submit"] {
      background-color: #ffffff;
      border: thin solid #ee202e;
      color: #ee202e; }
      .button .btn.btn-seemore:hover em, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.btn-seemore:hover em, .button #modal .modal-box .modal-body .frm-btnwrap input.btn-seemore:hover[type="submit"] em, #modal .modal-box .modal-body .frm-btnwrap .button input.btn-seemore:hover[type="submit"] em, #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.btn-seemore:hover[type="submit"] em {
        margin-left: 20px;
        color: #ee202e; }
    .button .btn.btn-seemore em, #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn.btn-seemore em, .button #modal .modal-box .modal-body .frm-btnwrap input.btn-seemore[type="submit"] em, #modal .modal-box .modal-body .frm-btnwrap .button input.btn-seemore[type="submit"] em, #modal .modal-box .modal-body .frm-btnwrap .frm-btn input.btn-seemore[type="submit"] em {
      font-size: 1.25rem;
      margin-left: 15px;
      transition: .3s all ease-in-out;
      color: #ffffff; }

.primary-tab {
  margin-bottom: 1.875rem;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .primary-tab .tab-link {
    overflow: auto;
    display: flex;
    padding: 0;
    list-style: none;
    transition: all .3s ease-in-out;
    margin: 0 -5px; }
    .primary-tab .tab-link::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
      background-color: #cccccc; }
    .primary-tab .tab-link::-webkit-scrollbar {
      margin-top: 10px;
      width: 2px;
      height: 2px; }
    .primary-tab .tab-link::-webkit-scrollbar-thumb {
      border-radius: unset;
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
      background-color: #283a97; }
    @media screen and (max-width: 767.9px) {
      .primary-tab .tab-link {
        justify-content: flex-start; } }
    .primary-tab .tab-link li {
      font-size: 1.125rem;
      font-weight: 600;
      color: #283a97;
      letter-spacing: -.25px;
      margin-right: 1.875rem;
      position: relative;
      scroll-snap-align: center;
      scroll-snap-stop: always;
      margin: 5px;
      height: auto; }
      .primary-tab .tab-link li:last-child {
        margin-right: 0; }
      .primary-tab .tab-link li.active, .primary-tab .tab-link li:hover {
        font-weight: 700;
        color: #ffffff; }
        .primary-tab .tab-link li.active a, .primary-tab .tab-link li:hover a {
          background-color: #ea2530; }
      .primary-tab .tab-link li a {
        text-align: center;
        height: 100%;
        width: 18.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 30px;
        background-color: #ffffff;
        transition: .3s all ease-in-out; }
  .primary-tab.white li {
    color: #ffffff; }
    .primary-tab.white li::before {
      background-color: #ffffff; }
    .primary-tab.white li.active {
      color: #ffffff; }
      .primary-tab.white li.active::before {
        background: #ffffff; }
    .primary-tab.white li:hover {
      color: #ffffff; }
      .primary-tab.white li:hover::before {
        width: 50px; }

#advisory-form {
  display: none;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto; }
  #advisory-form .advisory-form {
    padding: 3.75rem 0; }
  #advisory-form .form-group input {
    border-bottom: thin solid #888888;
    border-radius: 0; }
  #advisory-form .frm-btnwrap input {
    border-bottom: thin solid transparent; }
  #advisory-form .wrap-form .form-row {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 767.9px) {
      #advisory-form .wrap-form .form-row {
        display: block; } }
    #advisory-form .wrap-form .form-row .form-group {
      width: 49%; }
      @media screen and (max-width: 767.9px) {
        #advisory-form .wrap-form .form-row .form-group {
          width: 100%; } }
  #advisory-form .wrap-form .form-group input[type="text"] {
    border-radius: 0 !important;
    border: 0;
    border-bottom: thin solid #ccc;
    height: 50px;
    padding-left: 1.25rem;
    font-size: 14px;
    color: #999999;
    letter-spacing: 1.5px;
    width: 100%;
    margin-bottom: 0.9375rem; }
  #advisory-form .wrap-form .form-group.form-row {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 400px) {
      #advisory-form .wrap-form .form-group.form-row {
        display: block; } }
    #advisory-form .wrap-form .form-group.form-row input[type="text"] {
      width: 48.5%; }
      @media screen and (max-width: 400px) {
        #advisory-form .wrap-form .form-group.form-row input[type="text"] {
          width: 100%; } }
  #advisory-form .wrap-form .frm-btnwrap .frm-btn {
    position: relative;
    width: fit-content; }
    #advisory-form .wrap-form .frm-btnwrap .frm-btn input[type="reset"] {
      display: none; }
    #advisory-form .wrap-form .frm-btnwrap .frm-btn input[type="submit"] {
      width: 150px;
      height: 50px;
      background-color: #ee202e;
      color: white;
      border: unset;
      cursor: pointer;
      transition: .3s all ease-in-out; }
    #advisory-form .wrap-form .frm-btnwrap .frm-btn::before {
      content: "";
      position: absolute;
      background: url("../img/btn-icon.png") no-repeat;
      background-position-y: 50%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      transition: .3s all ease-in-out; }
    #advisory-form .wrap-form .frm-btnwrap .frm-btn:hover input[type="submit"] {
      background-color: #283a97; }
    #advisory-form .wrap-form .frm-btnwrap .frm-btn:hover::before {
      background-position-x: 100%; }

.news-detail-page .fancybox-content {
  max-width: 920px !important;
  padding: 0 60px !important;
  border-radius: 20px 0;
  background-color: #ffffff;
  overflow: hidden !important; }
  @media screen and (max-width: 768px) {
    .news-detail-page .fancybox-content {
      padding: 0 20px !important;
      width: 100% !important; } }

@media screen and (max-width: 576px) {
  .news-detail-page .fancybox-slide {
    padding: 44px 15px !important; } }

@media screen and (max-width: 576px) {
  .RadUpload {
    width: fit-content !important; } }

.apply-frm {
  padding: 30px 10px !important; }
  .apply-frm .title {
    font-weight: 700;
    font-size: 2rem; }
    @media screen and (min-width: 1280px) {
      .apply-frm .title {
        font-size: 24px; } }
  .apply-frm h4 {
    display: block;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 16px; }
    .apply-frm h4 span {
      margin: 0 -0.1875rem; }
  .apply-frm .form-group {
    margin-bottom: 20px;
    position: relative; }
    .apply-frm .form-group label {
      color: #333333;
      font-size: 1rem;
      font-weight: 400;
      transition: .5s all ease-in-out; }
      .apply-frm .form-group label span {
        color: #333333; }
    .apply-frm .form-group input, .apply-frm .form-group textarea {
      padding: 10px 0;
      width: 100%;
      border: 0;
      height: 40px;
      outline: 0;
      background-color: transparent;
      border-bottom: thin solid #e0e0e0; }
  .apply-frm .frm-btnwrap {
    padding: 0 15px; }
    .apply-frm .frm-btnwrap .frm-btn {
      margin-left: 0;
      position: relative; }
      @media screen and (max-width: 576px) {
        .apply-frm .frm-btnwrap .frm-btn {
          margin-top: 15px; } }
      .apply-frm .frm-btnwrap .frm-btn.btn-seemore:hover input {
        border-color: transparent;
        color: #ee202e;
        background-color: transparent; }
    .apply-frm .frm-btnwrap input {
      border-bottom: 0;
      color: #ffffff;
      font-size: 1.125rem;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      width: fit-content; }
  .apply-frm .attachfile1, .apply-frm .attachfile2 {
    padding: 0 15px; }
    .apply-frm .attachfile1 label, .apply-frm .attachfile2 label {
      position: static; }
      .apply-frm .attachfile1 label span, .apply-frm .attachfile2 label span {
        color: #333333; }
    .apply-frm .attachfile1 .left .ruFileWrap.ruStyled, .apply-frm .attachfile2 .left .ruFileWrap.ruStyled {
      width: 160px;
      height: 40px;
      border-radius: 20px;
      background-color: #f5f5f5 !important;
      transition: .3s all ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center; }
      .apply-frm .attachfile1 .left .ruFileWrap.ruStyled input, .apply-frm .attachfile2 .left .ruFileWrap.ruStyled input {
        color: #333333;
        font-size: 1.25rem;
        font-weight: 400;
        border: 0 !important;
        outline: 0 !important;
        background: none !important; }

.section {
  padding: 35px 0; }
  @media (min-width: 768px) {
    .section {
      padding: 40px 0; } }
  @media (min-width: 1024px) {
    .section {
      padding: 45px 0; } }
  @media (min-width: 1280px) {
    .section {
      padding: 60px 0px; } }

.arrow-button.white .swiper-button-prev, .arrow-button.white .swiper-button-next {
  border: thin solid #ffffff; }
  .arrow-button.white .swiper-button-prev em, .arrow-button.white .swiper-button-next em {
    color: #ffffff; }
  .arrow-button.white .swiper-button-prev:hover, .arrow-button.white .swiper-button-next:hover {
    background-color: #ea2530; }

.arrow-button.white .swiper-button-prev {
  left: -45px; }

.arrow-button.white .swiper-button-next {
  right: -45px; }

.arrow-button em {
  color: #999999; }

.arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
  transition: .3s all  ease-in-out;
  top: 50%;
  height: 30px !important;
  width: 30px !important;
  border: 2px solid #999999; }
  .arrow-button .swiper-button-prev::after, .arrow-button .swiper-button-next::after {
    display: none; }
  .arrow-button .swiper-button-prev:hover, .arrow-button .swiper-button-next:hover {
    background-color: #283a97;
    border: 2px solid transparent; }
    .arrow-button .swiper-button-prev:hover em, .arrow-button .swiper-button-next:hover em {
      color: white; }

.arrow-button .swiper-button-prev {
  left: -50px; }
  @media screen and (max-width: 1440px) {
    .arrow-button .swiper-button-prev {
      left: -60px; } }

.arrow-button .swiper-button-next {
  right: -50px; }
  @media screen and (max-width: 1440px) {
    .arrow-button .swiper-button-next {
      right: -60px; } }

.arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
  transition: .3s all  ease-in-out;
  top: 50%;
  height: 35px;
  width: 35px;
  border: 2px solid #999999; }

@media screen and (max-width: 1280px) {
  .arrow-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    position: relative; }
    .arrow-button .swiper-button-prev {
      margin-right: 10px; }
    .arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
      position: static; } }

.text-white {
  color: #ffffff; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.title {
  color: #333333;
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 25px;
  line-height: normal; }
  .title.inner {
    color: #ffffff;
    text-transform: uppercase;
    text-align: left;
    transition: .3s all ease-in-out; }
    .title.inner:hover {
      color: #ee202e; }
  .title.text-upper {
    text-transform: uppercase; }
  .title.text-left {
    text-align: left; }

.see-all {
  color: #444444;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 1.4px; }
  .see-all.bold {
    font-weight: 700; }
  .see-all.gray_3 {
    color: #999999; }

.block-title {
  color: #333333;
  font-size: 2.25rem;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.9px;
  margin-bottom: 1.25rem; }
  .block-title.text-upper {
    text-transform: uppercase; }
  .block-title.text-left {
    text-align: left; }

time {
  opacity: 0.75;
  display: block;
  color: #666666;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 1.2px;
  margin-bottom: 20px;
  transition: .3s all  ease-in-out; }

.d-flex {
  display: flex; }

.justify-between {
  justify-content: space-between; }

.justify-center {
  justify-content: center; }

.align-center {
  align-items: center; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.flex-wrap {
  flex-wrap: wrap; }

.text-white {
  color: #ffffff; }

.mt-60 {
  margin-top: 3.75rem; }
  @media screen and (max-width: 1023.98px) {
    .mt-60 {
      margin-bottom: 3.125rem; } }
  @media screen and (max-width: 768px) {
    .mt-60 {
      margin-bottom: 2.5rem; } }

.mt-30 {
  margin-top: 30px; }

.mt-20 {
  margin-top: 20px; }

.mb-80 {
  margin-bottom: 5rem; }
  @media screen and (max-width: 1023.98px) {
    .mb-80 {
      margin-bottom: 4.375rem; } }
  @media screen and (max-width: 768px) {
    .mb-80 {
      margin-bottom: 3.75rem; } }

.mb-40 {
  margin-bottom: 2.5rem; }

.mb-30 {
  margin-bottom: 30px; }

.mb-20 {
  margin-bottom: 20px; }

@media screen and (max-width: 1023.98px) {
  .mb-lg-20 {
    margin-bottom: 20px; } }

@media screen and (max-width: 1023.98px) {
  .mb-lg-30 {
    margin-bottom: 30px; } }

@media screen and (max-width: 1279.98px) {
  .mb-xl-30 {
    margin-bottom: 30px; } }

.mx-auto, .news-detail-page .fancybox-content {
  margin-right: auto;
  margin-left: auto; }

.disable {
  overflow: hidden;
  pointer-events: none; }

.text-inner {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 2; }

.info {
  display: flex;
  align-items: center;
  color: #999999;
  font-size: 14px; }
  .info .news-cate {
    position: relative; }
    .info .news-cate::before {
      content: "";
      position: absolute;
      right: -10px;
      height: 75%;
      width: 1px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #999999; }
  .info time {
    margin-bottom: 0px;
    margin-left: 20px;
    letter-spacing: normal;
    font-size: 14px;
    opacity: 1;
    color: #999999; }

.primary-sendrequired {
  background-color: #e8ecf3; }
  .primary-sendrequired.blue {
    background-color: #293b8e; }
    .primary-sendrequired.blue .wrap-box {
      height: 5.625rem; }
      @media screen and (max-width: 767.9px) {
        .primary-sendrequired.blue .wrap-box {
          height: auto; } }
    .primary-sendrequired.blue .content {
      color: #ffffff; }
  .primary-sendrequired .wrap-box {
    height: 7.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .primary-sendrequired .wrap-box .button .btn, .primary-sendrequired .wrap-box #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn, #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .frm-btn .btn, .primary-sendrequired .wrap-box .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .button input[type="submit"], .primary-sendrequired .wrap-box #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .frm-btn input[type="submit"] {
      position: relative; }
      .primary-sendrequired .wrap-box .button .btn span, .primary-sendrequired .wrap-box #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn span, #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .frm-btn .btn span, .primary-sendrequired .wrap-box .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"] span, #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .button input[type="submit"] span, .primary-sendrequired .wrap-box #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"] span, #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .frm-btn input[type="submit"] span {
        display: inline-block;
        position: absolute;
        left: 0;
        pointer-events: none;
        transition: .3s all ease-in-out; }
        .primary-sendrequired .wrap-box .button .btn span img, .primary-sendrequired .wrap-box #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn span img, #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .frm-btn .btn span img, .primary-sendrequired .wrap-box .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"] span img, #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .button input[type="submit"] span img, .primary-sendrequired .wrap-box #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"] span img, #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .frm-btn input[type="submit"] span img {
          filter: brightness(0) invert(1); }
      .primary-sendrequired .wrap-box .button .btn:hover span, .primary-sendrequired .wrap-box #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn:hover span, #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .frm-btn .btn:hover span, .primary-sendrequired .wrap-box .button #modal .modal-box .modal-body .frm-btnwrap input:hover[type="submit"] span, #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .button input:hover[type="submit"] span, .primary-sendrequired .wrap-box #modal .modal-box .modal-body .frm-btnwrap .frm-btn input:hover[type="submit"] span, #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .frm-btn input:hover[type="submit"] span {
        left: calc( 100% - 28px); }
    @media screen and (max-width: 767.9px) {
      .primary-sendrequired .wrap-box {
        height: auto;
        flex-direction: column;
        padding: 15px 0; }
        .primary-sendrequired .wrap-box .button, .primary-sendrequired .wrap-box #modal .modal-box .modal-body .frm-btnwrap .frm-btn, #modal .modal-box .modal-body .frm-btnwrap .primary-sendrequired .wrap-box .frm-btn {
          margin-top: 0.9375rem; }
        .primary-sendrequired .wrap-box .content {
          text-align: center; } }
  .primary-sendrequired .content {
    color: #333333;
    font-size: 1.5rem; }

.search-page {
  padding: 35px 0; }
  @media (min-width: 768px) {
    .search-page {
      padding: 40px 0; } }
  @media (min-width: 1024px) {
    .search-page {
      padding: 45px 0; } }
  @media (min-width: 1280px) {
    .search-page {
      padding: 60px 0px; } }
  .search-page .search-page-top .page-header h1 {
    font-size: 1.5625rem;
    font-weight: 500;
    color: #283a97; }
    @media (max-width: 1025px) {
      .search-page .search-page-top .page-header h1 {
        font-size: 26px; } }
  .search-page .search-page-top .searchresultsummary span {
    font-weight: 700;
    color: #ee202e; }
  .search-page .search-page-top .searchcontrols {
    padding: 10px 0px;
    border: 10px; }
    .search-page .search-page-top .searchcontrols .form-inline .form-group {
      width: 100%;
      position: relative; }
      .search-page .search-page-top .searchcontrols .form-inline .form-group .form-control {
        border: solid 1px #ccc;
        padding: 0px 15px;
        width: 100%;
        height: 40px;
        border-radius: 0; }
        .search-page .search-page-top .searchcontrols .form-inline .form-group .form-control:focus {
          box-shadow: none; }
      .search-page .search-page-top .searchcontrols .form-inline .form-group .seachpage-btn {
        display: none; }
  .search-page .searchresults .searchresultlist .searchresult {
    padding: 10px 0px;
    border-bottom: thin solid #ccc;
    display: flex;
    align-items: center;
    min-height: 50px; }
    .search-page .searchresults .searchresultlist .searchresult hr {
      border: 0; }
    .search-page .searchresults .searchresultlist .searchresult h3 {
      font-size: 1.125rem;
      font-weight: 600;
      color: #283a97; }
    .search-page .searchresults .searchresultlist .searchresult .searchresultdesc {
      font-size: 1rem;
      font-weight: 400; }
      .search-page .searchresults .searchresultlist .searchresult .searchresultdesc span {
        color: #ee202e;
        font-size: 1.125rem;
        font-weight: 700; }

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2));
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: .3s all ease-in-out;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center; }
  .search-overlay .Module {
    width: 100%; }
  .search-overlay.active {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 100%, rgba(0, 0, 0, 0.5));
    opacity: 1;
    pointer-events: visible;
    visibility: visible;
    transition-delay: .3s;
    transition: .3s all ease-in-out; }
    .search-overlay.active .searchbox::before {
      width: 100%;
      transition-delay: 0.3s;
      transition: 1s all ease-in-out;
      opacity: 1;
      background: #ffffff; }
    .search-overlay.active .searchbox input.searchinput::placeholder {
      font-weight: 900;
      color: white;
      transition-delay: 0.3s;
      transition: 1s all ease-in-out;
      transform: translate(0);
      opacity: 1; }
    .search-overlay.active .searchbox .btn-search {
      transform: translate(0);
      transition-delay: 0.3s;
      transition: 1s all ease-in-out; }
      .search-overlay.active .searchbox .btn-search em {
        color: white;
        transition-delay: 0.3s;
        transition: 1.5s all ease-in-out;
        opacity: 1; }
  .search-overlay .searchbox {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    position: relative;
    transition: .3s all ease-in-out; }
    @media screen and (max-width: 1280px) {
      .search-overlay .searchbox {
        width: 80%; } }
    .search-overlay .searchbox::before {
      pointer-events: none;
      width: 0%;
      height: 1px;
      position: absolute;
      content: '';
      top: 100%;
      left: 0;
      background: transparent;
      opacity: 0; }
    .search-overlay .searchbox input.searchinput {
      padding: 15px;
      width: 100%;
      height: 100%;
      background: transparent;
      border: 0;
      font-size: 1rem;
      color: #ffffff;
      font-weight: 600; }
      .search-overlay .searchbox input.searchinput::placeholder {
        font-weight: 900;
        color: rgba(255, 255, 255, 0.5);
        transform: translateX(30px);
        opacity: 0; }
    .search-overlay .searchbox .btn-search {
      border: 0;
      height: auto;
      background-color: transparent;
      transform: translateY(30px); }
      .search-overlay .searchbox .btn-search em {
        font-size: 1.875rem;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        opacity: 0; }
        @media screen and (max-width: 768px) {
          .search-overlay .searchbox .btn-search em {
            font-size: 1.4375rem; } }

.burger {
  display: none;
  margin-left: 15px; }
  @media screen and (min-width: 460px) {
    .burger {
      order: 2; } }
  @media screen and (max-width: 1280px) {
    .burger {
      display: block;
      float: left;
      z-index: 0; }
      .burger.active .line-1 {
        transform: rotate(-45deg) translate(-4px, 5px); }
      .burger.active .line-2 {
        opacity: 0; }
      .burger.active .line-3 {
        transform: rotate(45deg) translate(-5px, -6px); }
      .burger div {
        transition: 0.5s all ease;
        width: 22px;
        height: 3px;
        margin: 4px 5px;
        background-color: #283a97; } }
  @media screen and (max-width: 425px) {
    .burger {
      margin-left: 10px; }
      .burger div {
        width: 20px;
        height: 3px; } }

#overlay {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  transition: .3s all ease-in-out; }
  #overlay.active {
    opacity: 1;
    visibility: visible;
    pointer-events: visible; }
  @media screen and (min-width: 1280px) {
    #overlay {
      display: none; } }

.img-overlay {
  position: relative;
  background-color: #ccc;
  overflow: hidden; }
  .img-overlay:hover img {
    transform: scale(1.05); }
  .img-overlay::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%; }

#back-to-top {
  position: fixed;
  bottom: 140px;
  right: 5%;
  width: 40px;
  height: 40px;
  border: thin solid #959697;
  z-index: 30;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: .3s all ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(30px); }
  #back-to-top:hover {
    background: #283a97;
    border: thin solid transparent; }
    #back-to-top:hover em {
      color: #ffffff; }
  #back-to-top.active {
    opacity: 1;
    visibility: visible;
    transition: .3s all ease-in-out;
    transform: translate(0); }
  #back-to-top em {
    color: #959697;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0.8px; }

.talk {
  position: fixed;
  right: 5%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998; }
  .talk#mess {
    background-color: #ffffff;
    bottom: 90px; }
    .talk#mess img {
      width: 25px;
      height: 25px;
      margin: auto;
      object-fit: contain;
      pointer-events: none; }
  .talk#twiter {
    background-color: #0083ce;
    bottom: 40px; }
    .talk#twiter em {
      color: #ffffff;
      font-size: 25px;
      pointer-events: none; }

.hidden-img {
  display: none; }

#main-banner .swiper-slide {
  position: relative; }
  #main-banner .swiper-slide .img {
    position: relative; }
    #main-banner .swiper-slide .img img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      clip-path: polygon(0 0, 100% 0, 100% 0, 100% 97%, 52% 97%, 50% 100%, 48% 97%, 0 97%); }
      @media screen and (max-width: 1024px) {
        #main-banner .swiper-slide .img img {
          min-height: 50vh; } }
      @media screen and (max-width: 580px) {
        #main-banner .swiper-slide .img img {
          min-height: 60vh; } }
    #main-banner .swiper-slide .img::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      clip-path: polygon(0 0, 100% 0, 100% 0, 100% 97%, 52% 97%, 50% 100%, 48% 97%, 0 97%); }
  #main-banner .swiper-slide .icon-img {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%); }
    @media screen and (min-width: 1520px) {
      #main-banner .swiper-slide .icon-img {
        bottom: 12px; } }
  #main-banner .swiper-slide .box-content {
    position: absolute;
    bottom: 180px;
    width: 100%;
    height: 100%;
    left: 0; }
    @media screen and (max-width: 480px) {
      #main-banner .swiper-slide .box-content {
        bottom: 120px; } }
    #main-banner .swiper-slide .box-content .block-title {
      font-weight: 400;
      text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.9);
      line-height: normal;
      text-transform: none; }
      @media screen and (max-width: 459.9px) {
        #main-banner .swiper-slide .box-content .block-title {
          font-size: 1.75rem; } }
      @media screen and (max-width: 400px) {
        #main-banner .swiper-slide .box-content .block-title {
          font-size: 1.5rem; } }

#page-banner {
  position: relative;
  z-index: -1; }
  #page-banner .img {
    width: 100%;
    height: 100%; }
    #page-banner .img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: 380px; }
      @media screen and (max-width: 1024px) {
        #page-banner .img img {
          min-height: 25vh; } }

.news-detail-page #page-banner {
  display: none; }

.news-detail-page .Module-225 {
  display: block !important; }

.modulepager .pagination {
  display: flex;
  list-style-type: none;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 10px; }
  .modulepager .pagination li {
    margin: 0px 5px;
    color: #283a97;
    display: flex;
    justify-content: center;
    align-items: center; }
    .modulepager .pagination li:last-child {
      margin-right: 0; }
    .modulepager .pagination li a, .modulepager .pagination li span {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .2s ease-in-out all;
      overflow: hidden;
      font-size: 1.125rem;
      color: #999999;
      font-size: 1rem;
      font-weight: 400;
      border: thin solid #999999; }
    .modulepager .pagination li.active a, .modulepager .pagination li.active span, .modulepager .pagination li:hover a, .modulepager .pagination li:hover span {
      color: #ffffff;
      background: #263699;
      border: thin solid transparent; }
    @media (min-width: 1024px) {
      .modulepager .pagination li a, .modulepager .pagination li span {
        width: 40px;
        height: 40px; } }

.global-breadcrumb, .global-breadcrumb_2 {
  width: 100%;
  border-top: thin solid #cccccc;
  border-bottom: thin solid #cccccc; }
  .global-breadcrumb.global-breadcrumb_2, .global-breadcrumb_2.global-breadcrumb_2 {
    margin-top: 0px;
    background-color: #ffffff;
    border-bottom: thin solid #e0e0e0; }
    .global-breadcrumb.global-breadcrumb_2 ol li, .global-breadcrumb_2.global-breadcrumb_2 ol li {
      color: #666666; }
      .global-breadcrumb.global-breadcrumb_2 ol li + li::before, .global-breadcrumb_2.global-breadcrumb_2 ol li + li::before {
        color: #666666; }
  .global-breadcrumb .breadcrumb, .global-breadcrumb_2 .breadcrumb {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: transparent;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding: 0px; }
    .global-breadcrumb .breadcrumb li, .global-breadcrumb_2 .breadcrumb li {
      position: relative;
      display: flex;
      align-items: center;
      color: #666666;
      font-size: 0.875rem;
      font-weight: 400; }
      .global-breadcrumb .breadcrumb li + li, .global-breadcrumb_2 .breadcrumb li + li {
        margin-left: 20px; }
      .global-breadcrumb .breadcrumb li.active, .global-breadcrumb_2 .breadcrumb li.active {
        color: #283a97; }
      .global-breadcrumb .breadcrumb li:hover, .global-breadcrumb_2 .breadcrumb li:hover {
        color: #283a97; }
    .global-breadcrumb .breadcrumb li + li::before, .global-breadcrumb_2 .breadcrumb li + li::before {
      position: absolute;
      top: 50%;
      left: -20px;
      color: #666666;
      font-size: 1.5rem;
      transform: translateY(-50%);
      content: "\ea54";
      font-family: 'remixicon'; }

.page-contact.section {
  padding-bottom: 0; }

.page-contact [class*="col-"] {
  margin-bottom: 1.875rem; }

.page-contact .item {
  display: flex;
  flex-direction: column;
  border: thin solid #cccccc;
  padding: 10px 15px;
  height: 100%;
  align-items: center;
  justify-content: flex-start; }
  .page-contact .item .wrapper .wrap-content {
    margin-top: 0.9375rem; }
    .page-contact .item .wrapper .wrap-content .title {
      font-size: 1.5rem;
      color: #283a97;
      margin-bottom: 0.9375rem; }
    .page-contact .item .wrapper .wrap-content .content {
      display: flex;
      align-items: flex-start;
      margin-bottom: 5px; }
      .page-contact .item .wrapper .wrap-content .content em {
        padding-right: 10px;
        line-height: 1;
        color: #283a97;
        font-size: 18px; }
      .page-contact .item .wrapper .wrap-content .content a + a {
        margin-left: 0.25rem; }
  .page-contact .item.bg-blue {
    background-color: #283a97;
    border: thin solid transparent;
    justify-content: center; }
    .page-contact .item.bg-blue .title {
      color: #ffffff;
      margin-top: 1.875rem;
      font-weight: 500; }
  .page-contact .item .title {
    color: #283a97;
    font-size: 16px;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    text-align: center;
    text-transform: uppercase; }
  .page-contact .item .title-sub,
  .page-contact .item .content {
    color: #666666;
    font-size: 14px;
    letter-spacing: -.25px; }
  .page-contact .item .title-sub {
    font-weight: 600; }

.page-contact-1 {
  padding: 0.625rem 0 5rem; }
  .page-contact-1 .wrap-form .form-row {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 767.9px) {
      .page-contact-1 .wrap-form .form-row {
        display: block; } }
    .page-contact-1 .wrap-form .form-row .form-group {
      width: 49%; }
      @media screen and (max-width: 767.9px) {
        .page-contact-1 .wrap-form .form-row .form-group {
          width: 100%; } }
  .page-contact-1 .wrap-form .form-group input[type="text"] {
    border: thin solid #cccccc;
    height: 40px;
    border-radius: 0;
    letter-spacing: 0; }
  .page-contact-1 .wrap-form .form-group textarea {
    width: 100%;
    color: #999999;
    font-size: 14px;
    border: thin solid #cccccc;
    padding: 10px 20px;
    height: 150px;
    margin-bottom: 25px; }
  .page-contact-1 .wrap-form .frm-btnwrap .frm-btn input[type="submit"] {
    width: 180px; }
  .page-contact-1 .wrap-form .frm-btnwrap .frm-btn input[type="reset"] {
    display: none; }
  .page-contact-1 .wrap-map {
    margin-top: 2.5rem; }
    @media screen and (max-width: 1023.9px) {
      .page-contact-1 .wrap-map iframe {
        height: 350px; } }
  .page-contact-1 .wrap-form .form-row {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 767.9px) {
      .page-contact-1 .wrap-form .form-row {
        display: block; } }
    .page-contact-1 .wrap-form .form-row .form-group {
      width: 49%; }
      @media screen and (max-width: 767.9px) {
        .page-contact-1 .wrap-form .form-row .form-group {
          width: 100%; } }
  .page-contact-1 .wrap-form .form-group input[type="text"] {
    border-radius: 0;
    height: 50px;
    padding-left: 1.25rem;
    font-size: 14px;
    color: #999999;
    letter-spacing: 1.5px;
    width: 100%;
    margin-bottom: 1.875rem; }
  .page-contact-1 .wrap-form .form-group.form-row {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 400px) {
      .page-contact-1 .wrap-form .form-group.form-row {
        display: block; } }
    .page-contact-1 .wrap-form .form-group.form-row input[type="text"] {
      width: 48.5%; }
      @media screen and (max-width: 400px) {
        .page-contact-1 .wrap-form .form-group.form-row input[type="text"] {
          width: 100%; } }
  .page-contact-1 .wrap-form .frm-btnwrap .frm-btn {
    position: relative;
    width: fit-content; }
    .page-contact-1 .wrap-form .frm-btnwrap .frm-btn input[type="reset"] {
      display: none; }
    .page-contact-1 .wrap-form .frm-btnwrap .frm-btn input[type="submit"] {
      width: 150px;
      height: 50px;
      background-color: #ee202e;
      color: white;
      border: unset;
      cursor: pointer;
      transition: .3s all ease-in-out; }
    .page-contact-1 .wrap-form .frm-btnwrap .frm-btn::before {
      content: "";
      position: absolute;
      background: url("../img/btn-icon.png") no-repeat;
      background-position-y: 50%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      transition: .3s all ease-in-out; }
    .page-contact-1 .wrap-form .frm-btnwrap .frm-btn:hover input[type="submit"] {
      background-color: #283a97; }
    .page-contact-1 .wrap-form .frm-btnwrap .frm-btn:hover::before {
      background-position-x: 100%; }

.account-wrapper {
  display: flex;
  align-items: center; }
  .account-wrapper em {
    color: #999999;
    font-size: 1rem;
    font-weight: 400;
    display: block; }
  .account-wrapper .wrap {
    padding-left: 10px;
    display: flex;
    align-items: center; }
    .account-wrapper .wrap ul {
      padding: 0px; }
      .account-wrapper .wrap ul li {
        color: #666666;
        font-size: 0.875rem;
        font-weight: 400;
        font-style: italic;
        white-space: nowrap;
        list-style: none;
        transition: .3s all  ease-in-out; }
        .account-wrapper .wrap ul li a::after {
          display: none; }
        .account-wrapper .wrap ul li:nth-of-type(2) {
          display: none; }
        .account-wrapper .wrap ul li:hover {
          color: #283a97; }
          .account-wrapper .wrap ul li:hover a {
            color: #283a97 !important; }
    .account-wrapper .wrap .logout {
      margin-left: 5px;
      color: #666666;
      font-size: 0.875rem;
      font-weight: 400;
      font-style: italic;
      white-space: nowrap;
      list-style: none;
      transition: .3s all  ease-in-out; }
      .account-wrapper .wrap .logout:hover {
        color: #283a97; }
        .account-wrapper .wrap .logout:hover a {
          color: #283a97 !important; }

.user-sidemenu {
  padding: 60px 0px; }
  @media screen and (max-width: 768px) {
    .user-sidemenu {
      padding: 30px 0px; } }

.checkout-title .title-wrap {
  background-color: #f1f1f1;
  border-left: 10px solid #283a97;
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 1rem;
  font-weight: 700;
  color: #283a97;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding: 12px 15px;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  width: 100%; }
  .checkout-title .title-wrap .fas {
    margin-right: 10px; }
  @media screen and (max-width: 768px) {
    .checkout-title .title-wrap {
      font-weight: 600;
      border-left: 5px solid #283a97; } }

.admin-content .panel {
  margin: 60px 0;
  border-radius: 5px;
  background: #fff; }
  .admin-content .panel .panel-heading h1 {
    margin-bottom: 30px;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    color: #333333; }

.admin-content .form-horizontal {
  border: thin solid #ccc;
  border-radius: 15px;
  padding: 15px; }
  .admin-content .form-horizontal .form-group label {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 5px; }
  .admin-content .form-horizontal .form-group input {
    border: thin solid #ced4da !important;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding: 5px 15px; }
  .admin-content .form-horizontal .form-group input[type="submit"] {
    transition: all .2s ease-in-out;
    box-shadow: none;
    border: none;
    border-radius: 5px;
    background: #283a97;
    min-width: 120px;
    height: 40px;
    color: #fff;
    font-size: 16px; }
  .admin-content .form-horizontal .form-group span {
    font-size: 12px;
    color: red !important;
    display: flex;
    margin-top: 5px;
    font-style: italic; }
  .admin-content .form-horizontal .RadCaptcha {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap; }
    .admin-content .form-horizontal .RadCaptcha > * {
      width: 100%; }
    .admin-content .form-horizontal .RadCaptcha span {
      order: 3; }
    .admin-content .form-horizontal .RadCaptcha > div {
      order: 1;
      margin-bottom: 5px; }
      .admin-content .form-horizontal .RadCaptcha > div > div {
        display: flex;
        align-items: center;
        margin-bottom: 10px; }
        .admin-content .form-horizontal .RadCaptcha > div > div a {
          display: inline-block;
          padding-left: 15px;
          color: #1e87f0;
          font-size: 12px; }
    .admin-content .form-horizontal .RadCaptcha input {
      order: 2; }
  @media (min-width: 1200px) {
    .admin-content .form-horizontal {
      padding: 20px; } }

.customer-info {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 15px; }
  .customer-info .form-info, .customer-info .form-same, .customer-info .bill-export {
    flex: 100%;
    padding: 15px 0px; }
  .customer-info .form-group {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap; }
    .customer-info .form-group label {
      flex: 0 0 200px;
      font-size: 16px;
      color: black;
      font-weight: 400; }
    .customer-info .form-group input, .customer-info .form-group select {
      height: 40px;
      width: 100%; }
    .customer-info .form-group input, .customer-info .form-group select, .customer-info .form-group textarea {
      flex: calc( 100% - 230px);
      margin-left: 15px;
      border: thin solid #cccccc;
      border-radius: 5px;
      padding: 0px 15px; }
    .customer-info .form-group textarea {
      min-height: 200px;
      padding: 5px 15px; }
    .customer-info .form-group span {
      flex: 100%;
      color: #283a97;
      font-style: italic;
      padding: 5px 0px; }
    @media screen and (max-width: 768px) {
      .customer-info .form-group label {
        flex: 0 0 125px; }
      .customer-info .form-group textarea {
        min-height: 130px; } }
    @media screen and (max-width: 425px) {
      .customer-info .form-group {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px; }
        .customer-info .form-group span {
          font-size: 14px; }
        .customer-info .form-group label {
          flex: 100%; }
        .customer-info .form-group input, .customer-info .form-group select, .customer-info .form-group textarea {
          flex: 100%;
          width: 100%;
          margin-left: 0px;
          margin-top: 5px;
          flex: none; } }
  .customer-info .form-same {
    padding-top: 0px; }
    @media screen and (max-width: 425px) {
      .customer-info .form-same {
        display: flex;
        align-content: center; }
        .customer-info .form-same label {
          margin-left: 10px; } }
    .customer-info .form-same .form-wrapper {
      padding-top: 15px;
      display: none; }
  .customer-info .bill-export .form-wrapper {
    display: none;
    padding-top: 15px; }
    .customer-info .bill-export .form-wrapper i {
      padding: 5px 0px;
      display: block; }

.term-service-wrap .qcheckbox {
  padding: 10px 15px;
  display: flex;
  align-items: center; }
  .term-service-wrap .qcheckbox #PaymentAgree {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: thin solid #ccc; }
    .term-service-wrap .qcheckbox #PaymentAgree:checked {
      border: thin solid transparent; }
  .term-service-wrap .qcheckbox label {
    font-size: 0.875rem;
    color: black;
    font-weight: 400;
    margin-left: 15px; }

.setting-control .btn-gap {
  display: flex;
  justify-content: space-between; }

@media screen and (max-width: 991px) {
  .setting-control {
    margin: 0 auto; }
    .setting-control .btn-gap {
      display: flex;
      justify-content: space-between; } }

.setting-control .btn-gap {
  margin-top: 15px;
  display: flex;
  align-items: center; }
  .setting-control .btn-gap .btn-primary {
    transition: .3s all ease-in-out;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: fit-content;
    background: #f1f1f1;
    color: #283a97;
    border-radius: 5px;
    border-left: 10px solid #283a97; }
    @media screen and (max-width: 768px) {
      .setting-control .btn-gap .btn-primary {
        border-left: 5px solid #283a97; } }
    .setting-control .btn-gap .btn-primary + .btn-primary {
      margin-left: 15px; }
    .setting-control .btn-gap .btn-primary span {
      margin-left: 10px;
      font-size: 0.875rem;
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap; }
    .setting-control .btn-gap .btn-primary.red em {
      padding-right: 0; }
    @media screen and (max-width: 375px) {
      .setting-control .btn-gap .btn-primary.gray {
        margin-bottom: .9rem; } }
    .setting-control .btn-gap .btn-primary.gray em,
    .setting-control .btn-gap .btn-primary.gray span {
      color: #333333; }
    .setting-control .btn-gap .btn-primary:hover {
      background-color: #56856a;
      color: #ffffff;
      border-left: 10px solid #56856a; }

.middle-fullwidth {
  width: 100%;
  margin: 0 auto; }
  .middle-fullwidth .form-group.forget input {
    display: none; }
  .middle-fullwidth .form-group.forget label {
    border: 0;
    width: fit-content; }
  .middle-fullwidth .uk-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
    @media (min-width: 576px) {
      .middle-fullwidth .uk-container {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .middle-fullwidth .uk-container {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .middle-fullwidth .uk-container {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .middle-fullwidth .uk-container {
        max-width: 1260px !important; } }
    @media screen and (min-width: 1920px) {
      .middle-fullwidth .uk-container {
        max-width: 1260px !important; } }

.wrap-register {
  padding: 60px 0px; }
  @media screen and (max-width: 768px) {
    .wrap-register {
      padding: 30px 0px; } }
  .wrap-register .signup-panel {
    width: 100%;
    border: thin solid #ccc;
    border-radius: 10px;
    padding: 15px; }
    .wrap-register .signup-panel .signup-form {
      width: 100%; }
      .wrap-register .signup-panel .signup-form .createuserbutton {
        border-radius: 5px;
        background: #56856a;
        padding: 15px 60px;
        width: fit-content;
        color: #fff !important;
        font-size: 1rem;
        line-height: 100%;
        text-transform: uppercase; }

.form-horizontal .riStrengthBarL0 {
  display: none; }

.form-horizontal .form-group {
  position: relative;
  margin-bottom: 20px; }
  .form-horizontal .form-group .fa-exclamation-triangle {
    position: absolute;
    padding-top: 2px;
    font-size: 0.625rem;
    font-weight: 600;
    top: 100%;
    left: 0px;
    color: #ee202e; }
  .form-horizontal .form-group label {
    margin-bottom: 5px; }
  .form-horizontal .form-group input, .form-horizontal .form-group textarea {
    width: 100%;
    height: 40px;
    border: thin solid #ced4da !important;
    border-radius: 5px; }
  .form-horizontal .form-group .settingcontrol input.form-control {
    height: 40px; }
  .form-horizontal .form-group .settingrow select {
    height: 40px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    background-clip: padding-box;
    background-color: #fff;
    padding: .375rem .75rem;
    width: 100%;
    height: 40px;
    color: #495057;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5; }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v53/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2"); }

.material-icons {
  font-family: Material Icons;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr; }

@font-face {
  font-family: Linearicons-Free;
  src: url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.eot);
  src: url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.eot?#iefix) format("embedded-opentype"), url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.woff2) format("woff2"), url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.ttf) format("truetype"), url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.woff) format("woff"), url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.svg#Linearicons-Free) format("svg");
  font-weight: 400;
  font-style: normal; }

.lnr {
  font-family: Linearicons-Free;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.eot");
  src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.eot");
  src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.eot");
  src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900; }

.flex-wrap {
  display: flex;
  justify-content: center; }

.login-page .login-panel {
  margin: 60px 0;
  background: #fff;
  border-radius: 5px; }
  .login-page .login-panel .sitelogo {
    text-align: center;
    margin-bottom: 40px;
    width: 100%; }
  .login-page .login-panel img {
    height: 80px; }
  .login-page .login-panel .headtitle {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 30px; }
    .login-page .login-panel .headtitle:after {
      content: '';
      margin: 10px auto; }

@media (max-width: 1199px) {
  .login-page .login-panel {
    margin: 60px 0; } }

@media (max-width: 543px) {
  .login-page .sitelogo {
    margin-bottom: 30px; } }

.login-form {
  width: 100%;
  margin: 0 auto; }
  .login-form .fa-exclamation-triangle {
    @apply absolute top-100 left-0 text-12 text-primary pt-1 {} }
  .login-form .panel-heading {
    display: none; }
  .login-form .title {
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
    border-left: 3px solid #8c0000;
    padding-left: 10px;
    margin-bottom: 30px; }
  .login-form .module-title {
    font-size: 0.875rem;
    color: #666666;
    margin-bottom: 20px; }
  .login-form a {
    color: #8c0000; }
  .login-form .form-group {
    margin-bottom: 20px;
    position: relative;
    overflow: hidden; }
    .login-form .form-group label {
      color: #999999;
      text-transform: uppercase;
      font-size: 0rem;
      display: block;
      width: 50px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      border-right: 1px solid #e6e6e6;
      @apply text-14  text-main {} }
      .login-form .form-group label .fa {
        font-size: 1.125rem;
        line-height: 40px; }
  .login-form input {
    width: 100%;
    font-size: .875rem;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    padding: 10px 30px 10px 60px;
    -webkit-transition: all, 0.5s;
    transition: all, 0.5s; }
    .login-form input::placeholder {
      font-size: 15px; }
    .login-form input:active, .login-form input:hover, .login-form input:focus {
      border-color: #999999; }
    @media screen and (max-width: 400px) {
      .login-form input {
        width: 100%; } }
  .login-form a {
    display: inline-block;
    color: #000;
    padding: 10px 0;
    margin-right: 15px; }
    .login-form a:hover {
      color: #8c0000; }
  .login-form .forget {
    font-size: 0.875rem;
    color: #999999;
    margin-bottom: 30px;
    display: none; }
    .login-form .forget input[type='checkbox'] {
      @apply opacity-0 relative {} }
      .login-form .forget input[type='checkbox']:checked ~ label::before {
        content: '\F0132'; }
    .login-form .forget label {
      color: #333333;
      font-weight: 300;
      width: auto;
      height: auto;
      text-align: left;
      padding-left: 30px;
      line-height: 1.5;
      font-size: 0.875rem;
      text-transform: none;
      position: relative;
      -webkit-transition: all, 0.5s;
      transition: all, 0.5s; }
      .login-form .forget label:before {
        font-family: 'Material Design Icons';
        content: '\F0131';
        font-size: 20px;
        display: block;
        line-height: 14px;
        font-size: 16px;
        color: #333333;
        position: absolute;
        top: 2px;
        left: 1px;
        -webkit-transition: all, 0.5s;
        transition: all, 0.5s; }
      .login-form .forget label:hover {
        color: #333333; }
  .login-form .btn-login {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    position: relative;
    color: #fff;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center; }
    .login-form .btn-login:hover::after {
      transform: translateX(3px); }
    .login-form .btn-login:before {
      background: #c61017; }
    .login-form .btn-login:after {
      transition: .3s all ease-in-out;
      font: normal normal normal 24px/1 Material Design Icons;
      content: '\F0142';
      position: absolute;
      top: 12px;
      right: 15px; }
  .login-form input[type='reset'] {
    display: none; }
  .login-form input[type="submit"] {
    line-height: 100%;
    cursor: pointer;
    width: fit-content;
    color: #fff !important;
    background: #283a97;
    border-radius: 5px;
    transition: .3s all ease-in-out;
    padding: 5px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    text-transform: uppercase;
    font-size: 1rem; }
    .login-form input[type="submit"]:hover {
      background-color: #ee202e; }
  .login-form .go-signup {
    text-align: center;
    margin-top: 15px; }
    .login-form .go-signup a {
      display: block;
      clear: both;
      color: #8c0000; }
      .login-form .go-signup a:hover {
        color: #000; }

@media (max-width: 543px) {
  .login-form .btn-login {
    width: 100%; } }

.login-bg {
  width: 60%;
  position: relative;
  overflow: hidden;
  padding: 50px;
  border-radius: 5px; }
  .login-bg .login-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    .login-bg .login-img .login-overlay {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000;
      opacity: 0.5;
      z-index: 1; }
  .login-bg img {
    height: 100%;
    width: auto;
    max-width: unset;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
  .login-bg .login-des {
    position: relative;
    z-index: 1;
    color: #fff; }
    .login-bg .login-des .title {
      font-size: 2.875rem;
      font-weight: 300;
      margin-bottom: 20px; }
    .login-bg .login-des .module-title {
      font-size: 1.625rem;
      font-weight: 200;
      opacity: 0.9; }
    .login-bg .login-des .des {
      margin-top: 180px;
      font-size: 1.25rem; }
      .login-bg .login-des .des span {
        opacity: 0.8;
        font-weight: 200;
        display: inline-block;
        margin-right: 10px; }
      .login-bg .login-des .des a {
        display: inline-block;
        opacity: 1;
        font-weight: 300;
        font-size: 2rem;
        color: #fff; }
        .login-bg .login-des .des a:after {
          content: '\f105';
          font-family: 'fontawesome';
          margin-left: 5px; }
        .login-bg .login-des .des a:hover {
          color: #8c0000; }

@media (max-width: 991px) {
  .login-bg {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

@media (max-width: 767px) {
  .login-bg {
    padding: 30px; } }

@media (max-width: 991px) {
  .login-bg .login-img img {
    height: auto;
    width: 100%; } }

@media (max-width: 767px) {
  .login-bg .login-img img {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    -khtml-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1); } }

@media (max-width: 543px) {
  .login-bg .login-img img {
    width: auto;
    height: 100%; } }

@media (max-width: 767px) {
  .login-bg .login-des .title {
    font-size: 2.25rem;
    margin-bottom: 10px; } }

@media (max-width: 767px) {
  .login-bg .login-des .module-title {
    font-size: 1.25rem; } }

@media (max-width: 991px) {
  .login-bg .login-des .des {
    margin-top: 30px; } }

@media (max-width: 767px) {
  .login-bg .login-des .des {
    margin-top: 10px; } }

@media (max-width: 543px) {
  .login-bg .login-des .des a {
    font-size: 1.625rem; } }

.panel-primary span {
  color: #eb0c22;
  font-style: italic;
  display: block; }

.loginstandard {
  padding: 10px;
  border: thin solid #ccc;
  border-radius: 15px;
  padding-top: 2rem; }

.about-1.section {
  padding-top: 3.125rem; }

.about-1 .block-title {
  background: url("../img/gioi-thieu/bg-title.png") no-repeat;
  background-size: 100% 100%;
  font-weight: 500;
  padding: 1.875rem 0; }
  @media screen and (max-width: 767.9px) {
    .about-1 .block-title {
      background-size: cover;
      background-position: center;
      font-size: 1.8rem; } }
  @media screen and (max-width: 500px) {
    .about-1 .block-title {
      font-size: 1.5rem; } }
  @media screen and (max-width: 400px) {
    .about-1 .block-title {
      font-size: 1.25rem; } }

.about-1 .wrap-content {
  padding: 0 6.25rem;
  color: #333333; }
  @media screen and (max-width: 1023.9px) {
    .about-1 .wrap-content {
      padding: 0 1.25rem; } }

.about-2 {
  position: relative;
  background: url("../img/gioi-thieu/bg-1.png") no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1; }
  @media screen and (max-width: 1023.9px) {
    .about-2.section {
      padding-bottom: 1.875rem; } }
  .about-2::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    z-index: -1; }
  .about-2 .title, .about-2 .item * {
    z-index: 2; }
  .about-2 .title {
    font-size: 1.5rem;
    margin-bottom: 2.1875rem; }
  .about-2 .item {
    position: relative; }
    .about-2 .item::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 150px;
      background-color: rgba(255, 255, 255, 0.5);
      top: 0;
      right: -15px; }
      @media screen and (max-width: 1023.9px) {
        .about-2 .item::before {
          display: none; } }
    .about-2 .item .quatity {
      color: #ff3333;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 1.3; }
  .about-2 [class*="col-"] {
    margin-bottom: 1.875rem; }
    .about-2 [class*="col-"]:last-child .item::before {
      display: none; }

.about-3 {
  position: relative;
  background: url("../img/gioi-thieu/bg-2.png") no-repeat;
  background-size: 100% 100%;
  z-index: 1; }
  .about-3 .swiper-about-3 {
    position: relative; }
  .about-3::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    z-index: -1; }
  .about-3 .wrap-desc {
    display: flex;
    flex-direction: column;
    margin-top: 1.875rem;
    position: relative;
    padding-left: 0.9375rem;
    line-height: 1; }
    .about-3 .wrap-desc::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 2px;
      background-color: #ea2530;
      top: 0;
      left: 0; }
    .about-3 .wrap-desc .block-title {
      margin-bottom: 0.9375rem;
      transition: .3s all ease-in-out; }
      .about-3 .wrap-desc .block-title:hover {
        color: #ea2530; }
    .about-3 .wrap-desc .content {
      font-weight: 600; }

.about-4.section {
  padding-bottom: 1.875rem; }

.about-4 .wrap-content {
  padding-left: 1.875rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media screen and (max-width: 1023.9px) {
    .about-4 .wrap-content {
      padding-left: 0; } }
  .about-4 .wrap-content .title {
    font-size: 1.125rem;
    color: #283a97;
    margin-bottom: 1.25rem; }

.about-4 .img {
  width: 100%;
  height: 100%; }

.about-4 .row .row [class*="col-"] {
  margin-bottom: 1.875rem; }

.about-4 .row .row:last-child {
  flex-direction: row-reverse; }
  .about-4 .row .row:last-child .wrap-content {
    padding-left: 0; }
  @media screen and (max-width: 1023.9px) {
    .about-4 .row .row:last-child {
      flex-direction: column-reverse; } }

@media screen and (max-width: 1023.9px) {
  .about-4 .row .row:first-child {
    flex-direction: column-reverse; } }

.about-5.section {
  padding-top: 0; }

@media screen and (max-width: 1023.9px) {
  .about-5 [class*="col-"] {
    margin-bottom: 1.875rem; } }

.about-5 .desc {
  margin-bottom: 1.875rem;
  padding: 0 13.125rem; }
  @media screen and (max-width: 1023.9px) {
    .about-5 .desc {
      padding: 0 3.75rem; } }
  @media screen and (max-width: 767.9px) {
    .about-5 .desc {
      padding: 0; } }

.about-5 .item .wrap-content {
  margin-top: 0.9375rem; }
  .about-5 .item .wrap-content .title {
    font-size: 1.5rem;
    color: #ff3333;
    margin-bottom: 0.9375rem; }
  .about-5 .item .wrap-content .content {
    display: flex;
    margin-bottom: 5px; }
    .about-5 .item .wrap-content .content em {
      padding-right: 10px;
      line-height: 1;
      color: #ff3333;
      font-size: 18px; }

.index-1 {
  background: url("../img/bg1.png") no-repeat center;
  background-position-y: 67px;
  background-size: contain; }
  .index-1 .content {
    margin-bottom: 1.875rem;
    font-size: 1.125rem; }

.index-2 {
  background: url("../img/bg2.png") no-repeat;
  background-size: cover; }
  @media screen and (max-width: 1023.9px) {
    .index-2 [class*='col-'] {
      margin-bottom: 1.875rem; } }
  .index-2 .block-title {
    margin-bottom: 2.1875rem; }
  .index-2 .item .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 77.19298%; }
    .index-2 .item .img img, .index-2 .item .img iframe, .index-2 .item .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .index-2 .wrap-content {
    display: flex;
    align-items: center;
    position: relative;
    padding: 1.875rem;
    padding-top: 3.75rem;
    margin-top: -40px;
    height: 170px;
    font-size: 1.5rem;
    line-height: normal;
    background-color: #283a97;
    clip-path: polygon(0 24%, 51% 0, 100% 24%, 100% 100%, 0% 100%);
    transition: .2s all ease-in-out;
    justify-content: center;
    text-align: center; }
    .index-2 .wrap-content:hover {
      background-color: #ee202e; }
      .index-2 .wrap-content:hover .wrap-clippath {
        display: none; }
  .index-2 .wrap-clippath {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 178px;
    width: 100%;
    clip-path: polygon(50% 0, 100% 0, 100% 33%, 50% 10%, 0 33%, 0 0);
    background: #a0adb6; }

.index-3 {
  background-color: #e8ecf3; }
  .index-3.section {
    padding-bottom: 0; }
  .index-3 .big-box {
    display: none;
    flex-wrap: wrap; }
    .index-3 .big-box.active {
      display: flex; }
    .index-3 .big-box .item {
      border: thin solid #e8f3eb;
      flex: 0 0 25%;
      position: relative; }
      @media screen and (max-width: 1279.9px) {
        .index-3 .big-box .item {
          flex: 0 0 33.33333%; } }
      @media screen and (max-width: 1023.9px) {
        .index-3 .big-box .item {
          flex: 0 0 50%; } }
      @media screen and (max-width: 580px) {
        .index-3 .big-box .item {
          flex: 0 0 100%; } }
      .index-3 .big-box .item .img {
        position: relative;
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 81.25%; }
        .index-3 .big-box .item .img img, .index-3 .big-box .item .img iframe, .index-3 .big-box .item .img video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }
        .index-3 .big-box .item .img::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.25);
          z-index: 2;
          pointer-events: none; }
      .index-3 .big-box .item .text-inner {
        padding: 1.875rem;
        pointer-events: none; }
        .index-3 .big-box .item .text-inner .button, .index-3 .big-box .item .text-inner #modal .modal-box .modal-body .frm-btnwrap .frm-btn, #modal .modal-box .modal-body .frm-btnwrap .index-3 .big-box .item .text-inner .frm-btn {
          justify-content: flex-start;
          margin-top: auto; }
          .index-3 .big-box .item .text-inner .button a, .index-3 .big-box .item .text-inner #modal .modal-box .modal-body .frm-btnwrap .frm-btn a, #modal .modal-box .modal-body .frm-btnwrap .index-3 .big-box .item .text-inner .frm-btn a {
            pointer-events: visible; }
        .index-3 .big-box .item .text-inner .edit-link {
          pointer-events: visible; }
  .index-3 .toggle-catalog {
    display: none; }

.index-4 {
  background-color: #f5f5f5;
  background-size: contain; }
  .index-4 .wrap-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-right: 3.75rem; }
    @media screen and (max-width: 767.9px) {
      .index-4 .wrap-content {
        padding-right: 0; } }
    .index-4 .wrap-content .title {
      font-weight: 600;
      line-height: normal; }
    .index-4 .wrap-content .content {
      color: #333333; }
    .index-4 .wrap-content .button, .index-4 .wrap-content #modal .modal-box .modal-body .frm-btnwrap .frm-btn, #modal .modal-box .modal-body .frm-btnwrap .index-4 .wrap-content .frm-btn {
      margin: 1.875rem 0; }
      @media screen and (max-width: 1023.9px) {
        .index-4 .wrap-content .button, .index-4 .wrap-content #modal .modal-box .modal-body .frm-btnwrap .frm-btn, #modal .modal-box .modal-body .frm-btnwrap .index-4 .wrap-content .frm-btn {
          justify-content: center; } }
  .index-4 .wrap-bg {
    background: url("../img/index/3.png") no-repeat;
    padding: 1.875rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .index-4 .wrap-bg .item {
      flex: 0 0 30.30303%;
      margin: 5px;
      background-color: rgba(255, 255, 255, 0.8);
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px; }
      @media screen and (max-width: 767.9px) {
        .index-4 .wrap-bg .item {
          flex: 0 0 45%; } }
      .index-4 .wrap-bg .item img {
        object-fit: contain; }
    @media screen and (max-width: 767px) {
      .index-4 .wrap-bg.desktop {
        display: none; } }
    @media screen and (min-width: 768px) {
      .index-4 .wrap-bg.mobile {
        display: none; } }
  .index-4 .swiper-slide {
    height: fit-content; }

.index-5 {
  background: url(../img/bg3.png) no-repeat top center;
  background-size: cover; }
  .index-5 .swiper-news {
    position: relative; }
    @media screen and (min-width: 1024px) {
      .index-5 .swiper-news .swiper-slide:first-child {
        width: 600px !important;
        height: 360px !important; }
      .index-5 .swiper-news .swiper-slide {
        height: 360px; }
      .index-5 .swiper-news .swiper-slide:not(:first-child) .item .img {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 80.70175%; }
        .index-5 .swiper-news .swiper-slide:not(:first-child) .item .img img, .index-5 .swiper-news .swiper-slide:not(:first-child) .item .img iframe, .index-5 .swiper-news .swiper-slide:not(:first-child) .item .img video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }
      .index-5 .swiper-news .swiper-slide:not(:first-child) .item time {
        margin: 0.5rem 0; } }
  .index-5 .item {
    background: #e8f3eb;
    display: block;
    height: 100%; }
    .index-5 .item .wrap-content {
      height: 117.5px;
      padding: 0.625rem 1.875rem 1.875rem; }
    .index-5 .item .wrap-content .title {
      font-size: 1.125rem;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; }
    .index-5 .item .wrap-content .content {
      font-size: 0.875rem;
      color: #333333; }
    .index-5 .item:hover .title {
      color: #283a97; }
    @media screen and (max-width: 1023.9px) {
      .index-5 .item .img {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 80.70175%; }
        .index-5 .item .img img, .index-5 .item .img iframe, .index-5 .item .img video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }
      .index-5 .item .title {
        margin-bottom: 0; } }
    @media screen and (min-width: 1024px) {
      .index-5 .item.xl {
        display: flex; }
        .index-5 .item.xl .img img {
          height: 360px;
          object-fit: cover; }
        .index-5 .item.xl .wrap-content {
          padding: 1.875rem;
          max-width: 285px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          height: 100%; } }
    .index-5 .item.xl .button, .index-5 .item.xl #modal .modal-box .modal-body .frm-btnwrap .frm-btn, #modal .modal-box .modal-body .frm-btnwrap .index-5 .item.xl .frm-btn {
      margin-top: auto; }
      @media screen and (max-width: 1023.9px) {
        .index-5 .item.xl .button, .index-5 .item.xl #modal .modal-box .modal-body .frm-btnwrap .frm-btn, #modal .modal-box .modal-body .frm-btnwrap .index-5 .item.xl .frm-btn {
          display: none; } }
    .index-5 .item.xl .content {
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical; }
      @media screen and (max-width: 1023.9px) {
        .index-5 .item.xl .content {
          display: none; } }
    .index-5 .item.sm .wrap-content .title {
      margin-bottom: 0; }

.index-6 {
  background: url(../img/bg4.png) no-repeat;
  background-size: cover; }
  .index-6 .block-title {
    margin-bottom: 0; }
  .index-6 .desc {
    font-size: 1.125rem;
    font-weight: 300;
    margin-bottom: 0.9375rem; }
  .index-6 .wrap-form .form-row {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 767.9px) {
      .index-6 .wrap-form .form-row {
        display: block; } }
    .index-6 .wrap-form .form-row .form-group {
      width: 49%; }
      @media screen and (max-width: 767.9px) {
        .index-6 .wrap-form .form-row .form-group {
          width: 100%; } }
  .index-6 .wrap-form .form-group input[type="text"] {
    border: unset;
    border-radius: 5px;
    height: 50px;
    padding-left: 1.25rem;
    font-size: 14px;
    color: #999999;
    letter-spacing: 1.5px;
    width: 100%;
    margin-bottom: 0.9375rem; }
  .index-6 .wrap-form .form-group.form-row {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 400px) {
      .index-6 .wrap-form .form-group.form-row {
        display: block; } }
    .index-6 .wrap-form .form-group.form-row input[type="text"] {
      width: 48.5%; }
      @media screen and (max-width: 400px) {
        .index-6 .wrap-form .form-group.form-row input[type="text"] {
          width: 100%; } }
  .index-6 .wrap-form .frm-btnwrap .frm-btn {
    position: relative;
    width: fit-content; }
    .index-6 .wrap-form .frm-btnwrap .frm-btn input[type="reset"] {
      display: none; }
    .index-6 .wrap-form .frm-btnwrap .frm-btn input[type="submit"] {
      width: 150px;
      height: 50px;
      background-color: #ee202e;
      color: white;
      border: unset;
      cursor: pointer;
      transition: .3s all ease-in-out; }
    .index-6 .wrap-form .frm-btnwrap .frm-btn::before {
      content: "";
      position: absolute;
      background: url("../img/btn-icon.png") no-repeat;
      background-position-y: 50%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      height: 100%;
      transition: .3s all ease-in-out;
      pointer-events: none; }
    .index-6 .wrap-form .frm-btnwrap .frm-btn:hover input[type="submit"] {
      background-color: #283a97; }
    .index-6 .wrap-form .frm-btnwrap .frm-btn:hover::before {
      background-position-x: 100%; }

.capacity-1.section {
  padding-bottom: 3.75rem; }

.capacity-1 .block-bg {
  padding: 3.125rem 0;
  background: url("../img/nanglucsx/bg1.png") no-repeat;
  background-size: cover;
  position: relative; }
  .capacity-1 .block-bg::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .capacity-1 .block-bg .wrap-content {
    padding: 0 4.375rem;
    z-index: 2;
    text-align: center; }
    @media screen and (max-width: 767.9px) {
      .capacity-1 .block-bg .wrap-content {
        padding: 0 1.875rem; } }
    .capacity-1 .block-bg .wrap-content .block-title {
      line-height: normal;
      margin-bottom: 15px; }
      @media screen and (max-width: 399.9px) {
        .capacity-1 .block-bg .wrap-content .block-title {
          font-size: 1.25rem; } }
    .capacity-1 .block-bg .wrap-content .content {
      color: #ffffff; }
  .capacity-1 .block-bg .wrap-img {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1.875rem; }
    @media screen and (max-width: 767.9px) {
      .capacity-1 .block-bg .wrap-img {
        padding: 5px; } }
    .capacity-1 .block-bg .wrap-img .item {
      flex: 0 0 250px;
      height: 130px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 15px;
      margin: 2.5px;
      background-color: rgba(194, 203, 209, 0.75);
      transition: .3s all ease-in-out;
      cursor: pointer; }
      @media screen and (min-width: 1280px) {
        .capacity-1 .block-bg .wrap-img .item:last-child {
          margin-right: 0; } }
      @media screen and (max-width: 767.9px) {
        .capacity-1 .block-bg .wrap-img .item {
          flex: 0 0 48%; } }
      @media screen and (max-width: 399.9px) {
        .capacity-1 .block-bg .wrap-img .item {
          flex: 0 0 100%; } }
      .capacity-1 .block-bg .wrap-img .item.blue {
        background-color: #283a97; }
        .capacity-1 .block-bg .wrap-img .item.blue img {
          filter: brightness(0) invert(1); }
        .capacity-1 .block-bg .wrap-img .item.blue .content {
          color: #ffffff; }
      .capacity-1 .block-bg .wrap-img .item:hover {
        background-color: rgba(41, 59, 142, 0.75); }
        .capacity-1 .block-bg .wrap-img .item:hover .content {
          color: #ffffff; }
        .capacity-1 .block-bg .wrap-img .item:hover img {
          filter: brightness(0) invert(1); }
      .capacity-1 .block-bg .wrap-img .item .content {
        margin-top: 5px;
        text-align: center;
        color: #333333; }
      .capacity-1 .block-bg .wrap-img .item img {
        width: 45px;
        height: 45px;
        object-fit: contain; }

.capa-2 {
  background: url("../img/nanglucsx/bg2.jpg") no-repeat;
  background-size: cover; }
  .capa-2.section {
    padding: 3.125rem 0; }
  .capa-2 .block-title {
    margin-bottom: 0; }
  .capa-2 .desc {
    padding: 0 6.25rem;
    text-align: center;
    color: #ffffff; }
    @media screen and (max-width: 767.9px) {
      .capa-2 .desc {
        padding: 0; } }
  .capa-2 .primary-tab {
    margin-bottom: 0.9375rem; }
    @media screen and (max-width: 767.9px) {
      .capa-2 .primary-tab .tab-link {
        justify-content: center; } }
  .capa-2 .swiper-capa-2 {
    margin-top: 1.875rem;
    position: relative;
    display: none; }
    .capa-2 .swiper-capa-2.active {
      display: block; }
    .capa-2 .swiper-capa-2 .item .img {
      background-color: #ffffff; }
      .capa-2 .swiper-capa-2 .item .img a {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 89.74359%; }
        .capa-2 .swiper-capa-2 .item .img a img, .capa-2 .swiper-capa-2 .item .img a iframe, .capa-2 .swiper-capa-2 .item .img a video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }
      .capa-2 .swiper-capa-2 .item .img img {
        object-fit: contain; }
    .capa-2 .swiper-capa-2 .item .title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.9375rem;
      background-color: #dddddd;
      font-size: 16px;
      text-transform: none;
      transition: .3s all ease-in-out;
      overflow: hidden;
      height: 50px; }
      .capa-2 .swiper-capa-2 .item .title:hover {
        background-color: #ea2530;
        color: #ffffff; }

.capa-3 {
  display: none; }
  .capa-3 .swiper-capa-3 {
    position: relative; }
    .capa-3 .swiper-capa-3 .item {
      height: 65px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1); }
      .capa-3 .swiper-capa-3 .item .content {
        text-align: center; }
    .capa-3 .swiper-capa-3 .swiper-slide {
      padding: 5px; }
    .capa-3 .swiper-capa-3 .swiper-button-prev,
    .capa-3 .swiper-capa-3 .swiper-button-next {
      top: 60%; }

.capa-4.section {
  padding-bottom: 2.1875rem; }

.capa-4 ul.wrap-img {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1.875rem;
  padding: 0;
  list-style: none; }
  .capa-4 ul.wrap-img li {
    position: relative;
    flex: 0 0 160px;
    margin: 5px; }
    .capa-4 ul.wrap-img li .img {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 96.875%; }
      .capa-4 ul.wrap-img li .img img, .capa-4 ul.wrap-img li .img iframe, .capa-4 ul.wrap-img li .img video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
    .capa-4 ul.wrap-img li::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(40, 58, 151, 0.5);
      z-index: 2;
      transition: .3s all ease-in-out; }
    .capa-4 ul.wrap-img li:hover::before {
      background-color: rgba(234, 37, 48, 0.5); }
    .capa-4 ul.wrap-img li .text-inner {
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: 1.125rem;
      font-weight: bold;
      padding: 1.25rem; }

.capa-5.section {
  padding-top: 0;
  padding-bottom: 3.125rem; }

.capa-5 .img {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 66.66667%; }
  .capa-5 .img img, .capa-5 .img iframe, .capa-5 .img video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: .3s ease-in-out all; }
  @media screen and (max-width: 1023.9px) {
    .capa-5 .img {
      width: 100%; } }

.capa-5 .big-item {
  display: none; }
  .capa-5 .big-item.active {
    display: block; }

.capa-5 .wrap-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }
  @media screen and (max-width: 1023.9px) {
    .capa-5 .wrap-content {
      margin-bottom: 1.875rem; } }
  .capa-5 .wrap-content .title {
    color: #333399;
    font-size: 1.125rem; }
  .capa-5 .wrap-content .content {
    color: #333333; }

.capa-6.section {
  padding-top: 0;
  padding-bottom: 3.125rem; }

.capa-6 .wrap-box {
  padding: 3.75rem 6.25rem;
  background-color: #e8ecf3; }
  @media screen and (max-width: 767.9px) {
    .capa-6 .wrap-box {
      padding: 3.75rem 1.875rem; } }
  .capa-6 .wrap-box .block-title {
    font-weight: 500; }
  .capa-6 .wrap-box .swiper-capa-6 {
    position: relative; }
    .capa-6 .wrap-box .swiper-capa-6 .item {
      display: flex;
      flex-direction: row; }
      @media screen and (max-width: 1023.9px) {
        .capa-6 .wrap-box .swiper-capa-6 .item {
          flex-direction: column; } }
      .capa-6 .wrap-box .swiper-capa-6 .item .img {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 73.56322%;
        width: 100%;
        padding-top: 31%; }
        .capa-6 .wrap-box .swiper-capa-6 .item .img img, .capa-6 .wrap-box .swiper-capa-6 .item .img iframe, .capa-6 .wrap-box .swiper-capa-6 .item .img video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: contain;
          transition: .3s ease-in-out all; }
        @media screen and (max-width: 767.9px) {
          .capa-6 .wrap-box .swiper-capa-6 .item .img {
            padding-top: 40%; } }
      .capa-6 .wrap-box .swiper-capa-6 .item .wrap-content {
        max-width: 58.5%;
        padding-left: 3.75rem;
        padding-top: 1.875rem; }
        @media screen and (max-width: 1023.9px) {
          .capa-6 .wrap-box .swiper-capa-6 .item .wrap-content {
            max-width: 100%; } }
        @media screen and (max-width: 767.9px) {
          .capa-6 .wrap-box .swiper-capa-6 .item .wrap-content {
            padding-left: 0; } }
        .capa-6 .wrap-box .swiper-capa-6 .item .wrap-content .title {
          color: #383863;
          font-size: 1.125rem; }
        .capa-6 .wrap-box .swiper-capa-6 .item .wrap-content .content {
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
          color: #333333; }

.capa-7 {
  background-color: #f5f5f5;
  transition: .3s all ease-in-out; }
  .capa-7 [class*="col-"] {
    margin-bottom: 1.875rem; }
  .capa-7 .item {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff; }
    .capa-7 .item img {
      object-fit: contain; }
  .capa-7 .row {
    transition: .3s all ease-in-out; }
  .capa-7 [class*="col-"] {
    display: none; }
  .capa-7.active {
    transition: .3s all ease-in-out; }
    .capa-7.active .row {
      height: 100%;
      transition: .3s all ease-in-out; }

.btn.sub-btn, #modal .modal-box .modal-body .frm-btnwrap input.sub-btn[type="submit"] {
  position: relative; }
  .btn.sub-btn span, #modal .modal-box .modal-body .frm-btnwrap input.sub-btn[type="submit"] span {
    display: inline-block;
    position: absolute;
    left: 0;
    pointer-events: none;
    transition: .3s all ease-in-out; }
    .btn.sub-btn span img, #modal .modal-box .modal-body .frm-btnwrap input.sub-btn[type="submit"] span img {
      filter: brightness(0) invert(1); }
  .btn.sub-btn:hover span, #modal .modal-box .modal-body .frm-btnwrap input.sub-btn:hover[type="submit"] span {
    left: 10px; }

.page-product-detail.section {
  padding-bottom: 0; }

.page-product-detail .swiper-product-detail .gallery-top {
  margin-bottom: 1.875rem; }
  .page-product-detail .swiper-product-detail .gallery-top .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 76.76768%; }
    .page-product-detail .swiper-product-detail .gallery-top .img img, .page-product-detail .swiper-product-detail .gallery-top .img iframe, .page-product-detail .swiper-product-detail .gallery-top .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }

.page-product-detail .swiper-product-detail .block-thumb {
  position: relative; }
  .page-product-detail .swiper-product-detail .block-thumb .gallery-thumbs {
    cursor: pointer; }
    .page-product-detail .swiper-product-detail .block-thumb .gallery-thumbs .img {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 85.71429%; }
      .page-product-detail .swiper-product-detail .block-thumb .gallery-thumbs .img img, .page-product-detail .swiper-product-detail .block-thumb .gallery-thumbs .img iframe, .page-product-detail .swiper-product-detail .block-thumb .gallery-thumbs .img video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
    .page-product-detail .swiper-product-detail .block-thumb .gallery-thumbs .swiper-slide-thumb-active {
      border: thin solid #ee202e; }
  .page-product-detail .swiper-product-detail .block-thumb .arrow-button .swiper-button-prev,
  .page-product-detail .swiper-product-detail .block-thumb .arrow-button .swiper-button-next {
    background-color: white; }
    .page-product-detail .swiper-product-detail .block-thumb .arrow-button .swiper-button-prev:hover,
    .page-product-detail .swiper-product-detail .block-thumb .arrow-button .swiper-button-next:hover {
      background-color: #ee202e; }
  .page-product-detail .swiper-product-detail .block-thumb .arrow-button .swiper-button-prev {
    left: -15px; }
  .page-product-detail .swiper-product-detail .block-thumb .arrow-button .swiper-button-next {
    right: -15px; }

.page-product-detail .swiper-product-detail .block-top {
  position: relative; }
  .page-product-detail .swiper-product-detail .block-top .swiper-slide .img {
    cursor: zoom-in; }
  .page-product-detail .swiper-product-detail .block-top .zoom-img {
    position: absolute;
    top: 0;
    left: calc( 100% + 55px);
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: .3s all ease-in-out;
    box-shadow: 0px 0px 10px rgba(17, 17, 17, 0.5); }
    @media screen and (max-width: 1024px) {
      .page-product-detail .swiper-product-detail .block-top .zoom-img {
        display: none; } }
    .page-product-detail .swiper-product-detail .block-top .zoom-img.active {
      opacity: 1;
      pointer-events: visible;
      visibility: visible; }
    .page-product-detail .swiper-product-detail .block-top .zoom-img .img {
      background-image: url(../img/san-pham/1.png);
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 400%; }
  .page-product-detail .swiper-product-detail .block-top .zoom-cursor {
    position: absolute;
    z-index: 4;
    pointer-events: none;
    inset: 0;
    transition: .3s all ease-in-out;
    opacity: 0; }
    .page-product-detail .swiper-product-detail .block-top .zoom-cursor.active {
      opacity: 1; }
    .page-product-detail .swiper-product-detail .block-top .zoom-cursor .zoom-area {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0.2);
      box-shadow: 0px 0px 5px rgba(17, 17, 17, 0.2);
      width: 40%;
      height: 40%;
      position: absolute; }

.page-product-detail .block-title {
  font-size: 1.875rem; }

.page-product-detail .wrap-table {
  margin-left: 1.875rem; }
  @media screen and (max-width: 767.9px) {
    .page-product-detail .wrap-table {
      margin-left: 0; } }
  .page-product-detail .wrap-table table {
    width: 100%; }
    .page-product-detail .wrap-table table tr {
      height: 50px;
      border-top: thin solid #cccccc; }
      .page-product-detail .wrap-table table tr:last-child {
        border-bottom: thin solid #cccccc; }
      .page-product-detail .wrap-table table tr td:first-child {
        width: 20%;
        font-weight: 700; }
  .page-product-detail .wrap-table .button, .page-product-detail .wrap-table #modal .modal-box .modal-body .frm-btnwrap .frm-btn, #modal .modal-box .modal-body .frm-btnwrap .page-product-detail .wrap-table .frm-btn {
    margin-top: 2.5rem; }
  .page-product-detail .wrap-table .social-icons {
    margin-top: 3.75rem; }
    @media screen and (max-width: 767.9px) {
      .page-product-detail .wrap-table .social-icons {
        margin-top: 1.875rem; } }

.tab-panel .tab-description {
  overflow-x: auto; }
  .tab-panel .tab-description ul {
    list-style: none;
    padding: 0;
    display: flex; }
    @media screen and (max-width: 1024.98px) {
      .tab-panel .tab-description ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        white-space: nowrap; } }
    .tab-panel .tab-description ul li {
      padding-right: 10px; }
      .tab-panel .tab-description ul li a {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #cccccc;
        background: none;
        width: 180px;
        height: 45px;
        color: #666666;
        font-size: 18px;
        letter-spacing: -0.25px;
        cursor: pointer;
        font-weight: 500; }
      .tab-panel .tab-description ul li:hover a {
        background: #283a97;
        color: #ffffff; }
      .tab-panel .tab-description ul li.active a {
        font-weight: 400;
        color: #ffffff;
        background: #283a97; }
    @media screen and (max-width: 576px) {
      .tab-panel .tab-description ul {
        display: flex;
        align-items: center;
        justify-content: flex-start; }
        .tab-panel .tab-description ul li {
          text-align: center; } }

.tab-panel .content-description {
  border: thin solid #cccccc;
  padding: 1.875rem; }
  .tab-panel .content-description .tab-wrapper .tab-item {
    display: none; }
    .tab-panel .content-description .tab-wrapper .tab-item.active {
      display: block; }
  .tab-panel .content-description .fullcontent {
    overflow: hidden; }
    .tab-panel .content-description .fullcontent.active {
      max-height: max-content;
      transition: .5s all ease-in-out; }
    .tab-panel .content-description .fullcontent p {
      color: #333333;
      font-size: 1rem;
      padding-top: 5px; }
    .tab-panel .content-description .fullcontent span {
      color: #333333;
      font-size: 1rem;
      font-weight: 800;
      padding-top: 25px; }
    .tab-panel .content-description .fullcontent strong {
      color: #333333;
      font-size: 1rem;
      font-weight: 800;
      padding-top: 25px; }

.product-other.section {
  padding-top: 0; }

.product-other .title {
  font-size: 1.5rem;
  position: relative; }
  .product-other .title::before {
    content: "";
    position: absolute;
    bottom: -15px;
    background-color: #ea2530;
    height: 2px;
    width: 75px;
    left: 0; }

.product-other .swiper-product-other {
  margin-top: 3.125rem;
  position: relative; }
  .product-other .swiper-product-other .swiper-slide .item .title::before {
    display: none; }
  .product-other .swiper-product-other .swiper-slide .item .text-inner {
    padding: 1.875rem; }
    .product-other .swiper-product-other .swiper-slide .item .text-inner .content {
      letter-spacing: 1.5px; }
  .product-other .swiper-product-other .swiper-slide .item .img {
    position: relative;
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 80.76923%; }
    .product-other .swiper-product-other .swiper-slide .item .img img, .product-other .swiper-product-other .swiper-slide .item .img iframe, .product-other .swiper-product-other .swiper-slide .item .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
    .product-other .swiper-product-other .swiper-slide .item .img::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2; }

#modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: .3s all ease-in-out;
  padding-bottom: 50px;
  overflow: auto;
  display: flex;
  align-items: center; }
  #modal.active {
    opacity: 1;
    visibility: visible;
    transition: .3s all ease-in-out; }
  #modal .modal-box {
    max-width: 915px;
    margin: 50px auto 0;
    background-color: #ffffff;
    padding: 1.875rem;
    position: relative; }
    @media screen and (max-width: 400px) {
      #modal .modal-box {
        margin-top: 0;
        padding: 2.5rem 1.875rem; } }
    #modal .modal-box #modal-close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      color: #ffffff;
      background-color: #ee202e;
      cursor: pointer;
      width: 30px;
      height: 30px; }
    #modal .modal-box .modal-header .title {
      font-size: 1.5rem;
      text-align: center; }
    #modal .modal-box [id*="_pnlQuestions"] {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px; }
    #modal .modal-box .form-group {
      width: 100%;
      flex: 0 0 50%;
      padding: 0 5px;
      margin-bottom: 1.875rem; }
      #modal .modal-box .form-group.textarea {
        flex: 0 0 100%; }
      #modal .modal-box .form-group span {
        color: #ee202e;
        font-size: 0.75rem; }
        #modal .modal-box .form-group span::before {
          font-size: 0.875rem;
          font-weight: 300; }
    #modal .modal-box .modal-body input[type="text"] {
      border: thin solid #e5e5e5;
      border-radius: 0;
      height: 40px;
      font-weight: 300;
      width: 100%;
      padding: 0 15px; }
    #modal .modal-box .modal-body textarea {
      border: thin solid #e5e5e5;
      width: 100%;
      padding: 10px 0 0 20px; }
    #modal .modal-box .modal-body .frm-btnwrap {
      margin-bottom: 0;
      display: flex;
      justify-content: flex-start;
      padding: 0; }
      #modal .modal-box .modal-body .frm-btnwrap .label {
        display: none; }
      #modal .modal-box .modal-body .frm-btnwrap input[type="reset"] {
        display: none !important; }
      #modal .modal-box .modal-body .frm-btnwrap .frm-btn {
        position: relative; }
        #modal .modal-box .modal-body .frm-btnwrap .frm-btn::before {
          content: '';
          position: absolute;
          background-image: url(../img/btn-icon2.png);
          width: 28px;
          height: 7px;
          z-index: 3;
          pointer-events: none;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          filter: brightness(0) invert(1);
          transition: .3s all ease-in-out; }
        #modal .modal-box .modal-body .frm-btnwrap .frm-btn:hover::before {
          left: calc( 100% - 28px); }
      #modal .modal-box .modal-body .frm-btnwrap input[type="submit"] {
        width: 180px !important;
        border: 0; }

.page-product.section {
  padding-bottom: 5rem; }

.page-product .block-title {
  margin-bottom: 0; }

.page-product .primary-tab {
  margin-bottom: 20px; }

.page-product.index-3 .big-box {
  display: flex;
  flex-wrap: wrap; }

.page-product.index-3 .modulepager {
  margin-top: 1.875rem; }

.page-product .under-option {
  margin-bottom: 30px; }
  .page-product .under-option .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 575.98px) {
      .page-product .under-option .wrapper {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 20px; } }
  .page-product .under-option .child-link {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 10px;
    grid-gap: 10px; }
    @media (min-width: 576px) {
      .page-product .under-option .child-link {
        grid-gap: 10px 1.875rem; } }
    .page-product .under-option .child-link a {
      font-size: 1.125rem;
      color: #283a97;
      line-height: 1.2;
      transition: .3s all ease-in-out;
      position: relative; }
      .page-product .under-option .child-link a::before {
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        width: 0;
        transition: .3s all ease-in-out;
        height: 1px;
        background-color: #ea2530; }
      .page-product .under-option .child-link a.active, .page-product .under-option .child-link a:hover {
        color: #ea2530; }
        .page-product .under-option .child-link a.active::before, .page-product .under-option .child-link a:hover::before {
          width: 100%; }

@media (max-width: 575.98px) {
  .page-product #autoAppendHere-SortFilter {
    flex: 0 0 100%;
    width: 100%; } }

.page-product .sort-list {
  position: relative;
  width: 100%; }
  @media (min-width: 576px) {
    .page-product .sort-list {
      width: 140px; } }
  .page-product .sort-list .sort-title {
    font-size: 0.9375rem;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: thin solid #ea2530; }
    .page-product .sort-list .sort-title .text {
      padding-right: 5px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden; }
    .page-product .sort-list .sort-title em {
      font-size: 1.25rem;
      color: #999999; }
  .page-product .sort-list ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    list-style: none;
    padding: 0;
    z-index: 10; }
    .page-product .sort-list ul a {
      display: block;
      padding: 5px 10px;
      background-color: #f5f5f5;
      transition: .3s all ease-in-out; }
      .page-product .sort-list ul a:hover {
        background-color: #e1e1e1; }
      .page-product .sort-list ul a.no-subpage-error {
        color: #fff;
        background-color: #ea2530; }

.pd.pd-1 {
  position: relative; }
  .pd.pd-1 .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 81.25%; }
    .pd.pd-1 .img a img, .pd.pd-1 .img a iframe, .pd.pd-1 .img a video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .pd.pd-1 .pd-title {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;
    font-size: 1.125rem;
    padding: 0 15px;
    bottom: 10px; }
    @media (min-width: 1280px) {
      .pd.pd-1 .pd-title {
        bottom: 40px;
        padding: 0 40px;
        font-size: 1.5rem; } }

.product-list-new {
  width: 100%; }
  .product-list-new .pls-wrapper {
    grid-gap: 2px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
    @media (min-width: 768px) {
      .product-list-new .pls-wrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr)); } }
    @media (min-width: 1280px) {
      .product-list-new .pls-wrapper {
        grid-template-columns: repeat(4, minmax(0, 1fr)); } }
  .product-list-new .expand-item {
    display: none; }
  .product-list-new .button, .product-list-new #modal .modal-box .modal-body .frm-btnwrap .frm-btn, #modal .modal-box .modal-body .frm-btnwrap .product-list-new .frm-btn {
    margin-top: 30px; }

.article-wrapper .article-head {
  padding-bottom: 10px;
  border-bottom: thin solid  #d5d5d5;
  margin-bottom: 1.25rem; }
  .article-wrapper .article-head .block-title {
    text-transform: none;
    line-height: normal;
    margin-bottom: 10px;
    font-size: 1.5rem; }
  .article-wrapper .article-head .wrapper {
    margin-top: 0.9375rem;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.article-wrapper .article-body * {
  margin-bottom: 1.875rem; }

.article-wrapper .article-body h2,
.article-wrapper .article-body h3,
.article-wrapper .article-body h4,
.article-wrapper .article-body h5,
.article-wrapper .article-body h6 {
  position: relative;
  padding-left: 15px; }
  .article-wrapper .article-body h2::before,
  .article-wrapper .article-body h3::before,
  .article-wrapper .article-body h4::before,
  .article-wrapper .article-body h5::before,
  .article-wrapper .article-body h6::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: #ea2530; }

.article-wrapper .article-body img {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.social-icons {
  display: flex; }
  .social-icons .icon {
    display: flex;
    align-items: center;
    background: #1877f2;
    height: 20px;
    border-radius: 3px;
    min-width: 50px;
    padding: 3px 8px;
    margin-right: 5px;
    font-weight: bold;
    font-weight: 600;
    font-size: 14px; }
    .social-icons .icon:last-child {
      margin-right: 0; }
    .social-icons .icon * {
      color: #ffffff; }
  .social-icons em {
    color: #0073c3;
    font-size: 18px; }

.page-news-detail .news-other .wrap-header .block-title {
  font-size: 1.5rem;
  position: relative; }
  .page-news-detail .news-other .wrap-header .block-title::before {
    content: "";
    position: absolute;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background-color: #ee202e;
    left: 0; }

.page-news-detail .news-other .wrap-body {
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0; }
  .page-news-detail .news-other .wrap-body:nth-child(2) {
    margin-top: 15px; }
  .page-news-detail .news-other .wrap-body .item {
    display: flex;
    flex-direction: row;
    position: relative;
    height: auto;
    width: 100%; }
    @media screen and (max-width: 1279.9px) {
      .page-news-detail .news-other .wrap-body .item {
        height: auto; } }
    .page-news-detail .news-other .wrap-body .item .img {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 76.19048%;
      padding-top: 28.19048%;
      max-width: 105px;
      width: 100%; }
      .page-news-detail .news-other .wrap-body .item .img img, .page-news-detail .news-other .wrap-body .item .img iframe, .page-news-detail .news-other .wrap-body .item .img video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
      @media screen and (max-width: 1279.9px) {
        .page-news-detail .news-other .wrap-body .item .img {
          padding-top: 44.22222%; } }
      @media screen and (max-width: 1023.9px) {
        .page-news-detail .news-other .wrap-body .item .img {
          position: relative;
          display: block;
          height: 0;
          overflow: hidden;
          padding-top: 38.18182%;
          padding-top: 24.22222%;
          max-width: 220px; }
          .page-news-detail .news-other .wrap-body .item .img img, .page-news-detail .news-other .wrap-body .item .img iframe, .page-news-detail .news-other .wrap-body .item .img video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            transition: .3s ease-in-out all; } }
      @media screen and (max-width: 767.9px) {
        .page-news-detail .news-other .wrap-body .item .img {
          padding-top: 27.22222%; } }
      @media screen and (max-width: 479.9px) {
        .page-news-detail .news-other .wrap-body .item .img {
          padding-top: 42%; } }
      .page-news-detail .news-other .wrap-body .item .img img {
        max-width: 135px; }
        @media screen and (max-width: 1023.9px) {
          .page-news-detail .news-other .wrap-body .item .img img {
            max-width: 220px; } }
    .page-news-detail .news-other .wrap-body .item .box-content {
      padding: 10px 15px 15px; }
      .page-news-detail .news-other .wrap-body .item .box-content .title {
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 14px;
        margin-bottom: 0;
        text-transform: none;
        transition: .3s all ease-in-out; }
        .page-news-detail .news-other .wrap-body .item .box-content .title:hover {
          color: #333399; }

.page-news-list.section {
  padding-bottom: 5rem; }

.page-news-list .item {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 360px; }
  .page-news-list .item .button, .page-news-list .item #modal .modal-box .modal-body .frm-btnwrap .frm-btn, #modal .modal-box .modal-body .frm-btnwrap .page-news-list .item .frm-btn {
    text-transform: uppercase; }
  .page-news-list .item:hover .box-content .title {
    color: #283a97;
    transition: .3s all; }
  .page-news-list .item:hover .box-content .button.start, .page-news-list .item:hover .box-content #modal .modal-box .modal-body .frm-btnwrap .start.frm-btn, #modal .modal-box .modal-body .frm-btnwrap .page-news-list .item:hover .box-content .start.frm-btn {
    color: #283a97; }
    .page-news-list .item:hover .box-content .button.start .btn img, .page-news-list .item:hover .box-content #modal .modal-box .modal-body .frm-btnwrap .start.frm-btn .btn img, #modal .modal-box .modal-body .frm-btnwrap .page-news-list .item:hover .box-content .start.frm-btn .btn img, .page-news-list .item:hover .box-content .button.start #modal .modal-box .modal-body .frm-btnwrap input[type="submit"] img, #modal .modal-box .modal-body .frm-btnwrap .page-news-list .item:hover .box-content .button.start input[type="submit"] img, .page-news-list .item:hover .box-content #modal .modal-box .modal-body .frm-btnwrap .start.frm-btn input[type="submit"] img, #modal .modal-box .modal-body .frm-btnwrap .page-news-list .item:hover .box-content .start.frm-btn input[type="submit"] img {
      filter: grayscale(0); }
  @media screen and (max-width: 1279.9px) {
    .page-news-list .item {
      height: 300px; } }
  @media screen and (max-width: 1023.9px) {
    .page-news-list .item {
      height: 380px; } }
  @media screen and (max-width: 767.9px) {
    .page-news-list .item {
      height: auto; } }
  .page-news-list .item.xl {
    flex-direction: row;
    height: 360px; }
    @media screen and (max-width: 767.9px) {
      .page-news-list .item.xl {
        display: block;
        height: auto; } }
    .page-news-list .item.xl .img {
      flex: 0 0 315px;
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 114.28571%;
      padding-top: 59.8%; }
      .page-news-list .item.xl .img img, .page-news-list .item.xl .img iframe, .page-news-list .item.xl .img video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
      @media screen and (max-width: 1279.9px) {
        .page-news-list .item.xl .img {
          padding-top: 38.8%; } }
      @media screen and (max-width: 1023.9px) {
        .page-news-list .item.xl .img {
          padding-top: 52%; } }
      @media screen and (max-width: 767.9px) {
        .page-news-list .item.xl .img {
          padding-top: 57%; } }
    .page-news-list .item.xl .box-content {
      padding: 1.875rem; }
      .page-news-list .item.xl .box-content time {
        margin-bottom: 15px; }
  .page-news-list .item .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 80.70175%; }
    .page-news-list .item .img img, .page-news-list .item .img iframe, .page-news-list .item .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .page-news-list .item .box-content {
    background-color: #e8f3eb;
    padding: 1.25rem;
    overflow: hidden;
    height: 100%;
    width: 100%; }
    @media screen and (max-width: 1279.9px) {
      .page-news-list .item .box-content {
        height: 100%; } }
    .page-news-list .item .box-content time {
      margin-bottom: 5px; }
    .page-news-list .item .box-content .title {
      margin-bottom: 1.25rem;
      font-weight: 700;
      font-size: 1.125rem;
      color: #333333;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; }
    .page-news-list .item .box-content .content {
      font-size: 0.875rem;
      color: #333333;
      font-weight: 400;
      margin: 0.625rem 0 0.9375rem;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical; }
    .page-news-list .item .box-content .button .btn, .page-news-list .item .box-content #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn, #modal .modal-box .modal-body .frm-btnwrap .page-news-list .item .box-content .frm-btn .btn, .page-news-list .item .box-content .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .page-news-list .item .box-content .button input[type="submit"], .page-news-list .item .box-content #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .page-news-list .item .box-content .frm-btn input[type="submit"] {
      height: 40px; }
      .page-news-list .item .box-content .button .btn img, .page-news-list .item .box-content #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn img, #modal .modal-box .modal-body .frm-btnwrap .page-news-list .item .box-content .frm-btn .btn img, .page-news-list .item .box-content .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"] img, #modal .modal-box .modal-body .frm-btnwrap .page-news-list .item .box-content .button input[type="submit"] img, .page-news-list .item .box-content #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"] img, #modal .modal-box .modal-body .frm-btnwrap .page-news-list .item .box-content .frm-btn input[type="submit"] img {
        filter: grayscale(100%); }

.page-news-list [class*='col-'] {
  margin-bottom: 1.875rem; }

.gallery.section {
  padding-bottom: 5rem; }

.gallery .block-title {
  margin-bottom: 0; }

.gallery .item {
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto; }
  .gallery .item .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 76.84211%; }
    .gallery .item .img img, .gallery .item .img iframe, .gallery .item .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .gallery .item.xl .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 77.66667%; }
    .gallery .item.xl .img img, .gallery .item.xl .img iframe, .gallery .item.xl .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .gallery .item .text-inner {
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
    height: auto;
    padding-left: 30px;
    bottom: 30px;
    top: inherit;
    pointer-events: none; }
    .gallery .item .text-inner * {
      color: white; }
    .gallery .item .text-inner em {
      font-size: 24px;
      font-weight: 700;
      transition: .3s all  ease-in-out; }
    .gallery .item .text-inner .content {
      font-size: 16px;
      font-weight: 400;
      margin-left: 10px;
      transition: .3s all  ease-in-out; }
  .gallery .item:hover .box-content * {
    color: #e30016; }
  .gallery .item:hover .box-content .content {
    text-decoration: underline; }

.gallery [class*='col-'] {
  margin-bottom: 1.875rem; }

.gallery .wrap-content > .row {
  margin-bottom: -1.875rem; }

.box-info-contact {
  display: none; }

.infor-inner .box-info-contact {
  display: block; }

.page-recruit-detail [class*="col-"] {
  margin-bottom: 1.875rem; }

.page-recruit-detail .info em {
  line-height: 1.5;
  font-size: 16px; }

.page-recruit-detail .info time {
  margin-left: 5px; }

.page-recruit-detail .wrap-table {
  overflow: auto;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1); }

.page-recruit-detail table {
  width: 100%; }
  @media screen and (max-width: 1023.9px) {
    .page-recruit-detail table {
      width: 690px;
      overflow: auto;
      border-collapse: collapse;
      border-spacing: 0; } }
  .page-recruit-detail table em {
    color: #283a97;
    line-height: 1.5;
    font-size: 16px; }
  .page-recruit-detail table em + span {
    padding-left: 5px; }
  .page-recruit-detail table tr {
    height: 50px; }
    .page-recruit-detail table tr:nth-child(even) {
      background-color: #f5f5f5; }
    .page-recruit-detail table tr td span {
      color: #333333; }
    .page-recruit-detail table tr td:first-child {
      display: flex;
      height: 50px;
      align-items: center;
      padding-left: 1.875rem; }
      .page-recruit-detail table tr td:first-child span {
        font-weight: 700; }
    .page-recruit-detail table tr td:last-child {
      width: 80%; }
      .page-recruit-detail table tr td:last-child span {
        font-weight: 500; }

.page-recruit-detail .wrap-desc {
  padding: 0 1.875rem 1.875rem;
  margin-top: 1.875rem;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1); }
  .page-recruit-detail .wrap-desc .desc {
    padding-top: 1.875rem; }
    .page-recruit-detail .wrap-desc .desc .title {
      font-size: 16px;
      margin-bottom: 0.625rem; }
    .page-recruit-detail .wrap-desc .desc ul {
      list-style-position: inside;
      padding: 0; }
      .page-recruit-detail .wrap-desc .desc ul li::marker {
        color: rgba(51, 51, 51, 0.8); }

.box-info-contact, .info-option {
  background-color: #ea2530;
  border-bottom: thin solid #ffffff; }
  .box-info-contact .info-contact, .info-option .info-contact {
    padding: 1.25rem; }
    .box-info-contact .info-contact .block-title, .info-option .info-contact .block-title {
      font-size: 1.5rem;
      font-weight: 400;
      letter-spacing: normal;
      position: relative; }
      .box-info-contact .info-contact .block-title::before, .info-option .info-contact .block-title::before {
        content: "";
        position: absolute;
        width: 70px;
        height: 2px;
        background-color: #ffffff;
        bottom: -5px;
        left: 20px; }
        @media screen and (max-width: 1279.9px) {
          .box-info-contact .info-contact .block-title::before, .info-option .info-contact .block-title::before {
            left: 50%;
            transform: translateX(-50%); } }
    .box-info-contact .info-contact .desc, .info-option .info-contact .desc {
      display: flex;
      margin-bottom: 1.25rem; }
      .box-info-contact .info-contact .desc *, .info-option .info-contact .desc * {
        color: #ffffff; }
      .box-info-contact .info-contact .desc em, .info-option .info-contact .desc em {
        font-size: 16px;
        line-height: 1.5;
        margin-right: 10px; }
      .box-info-contact .info-contact .desc span, .box-info-contact .info-contact .desc a, .info-option .info-contact .desc span, .info-option .info-contact .desc a {
        word-break: break-all; }

.info-option {
  padding: 1.875rem 1.25rem; }
  .info-option .button, .info-option #modal .modal-box .modal-body .frm-btnwrap .frm-btn, #modal .modal-box .modal-body .frm-btnwrap .info-option .frm-btn {
    margin-bottom: 1.25rem; }
    .info-option .button:last-child, .info-option #modal .modal-box .modal-body .frm-btnwrap .frm-btn:last-child, #modal .modal-box .modal-body .frm-btnwrap .info-option .frm-btn:last-child {
      margin-bottom: 0; }
    .info-option .button .btn, .info-option #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn, #modal .modal-box .modal-body .frm-btnwrap .info-option .frm-btn .btn, .info-option .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .info-option .button input[type="submit"], .info-option #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .info-option .frm-btn input[type="submit"] {
      border: 1px solid #ffffff;
      width: 100%;
      height: 40px; }
      .info-option .button .btn:hover, .info-option #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn:hover, #modal .modal-box .modal-body .frm-btnwrap .info-option .frm-btn .btn:hover, .info-option .button #modal .modal-box .modal-body .frm-btnwrap input:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .info-option .button input:hover[type="submit"], .info-option #modal .modal-box .modal-body .frm-btnwrap .frm-btn input:hover[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .info-option .frm-btn input:hover[type="submit"] {
        background-color: #ffffff;
        color: #ea2530; }

.other-recruit {
  margin-top: 1.875rem; }
  .other-recruit .wrap-header {
    background-color: #283a97;
    padding: 10px 0;
    height: 50px;
    display: flex;
    align-items: center; }
    .other-recruit .wrap-header .block-title {
      padding-left: 1.875rem;
      font-size: 1.5rem;
      font-weight: 500;
      letter-spacing: normal;
      margin-bottom: 0; }
  .other-recruit .wrap-body {
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    padding: 0 1.875rem; }
    .other-recruit .wrap-body .item {
      padding: 0.9375rem 0;
      border-bottom: thin solid #cccccc;
      color: #333333; }
      .other-recruit .wrap-body .item:last-child {
        border: unset; }
      .other-recruit .wrap-body .item a {
        font-weight: 700; }
        .other-recruit .wrap-body .item a:hover {
          color: #ff3333;
          transition: .3s all ease-in-out; }

.recruit-list-1 {
  background: url("../img/tuyen-dung-ds/bg1.png") no-repeat;
  background-position: center;
  background-size: contain; }
  .recruit-list-1 .block-title {
    font-size: 1.875rem;
    font-weight: 500;
    margin-bottom: 0; }
  .recruit-list-1 .title {
    color: #ff3333;
    font-size: 1.5rem;
    margin-bottom: 0; }

.recruit-list-2 {
  background: #f5f5f5; }
  .recruit-list-2 .wrap-content {
    padding-left: 1.25rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .recruit-list-2 .wrap-content .title {
      font-size: 2.25rem;
      margin-bottom: 15px; }
    @media screen and (max-width: 1023.9px) {
      .recruit-list-2 .wrap-content {
        padding-left: 0;
        padding-top: 1.25rem; } }

@media screen and (max-width: 1023.9px) {
  .recruit-list-3 [class*="col-"] {
    margin-bottom: 1.875rem; } }

.recruit-list-3 .item {
  box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.1);
  height: 320px;
  padding: 1.875rem; }
  @media screen and (max-width: 1023.9px) {
    .recruit-list-3 .item {
      height: auto; } }
  .recruit-list-3 .item .img {
    text-align: center;
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto; }
  .recruit-list-3 .item .block-title {
    color: #333399;
    font-size: 1.25rem;
    margin-bottom: 10px;
    letter-spacing: normal; }
  .recruit-list-3 .item .content {
    line-height: 1.3; }

.recruit-list-4 .grid-container {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 270px 270px; }
  @media screen and (max-width: 1023.9px) {
    .recruit-list-4 .grid-container {
      grid-template-columns: 1fr;
      grid-template-rows: 2fr; } }
  .recruit-list-4 .grid-container .grid-item {
    position: relative; }
    .recruit-list-4 .grid-container .grid-item:nth-child(1) {
      grid-area: 1 / 1 / 2 /2; }
      @media screen and (max-width: 1023.9px) {
        .recruit-list-4 .grid-container .grid-item:nth-child(1) {
          grid-area: 3 / 1 / 4 /2; } }
    .recruit-list-4 .grid-container .grid-item:nth-child(2) {
      grid-area: 2 / 1 / 3 /2; }
      @media screen and (max-width: 1023.9px) {
        .recruit-list-4 .grid-container .grid-item:nth-child(2) {
          grid-area: 2 / 1 / 3 /2; } }
    .recruit-list-4 .grid-container .grid-item:nth-child(3) {
      grid-area: 1 / 2 / 3 /3; }
      .recruit-list-4 .grid-container .grid-item:nth-child(3) .text-inner {
        justify-content: center;
        align-items: center;
        padding: 4.375rem; }
        @media screen and (max-width: 767.9px) {
          .recruit-list-4 .grid-container .grid-item:nth-child(3) .text-inner {
            padding: 1.25rem; } }
        .recruit-list-4 .grid-container .grid-item:nth-child(3) .text-inner .title {
          text-align: center;
          font-size: 2.25rem; }
      @media screen and (max-width: 1023.9px) {
        .recruit-list-4 .grid-container .grid-item:nth-child(3) {
          grid-area: 1 / 1 / 2 /2; } }
    .recruit-list-4 .grid-container .grid-item:nth-child(4) {
      grid-area: 1 / 3 / 2 /4; }
      @media screen and (max-width: 1023.9px) {
        .recruit-list-4 .grid-container .grid-item:nth-child(4) {
          grid-area: 4 / 1 / 5 /2; } }
    .recruit-list-4 .grid-container .grid-item:nth-child(5) {
      grid-area: 2 / 3 / 3 /4; }
      @media screen and (max-width: 1023.9px) {
        .recruit-list-4 .grid-container .grid-item:nth-child(5) {
          grid-area: 5 / 1 / 6 /2; } }
  .recruit-list-4 .grid-container .img {
    position: relative;
    height: 100%; }
    .recruit-list-4 .grid-container .img img {
      object-fit: cover;
      height: 100%;
      position: absolute; }
      @media screen and (max-width: 1023.9px) {
        .recruit-list-4 .grid-container .img img {
          position: static;
          height: auto;
          width: 100%; } }
    .recruit-list-4 .grid-container .img::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2; }
  .recruit-list-4 .grid-container .text-inner {
    z-index: 3;
    padding: 1.25rem;
    top: inherit;
    bottom: 0;
    justify-content: flex-end; }
    .recruit-list-4 .grid-container .text-inner .title {
      margin-bottom: 15px;
      color: #ffffff;
      font-size: 1.5rem; }

.recruit-list-5.section {
  padding-bottom: 1.875rem; }

.recruit-list-5 .wrap-content {
  display: block;
  padding: 0.9375rem 1.25rem;
  height: 100px;
  transition: .3s all ease-in-out;
  border-bottom: thin solid #e5e5e5; }
  .recruit-list-5 .wrap-content .see {
    color: #666666; }
  .recruit-list-5 .wrap-content .title {
    color: #111;
    font-weight: 700;
    font-size: 1.125rem;
    margin-bottom: 3px; }
  @media screen and (max-width: 767.9px) {
    .recruit-list-5 .wrap-content {
      height: auto; } }
  .recruit-list-5 .wrap-content:hover {
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.1); }
    .recruit-list-5 .wrap-content:hover .see {
      transition: .3s all ease-in-out;
      color: #333399 !important;
      text-decoration: underline; }
    .recruit-list-5 .wrap-content:hover .title {
      color: #333399 !important; }
  .recruit-list-5 .wrap-content .see, .recruit-list-5 .wrap-content em {
    font-size: 14px; }
  .recruit-list-5 .wrap-content em {
    color: #666666;
    padding-right: 10px;
    line-height: 1.8; }
  .recruit-list-5 .wrap-content .address {
    display: flex; }
  .recruit-list-5 .wrap-content .box {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.recruit-list-5 + .pages.newspager .modulepager {
  margin: 0 0 2.5rem; }

.recruit-list-6 {
  background: url("../img/tuyen-dung-ds/bg2.png") no-repeat center;
  background-size: cover;
  position: relative;
  z-index: 1; }
  .recruit-list-6::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1; }
  .recruit-list-6 .desc {
    color: #ffffff; }
  .recruit-list-6 .button, .recruit-list-6 #modal .modal-box .modal-body .frm-btnwrap .frm-btn, #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .frm-btn {
    margin-top: 1.875rem; }
    .recruit-list-6 .button .btn, .recruit-list-6 #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn, #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .frm-btn .btn, .recruit-list-6 .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .button input[type="submit"], .recruit-list-6 #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"], #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .frm-btn input[type="submit"] {
      position: relative; }
      .recruit-list-6 .button .btn span, .recruit-list-6 #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn span, #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .frm-btn .btn span, .recruit-list-6 .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"] span, #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .button input[type="submit"] span, .recruit-list-6 #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"] span, #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .frm-btn input[type="submit"] span {
        display: inline-block;
        position: absolute;
        left: 0;
        pointer-events: none;
        transition: .3s all ease-in-out; }
        .recruit-list-6 .button .btn span img, .recruit-list-6 #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn span img, #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .frm-btn .btn span img, .recruit-list-6 .button #modal .modal-box .modal-body .frm-btnwrap input[type="submit"] span img, #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .button input[type="submit"] span img, .recruit-list-6 #modal .modal-box .modal-body .frm-btnwrap .frm-btn input[type="submit"] span img, #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .frm-btn input[type="submit"] span img {
          filter: brightness(0) invert(1); }
      .recruit-list-6 .button .btn:hover span, .recruit-list-6 #modal .modal-box .modal-body .frm-btnwrap .frm-btn .btn:hover span, #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .frm-btn .btn:hover span, .recruit-list-6 .button #modal .modal-box .modal-body .frm-btnwrap input:hover[type="submit"] span, #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .button input:hover[type="submit"] span, .recruit-list-6 #modal .modal-box .modal-body .frm-btnwrap .frm-btn input:hover[type="submit"] span, #modal .modal-box .modal-body .frm-btnwrap .recruit-list-6 .frm-btn input:hover[type="submit"] span {
        left: calc( 100% - 28px); }

footer .footer-top {
  padding: 3.4375rem 0 4.375rem;
  background-color: #000b44; }
  footer .footer-top .footer-title {
    position: relative; }
    footer .footer-top .footer-title::before {
      content: "";
      position: absolute;
      top: 0;
      width: 30px;
      height: 3px;
      background-color: #ee202e; }
    footer .footer-top .footer-title .title {
      padding-top: 20px;
      color: #ffffff;
      font-weight: 700;
      margin-bottom: 1.25rem;
      line-height: 1.3;
      font-size: 1rem; }
  footer .footer-top [class*='col-'] {
    border-right: 1px solid #18214c; }
    @media screen and (max-width: 1023.9px) {
      footer .footer-top [class*='col-'] {
        border-right: 0; } }
    footer .footer-top [class*='col-']:last-child {
      border-right: 0; }
  footer .footer-top ul {
    list-style: none;
    padding: 0; }
    footer .footer-top ul li {
      line-height: 1.3;
      margin-bottom: 0.9375rem; }
      footer .footer-top ul li * {
        color: #8c97a9;
        font-size: 14px;
        letter-spacing: 1.025px; }
      footer .footer-top ul li .title-sub {
        font-weight: 600; }
      footer .footer-top ul li a:hover {
        text-decoration: underline; }

footer .wrap-social {
  display: none; }
  @media screen and (max-width: 1023.9px) {
    footer .wrap-social {
      margin-bottom: 15px; } }
  footer .wrap-social .social-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    transition: .3s all ease-in-out;
    background-color: #071353;
    margin-right: 5px; }
    footer .wrap-social .social-item em {
      color: #8c97a9;
      font-size: 18px;
      transition: .2s all ease-in-out; }
    footer .wrap-social .social-item:hover em {
      transform: scale(1.1); }

footer .footer-bottom {
  background-color: #071353;
  padding: 20px 0;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 409.9px) {
    footer .footer-bottom {
      height: auto;
      padding: 0.9375rem 0; } }
  footer .footer-bottom .container {
    overflow: hidden; }
  footer .footer-bottom .wrap-left * {
    color: #647084;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25); }
  @media screen and (max-width: 767.9px) {
    footer .footer-bottom .wrap-left {
      text-align: center;
      margin-bottom: 10px; }
      footer .footer-bottom .wrap-left span {
        display: block; } }
  footer .footer-bottom .wrap-left .ModuleContent {
    display: flex;
    overflow: auto;
    white-space: nowrap; }
    footer .footer-bottom .wrap-left .ModuleContent::-webkit-scrollbar {
      height: 1px; }
  footer .footer-bottom .wrap-box {
    display: flex; }
    @media screen and (max-width: 1279.9px) {
      footer .footer-bottom .wrap-box {
        display: block; } }
    footer .footer-bottom .wrap-box * {
      text-transform: uppercase;
      font-size: 12px; }
    footer .footer-bottom .wrap-box .wrap-right {
      display: flex;
      margin-left: 5px;
      overflow: auto; }
      footer .footer-bottom .wrap-box .wrap-right::-webkit-scrollbar {
        height: 1px; }
      footer .footer-bottom .wrap-box .wrap-right .ModuleContent {
        display: flex;
        overflow: auto;
        white-space: nowrap; }
        footer .footer-bottom .wrap-box .wrap-right .ModuleContent::-webkit-scrollbar {
          height: 1px; }
      footer .footer-bottom .wrap-box .wrap-right a, footer .footer-bottom .wrap-box .wrap-right span {
        color: #8c97a9;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        text-decoration: underline; }
      @media screen and (max-width: 1279.9px) {
        footer .footer-bottom .wrap-box .wrap-right {
          justify-content: center;
          margin-left: 0; } }
      @media screen and (max-width: 409.9px) {
        footer .footer-bottom .wrap-box .wrap-right {
          flex-wrap: wrap; }
          footer .footer-bottom .wrap-box .wrap-right .ModuleContent {
            text-align: center; } }
      footer .footer-bottom .wrap-box .wrap-right .item {
        display: inline-block;
        position: relative;
        margin: 0 5px; }
        footer .footer-bottom .wrap-box .wrap-right .item::before {
          content: "";
          height: 8px;
          width: 1px;
          background-color: #555f7d;
          top: 50%;
          transform: translateY(-50%);
          right: -5px;
          position: absolute; }
        footer .footer-bottom .wrap-box .wrap-right .item:last-child::before {
          display: none; }
        footer .footer-bottom .wrap-box .wrap-right .item:hover {
          text-decoration: underline; }
        @media screen and (max-width: 409.9px) {
          footer .footer-bottom .wrap-box .wrap-right .item:not(:first-child)::before {
            display: none; } }

header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  transition: .3s all ease-in-out;
  padding: 7px 0px; }
  header.header-scroll {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25); }
  header.disable_bsd {
    box-shadow: none !important; }
  @media screen and (max-width: 1280px) {
    header {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25); } }
  header ul {
    padding: 0; }
    header ul li {
      list-style: none;
      cursor: pointer; }
  header nav ul {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 1280px) {
      header nav ul {
        display: block; } }
    header nav ul li {
      color: #283a97;
      font-size: 1rem;
      font-weight: 700;
      transition: .3s all ease-in-out; }
      header nav ul li.active, header nav ul li:hover {
        color: #ee202e; }
      header nav ul li + li {
        margin-left: 30px; }
        @media screen and (max-width: 1280px) {
          header nav ul li + li {
            margin-left: 0px;
            padding: 5px 0px; } }
  header .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 1280px) {
      header .container .left-side {
        display: none; } }
    header .container .middle {
      flex: 0 0 103px; }
      @media screen and (min-width: 1024px) {
        header .container .middle {
          margin-left: 54px; } }
      @media screen and (max-width: 1280px) {
        header .container .middle {
          flex: 0 0 72px; } }
      header .container .middle .logo {
        width: 100%;
        height: 100%; }
        header .container .middle .logo img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    header .container .right-side {
      display: flex;
      justify-content: center;
      align-items: center; }
      @media screen and (max-width: 459.9px) {
        header .container .right-side {
          justify-content: flex-start; } }
      header .container .right-side .search-icon {
        margin-left: 30px;
        cursor: pointer; }
        @media screen and (max-width: 459.9px) {
          header .container .right-side .search-icon {
            margin-left: 0; } }
        header .container .right-side .search-icon .icon {
          display: flex;
          align-items: center; }
        header .container .right-side .search-icon em {
          color: #999999;
          font-size: 1.125rem;
          font-weight: 400;
          transition: all .3s; }
        header .container .right-side .search-icon:hover em {
          color: #ee202e; }
  header .language {
    margin-left: 20px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }
    header .language::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 0px;
      top: 100%; }
    header .language:hover::before {
      height: 30px; }
    header .language:hover .list-change {
      visibility: visible;
      pointer-events: auto;
      opacity: 1;
      transform: translateY(5px); }
    header .language:hover em {
      transform: rotate(180deg); }
    header .language span {
      color: #999999;
      font-size: 0.875rem;
      font-weight: 400; }
    header .language em {
      color: #999999;
      font-size: 1.125rem;
      font-weight: 400;
      transition: .2s all ease-in-out; }
    @media screen and (max-width: 1200px) {
      header .language span {
        font-size: 1rem; } }
    header .language .list-change {
      position: absolute;
      top: 100%;
      left: 0;
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
      transition: .3s all ease-in-out;
      transform: translateY(20px); }
      header .language .list-change:hover ul a {
        color: white;
        border: thin solid #ee202e;
        background-color: #ee202e; }
  header .nav-mobile {
    display: none; }
    @media screen and (max-width: 1280px) {
      header .nav-mobile {
        display: block;
        width: 50%;
        height: 100%;
        background-color: #fff;
        position: fixed;
        top: 60px;
        right: 0;
        z-index: 1000;
        padding: 15px;
        opacity: 0;
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        pointer-events: none;
        transform: translateX(20%);
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        max-width: 320px;
        width: 100%;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15); } }
    header .nav-mobile.active {
      opacity: 1;
      pointer-events: visible;
      visibility: visible;
      transform: translate(0); }
      header .nav-mobile.active .close-button em {
        transform: translate(0);
        transition-delay: 1.3s; }
      header .nav-mobile.active .icon, header .nav-mobile.active ul li, header .nav-mobile.active .login_register li, header .nav-mobile.active .left-side .item {
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: moveInRight;
        animation-name: moveInRight; }
      header .nav-mobile.active nav ul li {
        font-size: 1.3rem;
        border-bottom: thin solid #ccc;
        padding: 5px 0; }
        header .nav-mobile.active nav ul li:nth-child(1) {
          animation-delay: .5s; }
        header .nav-mobile.active nav ul li:nth-child(2) {
          animation-delay: .6s; }
        header .nav-mobile.active nav ul li:nth-child(3) {
          animation-delay: .7s; }
        header .nav-mobile.active nav ul li:nth-child(4) {
          animation-delay: .8s; }
        header .nav-mobile.active nav ul li:nth-child(5) {
          animation-delay: .9s; }
        header .nav-mobile.active nav ul li:nth-child(6) {
          animation-delay: 1s; }
        header .nav-mobile.active nav ul li:nth-child(7) {
          animation-delay: 1.1s; }
        header .nav-mobile.active nav ul li:nth-child(8) {
          animation-delay: 1.2s; }
    header .nav-mobile .close-button {
      padding: 0px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      border-bottom: thin solid #cccccc;
      cursor: pointer; }
      header .nav-mobile .close-button em {
        font-size: 1.875rem;
        font-weight: 300;
        color: #666666;
        margin-left: auto;
        transform: translateX(200%);
        transition: 0.8s all cubic-bezier(0.68, -0.55, 0.265, 1.55); }
      @media screen and (min-width: 1280.1px) {
        header .nav-mobile .close-button {
          display: none; } }

.hotline {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 15px;
  padding-left: 15px; }
  @media screen and (max-width: 459.9px) {
    .hotline {
      margin-left: 0;
      padding-left: 0;
      margin-top: 15px; } }
  .hotline::before {
    position: absolute;
    content: '';
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 30px;
    background-color: rgba(153, 153, 153, 0.25); }
    @media screen and (max-width: 459.9px) {
      .hotline::before {
        display: none; } }
  .hotline .number {
    margin-left: 5px;
    color: #9a9999;
    font-size: 0.75rem;
    font-weight: 400;
    display: flex;
    align-items: center; }
    .hotline .number a {
      margin-left: 5px;
      color: #ee202e;
      font-size: 1rem;
      font-weight: 700; }
