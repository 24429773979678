.button
	display: flex
	align-items: center
	justify-content: center
	text-transform: uppercase
	&.start
		justify-content: flex-start
		margin-top: r(30px)
	.btn
		cursor: pointer
		width: 180px
		height: 50px
		display: flex
		align-items: center
		justify-content: center
		font-size: 14px
		font-weight: 400
		text-transform: uppercase
		color: $white
		letter-spacing: 1.4px
		border: 1px solid $tran
		transition: .3s all ease-in-out
		line-height: normal
		position: relative
		&.link-seemore
			color: $main
			width: auto
			height: auto
            // margin-top: r(30px)
			.btn-icon
				left: auto
				right: -40px
				top: 45%
				transform: translateY(-45%)
			&:hover
				background: $tran
				.btn-icon
					left: auto
					right: -50px
		.btn-icon
			position: absolute
			left: 0
			right: 0
			top: 50%
			transform: translateY(-50%)
			transition: .4s all ease-in-out
			img
				filter: brightness(0) invert(1)
			&.no-bright
				img
					filter: brightness(1) invert(0)
		&.white
			border: thin solid $white
		&.gray
			border: thin solid $text-2
			color: $text-2
		&.red
			width: 150px
			background-color: #ee202e
			&:hover
				background-color: $red-1
		&.blue
			background-color: $main
			&:hover
				background-color: $red-1
		&:hover
			background-color: $red-1
			border: thin solid $tran
			.btn-icon
				line-height: 1.6
				left: 83%
				right: 0
				transition: .4s all ease-in-out
			.blue
				background-color: $tran
			&.gray
				color: white
				.no-bright
					img
						filter: brightness(0) invert(1)
		&.btn-expand
			display: flex
			align-items: center
			.btn-icon
				position: static
				margin-left: 5px
				transform: rotate(90deg)
	&.inner-white
		.btn
			img
				position: absolute
				right: 0
				filter: brightness(0) invert(1)
				transition: .3s all
		.btn
			&:hover
				background-color: $white
				color: #000b44
				border: 1px solid #000b44
				img
					filter: brightness(1) invert(0)

.button
	cursor: pointer
	*
		white-space: nowrap
	.btn
		&.btn-seemore
			width: 200px
			height: 50px
			border-radius: 25px
			background-color: $red-1
			color: $white
			font-size: r(18px)
			font-weight: 400
			text-transform: uppercase
			display: flex
			justify-content: center
			align-items: center
			transition: .3s all ease-in-out
			border: thin solid $tran
			&:hover
				background-color: $white
				border: thin solid $red-1
				color: $red-1
				em
					margin-left: 20px
					color: $red-1
			em
				font-size: r(20px)
				margin-left: 15px
				transition: .3s all ease-in-out
				color: $white
