@function r($size) {
	@return ($size/16px)*1rem; }
@mixin  line($line) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $line;
	-webkit-box-orient: vertical; }
@function pow($base, $exponent) {
	$result: 1;
	@for $_ from 1 through $exponent {
		$result: $result * $base; }
	@return $result + px; }

@mixin prefix($property, $value, $prefixes) {
	@each $prefix in $prefixes {
		-#{$prefix}-#{$property}: $value;

		#{$property}: $value; }
	// ex: +prefix(filter, grayscale(50%), moz webkit)
	// ->
	// -moz-filter: grayscale(50%)
	// -webkit-filter: grayscale(50%)
 }	// filter: grayscale(50%)
@mixin img-ratio($ratio,$fit:cover) {
	position: relative;
	display: block;
	height: 0;
	overflow: hidden;
	padding-top: $ratio *100%;
	img,iframe,video {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: $fit;
		transition: .3s ease-in-out all; } }
@mixin bg-body($color) {
	#{if(&, '&.bg-body','.bg-body')} {
		background-color: $color; }
	// ex: +bg-body($body)
	// pug: header.bg-body or .bg-body
	// ->
 }	// css: header.bg-body{background-color: #ffffff}
@mixin dc() {
	display: flex;
	align-items: center;
	justify-content: center; }
@mixin li($name, $ratio) {
	+ $name {
		margin-top: $ratio; } }
@mixin line($line) {
	overflow: hidden;
	display: -webkit-box;
	text-overflow: ellipsis;
	-webkit-line-clamp: $line;
	-webkit-box-orient: vertical; }

@mixin hover-underline($ratio, $color) {
	position: relative;
	transition: background-size .6s ease;
	background-image: linear-gradient(180deg,transparent $ratio, $color 0);
	background-repeat: no-repeat;
	background-size: 0 100%;
	&:hover {
		background-size: 100% 100%; } }
@mixin gg-speed($auto, $grid) {
	content-visibility: $auto;
	contain-intrinsic-size: $grid; }
@mixin text-vertical($vertical ) {
	writing-mode: vertical-rl;
 }	// writing-mode: vertical-$vertical
@mixin type-none {
	list-style-type: none;
	margin: 0;
	padding: 0; }
@mixin scroll {
	&::-webkit-scrollbar {
		width: 5px; }
	&::-webkit-scrollbar-track {
		background: $body; }
	&::-webkit-scrollbar-thumb {
		background: $main; }
	&::-webkit-scrollbar-thumb:hover {
		background: $main; } }
@mixin color-opacity($opacity) {
	rgba($main, $opacity) {} }
