.zoom-img
    overflow: hidden
    img
        transform: scale(1.05)
        transition: .3s all  ease-in-out
    &:hover
        img
            transform: scale(1)
            ransition: .3s all  ease-in-out
@keyframes flash
    50%
        opacity: 0.5

@keyframes fadding
    0%
        opacity: 0
        transform: scale(0.5)
    100%
        opacity: 1
        transform: scale(1)
@keyframes reveal
    0%
        opacity: 0
    100%
        opacity: 1

@keyframes slideRight
    0%
        left: -50px
    100%
        left: 100%

@keyframes slideLeft
    0%
        left: 100%
    100%
        left: -50px
@keyframes moveInLeft
    0%
        opacity: 0
        transform: translateX(-10rem)
    80%
        transform: translateX(1rem)
    100%
        opacity: 1
        transform: translateX(0)

@keyframes moveInRight
    0%
        opacity: 0
        transform: translateX(10rem)
    80%
        transform: translateX(-1rem)
    100%
        opacity: 1
        transform: translateX(0)

@keyframes moveInBottom
    0%
        opacity: 0
        transform: translateY(3rem)
    100%
        opacity: 1
        transform: translateY(0)

@-webkit-keyframes returnToNormal
    from 
    opacity: 0
    -webkit-transform: translate3d(0, 100%, 0)
    transform: translate3d(0, 100%, 0)
    to 
    opacity: 1
    -webkit-transform: none
    transform: none
@keyframes returnToNormal
    from
        opacity: 0
        -webkit-transform: translate3d(0, 100%, 0)
        transform: translate3d(0, 100%, 0)
    to
        opacity: 1
        -webkit-transform: none
        transform: none

@keyframes growth
    from
        transform: scale(.7)
    to
        transform: scale(1)
@keyframes fade-in
    from
        opacity: 0
    to
        opacity: 1
@keyframes slideUp
    0%
        opacity: 0
        transform: translateY(-50px)
    100%
        opacity: 1
        transform: translateY(0px)
@keyframes moving
    0%
        transform: translateY(5px)
    100%
        transform: translateY(-5px)
@keyframes buzz
    50%
        border-radius: 0% 13% 0% 0%
