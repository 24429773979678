.d-flex
	display: flex
.justify-between
	justify-content: space-between
.justify-center
	justify-content: center
.align-center
	align-items: center
.text-center
	text-align: center
.text-right
	text-align: right
.flex-wrap
	flex-wrap: wrap
.text-white
	color: #ffffff
.mt-60
	margin-top: r(60px)
	@media screen and ( max-width: 1023.98px )
		margin-bottom: r(50px)
	@media screen and ( max-width: 768px )
		margin-bottom: r(40px)
.mt-30
	margin-top: 30px
.mt-20
	margin-top: 20px
.mb-80
	margin-bottom: r(80px)
	@media screen and ( max-width: 1023.98px )
		margin-bottom: r(70px)
	@media screen and ( max-width: 768px )
		margin-bottom: r(60px)
.mb-40
	margin-bottom: r(40px)
.mb-30
	margin-bottom: 30px
.mb-20
	margin-bottom: 20px
.mb-lg-20
	@media screen and ( max-width: 1023.98px )
		margin-bottom: 20px
.mb-lg-30
	@media screen and ( max-width: 1023.98px )
		margin-bottom: 30px
.mb-xl-30
	@media screen and ( max-width: 1279.98px )
		margin-bottom: 30px
.mx-auto
	margin-right: auto
	margin-left: auto
.disable
	overflow: hidden
	pointer-events: none
.text-inner
	position: absolute
	display: flex
	flex-direction: column
	top: 0
	left: 0
	color: $white
	width: 100%
	height: 100%
	z-index: 2
.info
	display: flex
	align-items: center
	color: $gray_3
	font-size: 14px
	.news-cate
		position: relative
		&::before
			content: ""
			position: absolute
			right: -10px
			height: 75%
			width: 1px
			top: 50%
			transform: translateY(-50%)
			background-color: $gray_3
	time
		margin-bottom: 0px
		margin-left: 20px
		letter-spacing: normal
		font-size: 14px
		opacity: 1
		color: $gray_3
.primary-sendrequired
	background-color: #e8ecf3
	&.blue
		background-color: #293b8e
		.wrap-box
			height: r(90px)
			@media screen and ( max-width: 767.9px )
				height: auto
		.content
			color: $white
	.wrap-box
		height: r(120px)
		display: flex
		justify-content: space-between
		align-items: center
		.button
			.btn
				position: relative
				span
					display: inline-block
					position: absolute
					left: 0
					pointer-events: none
					transition: .3s all ease-in-out
					img
						filter: brightness(0) invert(1)
				&:hover
					span
						left: calc( 100% - 28px )
		@media screen and ( max-width: 767.9px )
			height: auto
			flex-direction: column
			padding: 15px 0
			.button
				margin-top: r(15px)
			.content
				text-align: center
	.content
		color: $text-2
		font-size: r(24px)
.search-page
	padding: 35px 0
	@media(min-width: 768px)
		padding: 40px 0
	@media(min-width: 1024px)
		padding: 45px 0
	@media(min-width: 1280px)
		padding: 60px 0px
	.search-page-top
		.page-header
			h1
				font-size: r(25px)
				font-weight: 500
				color: $main
				@media(max-width: 1025px)
					font-size: 26px
		.searchresultsummary
			span
				font-weight: 700
				color: $red-1
		.searchcontrols
			padding: 10px 0px
			border: 10px
			.form-inline
				.form-group
					width: 100%
					position: relative
					.form-control
						border: solid 1px #ccc
						padding: 0px 15px
						width: 100%
						height: 40px
						border-radius: 0
						&:focus
							box-shadow: none

					.seachpage-btn
						display: none
	.searchresults
		.searchresultlist
			.searchresult
				padding: 10px 0px
				border-bottom: thin solid #ccc
				display: flex
				align-items: center
				min-height: 50px
				hr
					border: 0

				h3
					font-size: r(18px)
					font-weight: 600
					color: $main
				.searchresultdesc
					font-size: r(16px)
					font-weight: 400
					span
						color: $red-1
						font-size: r(18px)
						font-weight: 700
.search-overlay
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	background: linear-gradient(90deg,rgba(#000 ,0.7 ) 0%,rgba(#000 ,0.2 ))
	opacity: 0
	pointer-events: none
	visibility: hidden
	transition: .3s all ease-in-out
	z-index: 9999
	display: flex
	justify-content: center
	align-items: center
	.Module
		width: 100%
	&.active
		background: linear-gradient(90deg,rgba(#000 ,0.7 ) 100%,rgba(#000 ,0.5 ))
		opacity: 1
		pointer-events: visible
		visibility: visible
		transition-delay: .3s
		transition: .3s all ease-in-out
		.searchbox
			&::before
				width: 100%
				transition-delay: 0.3s
				transition: 1s all ease-in-out
				opacity: 1
				background: $white
			input
				&.searchinput
					&::placeholder
						font-weight: 900
						color: rgba(#fff ,1 )
						transition-delay: 0.3s
						transition: 1s all ease-in-out
						transform: translate(0)
						opacity: 1
			.btn-search
				transform: translate(0)
				transition-delay: 0.3s
				transition: 1s all ease-in-out
				em
					color: rgba(#fff ,1 )
					transition-delay: 0.3s
					transition: 1.5s all ease-in-out
					opacity: 1

	.searchbox
		height: 50px
		display: flex
		justify-content: center
		align-items: center
		width: 50%
		margin: 0 auto
		position: relative
		transition: .3s all ease-in-out
		@media screen and ( max-width: 1280px  )
			width: 80%
		&::before
			pointer-events: none
			width: 0%
			height: 1px
			position: absolute
			content: ''
			top: 100%
			left: 0
			background: transparent
			opacity: 0
		input
			&.searchinput
				padding: 15px
				width: 100%
				height: 100%
				background: transparent
				border: 0
				font-size: 1rem
				color: $white
				font-weight: 600
				&::placeholder
					font-weight: 900
					color: rgba(#fff ,0.5 )
					transform: translateX(30px)
					opacity: 0

		.btn-search
			border: 0
			height: auto
			background-color: transparent
			// margin-left: 20px
			transform: translateY(30px)
			em
				font-size: r(30px)
				font-weight: 400
				color: rgba(#fff ,0.5 )
				opacity: 0
				@media screen and ( max-width: 768px )
					font-size: r(23px)

.burger
	display: none
	margin-left: 15px
	@media screen and ( min-width: 460px )
		order: 2
	@media screen and ( max-width: 1280px )
		display: block
		float: left
		z-index: 0
		&.active
			.line-1
				transform: rotate(-45deg) translate(-4px,5px)
			.line-2
				opacity: 0
			.line-3
				transform: rotate(45deg) translate(-5px,-6px)
		div
			transition: 0.5s all ease
			width: 22px
			height: 3px
			margin: 4px 5px
			background-color: $main
	@media screen and ( max-width: 425px )
		margin-left: 10px
		div
			width: 20px
			height: 3px

#overlay
	opacity: 0
	visibility: hidden
	pointer-events: none
	background-color: rgba(0, 0, 0, .8)
	width: 100%
	height: 100%
	position: fixed
	top: 0
	left: 0
	bottom: 0
	z-index: 1
	transition: .3s all ease-in-out
	&.active
		opacity: 1
		visibility: visible
		pointer-events: visible
	@media screen and ( min-width: 1280px )
		display: none
.img-overlay
	position: relative
	background-color: #ccc
	overflow: hidden
	&:hover
		img
			transform: scale(1.05)
	&::before
		position: absolute
		content: ''
		bottom: 0
		left: 0
		right: 0
		height: 100%
		z-index: 1
		pointer-events: none
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)
		width: 100%

#back-to-top
	position: fixed
	bottom: 140px
	right: 5%
	width: 40px
	height: 40px
	border: thin solid #959697
	z-index: 30
	line-height: 50px
	text-align: center
	cursor: pointer
	opacity: 0
	visibility: hidden
	transition: .3s all ease-in-out
	display: flex
	justify-content: center
	align-items: center
	transform: translateY(30px)
	&:hover
		background: $main
		border: thin solid $tran
		em
			color: $white
	&.active
		opacity: 1
		visibility: visible
		transition: .3s all ease-in-out
		transform: translate(0)
	em
		color: #959697
		font-size: r(32px)
		font-weight: 400
		letter-spacing: 0.8px

.talk
	position: fixed
	right: 5%
	width: 40px
	height: 40px
	display: flex
	align-items: center
	justify-content: center
	z-index: 998
	&#mess
		background-color: $white
		bottom: 90px
		img
			width: 25px
			height: 25px
			margin: auto
			object-fit: contain
			pointer-events: none
	&#twiter
		background-color: #0083ce
		bottom: 40px
		em
			color: $white
			font-size: 25px
			pointer-events: none

.hidden-img
	display: none
