.capa-3
	display: none
	.swiper-capa-3
		position: relative
		.item
			height: 65px
			border-radius: 50px
			display: flex
			align-items: center
			justify-content: center
			box-shadow: 0px 3px 8px 0px rgba(0,0,0,.1)
			.content
				text-align: center
		.swiper-slide
			padding: 5px
		.swiper-button-prev,
		.swiper-button-next
			top: 60%
