.about-3
	position: relative
	background: url("../img/gioi-thieu/bg-2.png")no-repeat
	background-size: 100% 100%
	z-index: 1
	.swiper-about-3
		position: relative
	&::after
		position: absolute
		content: ""
		top: 0
		left: 0
		background-color: rgba(black, .75)
		width: 100%
		height: 100%
		z-index: -1
	.wrap-desc
		display: flex
		flex-direction: column
		margin-top: r(30px)
		position: relative
		padding-left: r(15px)
		line-height: 1
		&::before
			content: ""
			position: absolute
			height: 100%
			width: 2px
			background-color: #ea2530
			top: 0
			left: 0
		.block-title
			margin-bottom: r(15px)
			transition: .3s all ease-in-out
			&:hover
				color: #ea2530
		.content
			font-weight: 600
            // @include line(1)
