.gallery
    &.section
        padding-bottom: r(80px)
    .block-title
        margin-bottom: 0
    .item
        position: relative
        display: flex
        flex-direction: column
        height: auto
        .img
            +img-ratio(219/285)
        &.xl
            .img
                +img-ratio(466/600)
        .text-inner
            width: 100%
            flex-direction: row
            align-items: flex-end
            height: auto
            padding-left: 30px
            bottom: 30px
            top: inherit
            pointer-events: none
            *
                color: white
            em
                font-size: 24px
                font-weight: 700
                transition: .3s all  ease-in-out
            .content
                font-size: 16px
                font-weight: 400
                margin-left: 10px
                transition: .3s all  ease-in-out
        &:hover
            .box-content
                *
                    color: #e30016
                .content
                    text-decoration: underline
    [class*='col-']
        margin-bottom: r(30px)
    .wrap-content
        >.row
            margin-bottom: r(-30px)
    .item
        