.about-1
    &.section
        padding-top: r(50px)
    .block-title
        background: url("../img/gioi-thieu/bg-title.png")no-repeat
        background-size: 100% 100%
        font-weight: 500
        padding: r(30px) 0
        @media screen and ( max-width: 767.9px )
            background-size: cover
            background-position: center
            font-size: 1.8rem
        @media screen and ( max-width: 500px )
            font-size: 1.5rem
        @media screen and ( max-width: 400px )
            font-size: 1.25rem
    .wrap-content
        padding: 0 r(100px)
        color: $text-2
        @media screen and ( max-width: 1023.9px )
            padding: 0 r(20px)
            