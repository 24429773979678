.page-news-list
    &.section
        padding-bottom: r(80px)
    .item
        position: relative
        display: flex
        flex-direction: column
        height: 360px
        .button
            text-transform: uppercase
        &:hover
            .box-content
                .title
                    color: $main
                    transition: .3s all
                .button.start
                    color: $main
                    .btn
                        img
                            filter: grayscale(0)
        @media screen and ( max-width: 1279.9px )
            height: 300px
        @media screen and ( max-width: 1023.9px )
            height: 380px
        @media screen and ( max-width: 767.9px )
            height: auto
        &.xl
            flex-direction: row
            height: 360px
            @media screen and ( max-width: 767.9px )
                display: block
                height: auto
            .img
                flex: 0 0 315px 
                +img-ratio(360/315)
                padding-top: 59.8%
                @media screen and ( max-width: 1279.9px )
                    padding-top: 38.8%
                @media screen and ( max-width: 1023.9px )
                    padding-top: 52%
                @media screen and ( max-width: 767.9px )
                    padding-top: 57%
            .box-content
                padding: r(30px)
                time
                    margin-bottom: 15px
        .img
            +img-ratio(230/285)
        .box-content
            background-color:  #e8f3eb
            padding: r(20px)
            overflow: hidden
            height: 100%
            width: 100%
            @media screen and ( max-width: 1279.9px )
                height: 100%
            time
                margin-bottom: 5px
            .title
                margin-bottom: r(20px)
                font-weight: 700
                font-size: r(18px)
                color: $text-2
                @include line(3)
            .content
                font-size: r(14px)
                color: $text-2
                font-weight: 400
                margin: r(10px) 0 r(15px)
                @include line(4)
            .button
                .btn
                    height: 40px
                    img
                        filter: grayscale(100%)
                    
    [class*='col-']
        margin-bottom: r(30px)
