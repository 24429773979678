.about-4
    &.section
        padding-bottom: r(30px)
    .wrap-content
        padding-left: r(30px)
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        @media screen and ( max-width: 1023.9px )
            padding-left: 0
        .title
            font-size: r(18px)
            color: $main
            margin-bottom: r(20px)
    .img
        width: 100%
        height: 100%
    .row
        .row
            [class*="col-"]
                margin-bottom: r(30px)
            &:last-child
                flex-direction: row-reverse
                .wrap-content
                    padding-left: 0
                @media screen and ( max-width: 1023.9px )
                    flex-direction: column-reverse
            &:first-child
                @media screen and ( max-width: 1023.9px )
                    flex-direction: column-reverse
                