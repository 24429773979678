#main-banner
    .swiper-slide
        position: relative
        .img
            position: relative
            img
                object-fit: cover
                width: 100%
                height: 100%
                clip-path: polygon(0 0,100% 0,100% 0,100% 97%,52% 97%,50% 100%,48% 97%,0 97%)
                @media screen and ( max-width: 1024px )
                    min-height: 50vh
                @media screen and ( max-width: 580px )
                    min-height: 60vh
            &::before
                content: ""
                position: absolute
                top: 0
                width: 100%
                height: 100%
                left: 0
                clip-path: polygon(0 0,100% 0,100% 0,100% 97%,52% 97%,50% 100%,48% 97%,0 97%)
        .icon-img
            position: absolute
            bottom: 8px
            left: 50%
            transform: translateX(-50%)
            @media screen and ( min-width: 1520px )
                bottom: 12px 
        .box-content
            position: absolute
            bottom: 180px
            width: 100%
            height: 100%
            left: 0
            @media screen and ( max-width: 480px )
                bottom: 120px
            .block-title
                font-weight: 400
                text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.9)
                line-height: normal
                text-transform: none
                @media screen and ( max-width: 459.9px )
                    font-size: r(28px)
                @media screen and ( max-width: 400px )
                    font-size: 1.5rem
