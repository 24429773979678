.index-3
	background-color: #e8ecf3
	&.section
		padding-bottom: 0
	.big-box
		display: none
		flex-wrap: wrap
		&.active
			display: flex
		.item
			border: thin solid #e8f3eb
			flex: 0 0 25%
			position: relative
			@media screen and ( max-width: 1279.9px )
				flex: 0 0 (100%/3)
			@media screen and ( max-width: 1023.9px )
				flex: 0 0 50%
			@media screen and ( max-width: 580px )
				flex: 0 0 100%
			.img
				position: relative
				+img-ratio(390/480)
				&::before
					content: ""
					position: absolute
					width: 100%
					height: 100%
					top: 0
					left: 0
					background-color: rgba(0,0,0,0.25)
					z-index: 2
					pointer-events: none
			.text-inner
				padding: r(30px)
				pointer-events: none
				.button
					justify-content: flex-start
					margin-top: auto
					a
						pointer-events: visible
				.edit-link
					pointer-events: visible
	.toggle-catalog
		display: none
