.capa-6
    &.section
        padding-top: 0
        padding-bottom: r(50px)
    .wrap-box
        padding: r(60px) r(100px)
        background-color: #e8ecf3
        @media screen and ( max-width: 767.9px )
            padding: r(60px) r(30px)
        .block-title
            font-weight: 500
        .swiper-capa-6
            position: relative
            .item
                display: flex
                flex-direction: row
                @media screen and ( max-width: 1023.9px )
                    flex-direction: column
                .img
                    +img-ratio(320/435,contain)
                    width: 100%
                    padding-top: 31%
                    @media screen and ( max-width: 767.9px )
                        padding-top: 40%
                .wrap-content
                    max-width: 58.5%
                    padding-left: r(60px)
                    padding-top: r(30px)
                    @media screen and ( max-width: 1023.9px )
                        max-width: 100%
                    @media screen and ( max-width: 767.9px )
                        padding-left: 0
                    .title
                        color: #383863
                        font-size: r(18px)
                    .content
                        @include line(6)
                        color: $text-2
