.about-5
    &.section
        padding-top: 0
    @media screen and ( max-width: 1023.9px )
       [class*="col-"]
           margin-bottom: r(30px)
    .desc
        margin-bottom: r(30px)
        padding: 0 r(210px)
        @media screen and ( max-width: 1023.9px )
            padding: 0 r(60px)
        @media screen and ( max-width: 767.9px )
            padding: 0
    .item
        .wrap-content
            margin-top: r(15px)
            .title
                font-size: r(24px)
                color: #ff3333
                margin-bottom: r(15px)
            .content
                display: flex
                margin-bottom: 5px
                em
                    padding-right: 10px
                    line-height: 1  
                    color: #ff3333
                    font-size: 18px